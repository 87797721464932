/**
 * Component short description
 *
 * @see Logout.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import { useAuth } from "../../../hooks";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Logout.data";

/**
 * Imports Material UI components
 */

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Logout.lang.ro-ro";
import { hu_hu } from "./Logout.lang.hu-hu";
import { en_us } from "./Logout.lang.en-us";
import { de_de } from "./Logout.lang.de-de";

i18n.addResourceBundle("ro-RO", "Logout", ro_ro);
i18n.addResourceBundle("hu-HU", "Logout", hu_hu);
i18n.addResourceBundle("en-US", "Logout", en_us);
i18n.addResourceBundle("de-DE", "Logout", de_de);

/**
 * Displays the component
 */
const Logout = props => {
  const { logout } = useAuth();

  logout();

  return null;
};

Logout.propTypes = propTypes;
Logout.defaultProps = defaultProps;

export default Logout;
export { propTypes as LogoutPropTypes, defaultProps as LogoutDefaultProps };
