/**
 * Component short description
 *
 * @see useValidationMessages.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./useValidationMessages.data";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./useValidationMessages.lang.ro-ro";
import { hu_hu } from "./useValidationMessages.lang.hu-hu";
import { en_us } from "./useValidationMessages.lang.en-us";
import { de_de } from "./useValidationMessages.lang.de-de";

i18n.addResourceBundle("ro-RO", "useValidationMessages", ro_ro);
i18n.addResourceBundle("hu-HU", "useValidationMessages", hu_hu);
i18n.addResourceBundle("en-US", "useValidationMessages", en_us);
i18n.addResourceBundle("de-DE", "useValidationMessages", de_de);

/**
 * Displays the component
 */
const useValidationMessages = (props) => {
  const { t } = useTranslation("useValidationMessages");

  const trans = (key) => {
    return t(key);
  };

  return { trans };
};

useValidationMessages.propTypes = propTypes;
useValidationMessages.defaultProps = defaultProps;

export default useValidationMessages;
export {
  propTypes as useValidationMessagesPropTypes,
  defaultProps as useValidationMessagesDefaultProps,
};
