/**
 * Component short description
 *
 * @see Afir.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Afir.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Afir.lang.ro-ro";
import { hu_hu } from "./Afir.lang.hu-hu";
import { en_us } from "./Afir.lang.en-us";
import { de_de } from "./Afir.lang.de-de";

i18n.addResourceBundle("ro-RO", "Afir", ro_ro);
i18n.addResourceBundle("hu-HU", "Afir", hu_hu);
i18n.addResourceBundle("en-US", "Afir", en_us);
i18n.addResourceBundle("de-DE", "Afir", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3),
    margin: theme.spacing(1),
    border: "1px solid"
  }
}));

/**
 * Displays the component
 */
const Afir = props => {
  const { container } = useStyles(props);
  const { t } = useTranslation("Afir");

  return (
    <Grid container>
      <Grid item xs={12} className={clsx(container, "Afir")}>
        {t("Afir")}
      </Grid>
    </Grid>
  );
};

Afir.propTypes = propTypes;
Afir.defaultProps = defaultProps;

export default Afir;
export { propTypes as AfirPropTypes, defaultProps as AfirDefaultProps };
