/**
 * Component short description
 *
 * @see Cart.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Card2 from "../../Card2";
import { TextSection, Template, Actions, PageHeader } from "../../Theme";
import OrdersForCart from "../../_account/OrdersForCart";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Cart.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Cart.lang.ro-ro";
import { hu_hu } from "./Cart.lang.hu-hu";
import { en_us } from "./Cart.lang.en-us";
import { de_de } from "./Cart.lang.de-de";

i18n.addResourceBundle("ro-RO", "Cart", ro_ro);
i18n.addResourceBundle("hu-HU", "Cart", hu_hu);
i18n.addResourceBundle("en-US", "Cart", en_us);
i18n.addResourceBundle("de-DE", "Cart", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  }
}));

/**
 * Displays the component
 */
const Cart = props => {
  const { description, orders } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("Cart");

  /**
   * Displays the Hero card
   */
  const cardContent1 = <TextSection description={description} />;

  const cardWithMedia1 = {
    image: null,
    content: cardContent1
  };

  return (
    <Layout header={{ currentPage: "Cart" }}>
      <Grid container spacing={1} className={clsx(container, "Cart")}>
        <Grid item xs={12}>
          <PageHeader title={t("Cart")} />
        </Grid>
        <Grid item xs={12}>
          <Card2 {...cardWithMedia1} />
        </Grid>
        <Grid item xs={12}>
          <OrdersForCart {...orders} />
        </Grid>
      </Grid>
    </Layout>
  );
};

Cart.propTypes = propTypes;
Cart.defaultProps = defaultProps;

export default Cart;
export { propTypes as CartPropTypes, defaultProps as CartDefaultProps };
