/**
 * Component short description
 *
 * @see useContact.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import useSWR from "swr";

/**
 * Imports other components and hooks
 */
import useRequests from "../../useRequests";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./useContact.data";

const useContactInfoSwr = () => {
  const { get } = useRequests();
  return useSWR("contact/info", get);
};

/**
 * Displays the component
 */
const useContact = (props) => {
  const { post } = useRequests();

  const createContact = (contact) => {
    return post("contact", contact);
  };

  return { createContact, useContactInfoSwr };
};

useContact.propTypes = propTypes;
useContact.defaultProps = defaultProps;

export default useContact;
export {
  useContactInfoSwr,
  propTypes as useContactPropTypes,
  defaultProps as useContactDefaultProps,
};
