/**
 * Defines the data requirements for the component
 *
 * @see Home.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { HeroPropTypes, HeroDefaultProps } from "../Hero";
import { AboutPropTypes, AboutDefaultProps } from "../About";
import {
  CategoriesAsCarouselPropTypes,
  CategoriesAsCarouselDefaultProps
} from "../../_category/CategoriesAsCarousel";
import { OngInfoPropTypes, OngInfoDefaultProps } from "../OngInfo";
import { AfirPropTypes, AfirDefaultProps } from "../Afir";
import {
  LocalMarketIntroPropTypes,
  LocalMarketIntroDefaultProps
} from "../../_localMarket/LocalMarketIntro";
import {
  StayIntroPropTypes,
  StayIntroDefaultProps
} from "../../_stay/StayIntro";
import {
  NewsAsHeroPropTypes,
  NewsAsHeroDefaultProps
} from "../../_news/NewsAsHero";
import { ValutaPropTypes, ValutaDefaultProps } from "../Valuta";
import { NewsletterPropTypes, NewsletterDefaultProps } from "../../Newsletter";
import { HeaderPropTypes, HeaderDefaultProps } from "../../Header";

/**
 * Defines the prop types
 */
const propTypes = {
  header: PropTypes.shape(HeaderPropTypes),
  hero: PropTypes.shape(HeroPropTypes),
  about: PropTypes.shape(AboutPropTypes),
  categoriesAsCarousel: PropTypes.shape(CategoriesAsCarouselPropTypes),
  ongInfo: PropTypes.shape(OngInfoPropTypes),
  afir: PropTypes.shape(AfirPropTypes),
  localMarketIntro: PropTypes.shape(LocalMarketIntroPropTypes),
  stayIntro: PropTypes.shape(StayIntroPropTypes),
  newsAsHero: PropTypes.shape(NewsAsHeroPropTypes),
  valuta: PropTypes.shape(ValutaPropTypes),
  newsletter: PropTypes.shape(NewsletterPropTypes)
};

/**
 * Defines the default props
 */
const defaultProps = {
  header: HeaderDefaultProps,
  hero: HeroDefaultProps,
  about: AboutDefaultProps,
  categoriesAsCarousel: CategoriesAsCarouselDefaultProps,
  ongInfo: OngInfoDefaultProps,
  afir: AfirDefaultProps,
  localMarketIntro: LocalMarketIntroDefaultProps,
  stayIntro: StayIntroDefaultProps,
  newsAsHero: NewsAsHeroDefaultProps,
  valuta: ValutaDefaultProps,
  newsletter: NewsletterDefaultProps
};

export { propTypes, defaultProps };
