/**
 * Defines the data requirements for the component
 *
 * @see List2.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";

/**
 * Defines the prop types
 */
const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, listItem: PropTypes.string })
  )
};

/**
 * Defines the default props
 */
const defaultProps = {
  items: Array(1).fill({
    id: shortid.generate(),
    listItem: "List item"
  })
};

export { propTypes, defaultProps };
