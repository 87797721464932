/**
 * Common mock data types
 *
 * @see Borsec - Pineyard Template
 * @see https://docs.google.com/spreadsheets/d/1CALnqCc7nG-5j05iGfXXpkPsxPORK8pBF-sTJo--6kw/edit?usp=sharing
 */
import React from "react";
import { loremIpsum } from "lorem-ipsum";
import shortid from "shortid";
import { kebabCase } from "lodash";

/**
 * Defining data basic types
 */
const dText128 = {
  format: "plain",
  sentenceUpperBound: 10
};
const dText250 = {
  format: "plain",
  sentenceLowerBound: 20,
  sentenceUpperBound: 40
};
const dText500 = {
  format: "plain",
  sentenceLowerBound: 40,
  sentenceUpperBound: 80
};
const dText1500 = {
  format: "plain",
  sentenceLowerBound: 80,
  sentenceUpperBound: 160
};

/**
 * Defining basic data functions
 */
const dIdString = () => shortid.generate();
const dIdNumber = () => Math.floor(Math.random() * Math.floor(10000));
const dName = () => loremIpsum(dText128);
const dSlug = name => kebabCase(name);
const dAlternateName = () => loremIpsum(dText128);
const dDescription = () => loremIpsum(dText250);
const dSectionDescription = () => loremIpsum(dText500);
const dSectionListing = () => {
  const listItems = Array.from(Array(3).keys()).map(index => (
    <li key={dIdString()}>{dDescription()}</li>
  ));
  return <ul>{listItems}</ul>;
};

export {
  dIdNumber,
  dIdString,
  dName,
  dSlug,
  dAlternateName,
  dDescription,
  dSectionDescription,
  dSectionListing
};
