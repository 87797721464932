/**
 * Component short description
 *
 * @see AccommodationAsThumb.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import Card2 from "../../Card2";
import { useUnsplash } from "../../../hooks";
import { TextThumbnail } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./AccommodationAsThumb.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./AccommodationAsThumb.lang.ro-ro";
import { hu_hu } from "./AccommodationAsThumb.lang.hu-hu";
import { en_us } from "./AccommodationAsThumb.lang.en-us";
import { de_de } from "./AccommodationAsThumb.lang.de-de";

i18n.addResourceBundle("ro-RO", "AccommodationAsThumb", ro_ro);
i18n.addResourceBundle("hu-HU", "AccommodationAsThumb", hu_hu);
i18n.addResourceBundle("en-US", "AccommodationAsThumb", en_us);
i18n.addResourceBundle("de-DE", "AccommodationAsThumb", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  }
}));

/**
 * Displays the component
 */
const AccommodationAsThumb = props => {
  const { name, slug, image, price, destinationName, index } = props;
  // const image = images[0]; // NOTE: clarify with backend. This is a repeating pattern
  const { container } = useStyles(props);
  const { t } = useTranslation("AccommodationAsThumb");

  /**
   * Loads unsplash images
   */
  const unsplashImages = useUnsplash({
    keyword: "Rooms",
    page: index + 1,
    photos: 1,
    params: { orientation: "landscape" }
  });

  /**
   * Loads the current route
   */
  const match = useRouteMatch();
  const { url } = match;

  /**
   * Displays the accommodation as card
   */
  const price2 = `${price} RON / Night`;

  const cardContent1 = (
    <TextThumbnail
      overline={destinationName}
      to={`${url}/${slug}`}
      title={name}
      price={price2}
    />
  );

  const cardWithMedia1 = {
    image: unsplashImages ? unsplashImages[0] : image,
    content: cardContent1,
    customStyles: useStyles(props)
  };

  return (
    <Grid container className={clsx(container, "AccommodationAsThumb")}>
      <Grid item xs={12}>
        <Card2 {...cardWithMedia1} />
      </Grid>
    </Grid>
  );
};

AccommodationAsThumb.propTypes = propTypes;
AccommodationAsThumb.defaultProps = defaultProps;

export default AccommodationAsThumb;
export {
  propTypes as AccommodationAsThumbPropTypes,
  defaultProps as AccommodationAsThumbDefaultProps
};
