/**
 * Defines the data requirements for the component
 *
 * @see BookingHistory.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";

/**
 * Imports prop types from other components
 */
import { BookingDefaultProps, BookingPropTypes } from "../Booking";

/**
 * Defines the prop types
 */
const propTypes = {
  bookings: PropTypes.arrayOf(PropTypes.shape(BookingPropTypes))
};

/**
 * Defines the default props
 */
const defaultProps = {
  bookings: Array(5)
    .fill(BookingDefaultProps)
    .map((item, index) => {
      return {
        ...item,
        id: shortid.generate(),
        name: `Accommodation name ${index + 1}`,
        destinationName: `Destination name ${index + 1}`,
        price: Math.floor(Math.random() * Math.floor(100)) + 1
      };
    })
};

export { propTypes, defaultProps };
