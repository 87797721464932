/**
 * Defines the data requirements for the component
 *
 * @see Article.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import Lorem from "react-lorem-component";

/**
 * Imports prop types from other components
 */
import {
  ImageResponsivePropTypes,
  ImageResponsiveDefaultProps,
  ImageResponsivePlaceholderCom169
} from "../../ImageResponsive";

/**
 * Defines the prop types
 *
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/ > NewsDetail
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/News/getNewsByID
 */
const propTypes = {
  /**
   * Props from the API
   */
  id: PropTypes.number,
  slug: PropTypes.string,
  name: PropTypes.string,
  destination: PropTypes.string,
  image: PropTypes.shape(ImageResponsivePropTypes),
  description: PropTypes.string,
  sectionDescription1: PropTypes.string,
  image1: PropTypes.shape(ImageResponsivePropTypes),
  image1Description: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionDescription2: PropTypes.string,
  sectionListing: PropTypes.string,
  image2: PropTypes.shape(ImageResponsivePropTypes),
  image2Description: PropTypes.string,
  sectionDescription3: PropTypes.string
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: 666,
  slug: "news1-name",
  name: "News1",
  destination: "Destination1",
  image: ImageResponsivePlaceholderCom169,
  description: <Lorem seed="0" count="1" sentenceUpperBound="14" />,
  sectionDescription1: <Lorem seed="1" count="1" sentenceUpperBound="14" />,
  image1: ImageResponsivePlaceholderCom169,
  image1Description: <Lorem seed="2" count="1" sentenceUpperBound="14" />,
  sectionTitle: "Section title",
  sectionDescription2: <Lorem seed="4" count="1" sentenceUpperBound="14" />,
  sectionListing: (
    <Lorem seed="3" mode="list" count="3" sentenceUpperBound="7" />
  ),
  image2: ImageResponsivePlaceholderCom169,
  image2Description: <Lorem seed="5" count="1" sentenceUpperBound="14" />,
  sectionDescription3: <Lorem seed="6" count="1" sentenceUpperBound="14" />
};

export { propTypes, defaultProps };
