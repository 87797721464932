/**
 * Defines the data requirements for the component
 *
 * @see Routes.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports other components and hooks
 */
import Home from "../_home/Home";
import Destinations from "../_destinations/Destinations";
import LocalMarket from "../_localMarket/LocalMarket";
import Stay from "../_stay/Stay";
import Faq from "../_faq/Faq/Faq";
import Contact from "../_contact/Contact/Contact";
import News from "../_news/News";
import Events from "../_events/Events";
import Terms from "../_terms/Terms";
import Account from "../_account/Account";
import Settings from "../_account/Settings";
import BookingHistory from "../_account/BookingHistory";
import OrderHistory from "../_account/OrderHistory";
import Search from "../_search/Search";
import Cart from "../_cart/Cart";
import Order from "../_account/Order";
import Checkout from "../_checkout/Checkout";
import MediaAndPress from "../_mediaPress/MediaAndPress";
import Support from "../_support/Support";
import PrivacyPolicy from "../_privacyPolicy/PrivacyPolicy";
import Sitemap from "../_sitemap/Sitemap";

/**
 * Defines the prop types
 */
const propTypes = {
  routes: PropTypes.arrayOf(PropTypes.any)
};

/**
 * Defines the default props
 */
const defaultProps = {
  routes: [
    { path: "/destinations", component: Destinations },
    { path: "/local-market", component: LocalMarket },
    { path: "/stay", component: Stay },
    { path: "/faq", component: Faq },
    { path: "/contact", component: Contact },
    { path: "/news", component: News },
    { path: "/events", component: Events },
    { path: "/terms-and-conditions", component: Terms },
    { path: "/account", component: Account },
    { path: "/settings", component: Settings },
    { path: "/order-history", component: OrderHistory },
    { path: "/booking-history", component: BookingHistory },
    { path: "/search", component: Search },
    { path: "/cart", component: Cart },
    { path: "/order", component: Order },
    { path: "/checkout", component: Checkout },
    { path: "/media-and-press", component: MediaAndPress },
    { path: "/support", component: Support },
    { path: "/privacy-policy", component: PrivacyPolicy },
    { path: "/sitemap", component: Sitemap },
    { path: "/", component: Home }
  ]
};

export { propTypes, defaultProps };
