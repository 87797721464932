/**
 * Component short description
 *
 * @see OrderForAccount.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import shortid from "shortid";
import { Link as RouterLink } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import Card2, { Card2SingleRowStylesWithActions } from "../../Card2";
import {
  TextSection,
  Template,
  Actions,
  PageHeader,
  TextPrice
} from "../../Theme";
import Order from "../Order";
import List2 from "../../List2";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./OrderForAccount.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./OrderForAccount.lang.ro-ro";
import { hu_hu } from "./OrderForAccount.lang.hu-hu";
import { en_us } from "./OrderForAccount.lang.en-us";
import { de_de } from "./OrderForAccount.lang.de-de";

i18n.addResourceBundle("ro-RO", "OrderForAccount", ro_ro);
i18n.addResourceBundle("hu-HU", "OrderForAccount", hu_hu);
i18n.addResourceBundle("en-US", "OrderForAccount", en_us);
i18n.addResourceBundle("de-DE", "OrderForAccount", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  },

  contentContainer: {
    flexBasis: "100%"
  }
}));

/**
 * Displays the component
 */
const OrderForAccount = props => {
  const { id, seller, orderDate, orderItems, totalPrice, status } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("OrderForAccount");

  /**
   * Prepares the card
   */
  const subtitle1 = (
    <>
      <span>{t("Order number")}:</span>
      <br />
      <span>{id}</span>
    </>
  );

  const details = (
    <List2
      items={[
        { id: shortid.generate(), listItem: `${t("Seller")}: ${seller}` },
        {
          id: shortid.generate(),
          listItem: `${t("Total items")}: ${orderItems} / ${totalPrice} RON`
        },
        { id: shortid.generate(), listItem: `${t("Order status")}: ${status}` }
      ]}
    />
  );

  const cardContent1 = (
    <TextSection subtitle={subtitle1} description={details} />
  );

  const action = (
    <Button variant="outlined" to="/order" component={RouterLink}>
      {t("View order")}
    </Button>
  );

  const cardActions1 = (
    <Actions items={[{ id: shortid.generate(), action: action }]} />
  );

  const cardWithMedia1 = {
    image: null,
    content: cardContent1,
    actions: cardActions1,
    variant: "outlined",
    customStyles: useStyles(props)
  };

  return <Card2 {...cardWithMedia1} />;
};

OrderForAccount.propTypes = propTypes;
OrderForAccount.defaultProps = defaultProps;

export default OrderForAccount;
export {
  propTypes as OrderForAccountPropTypes,
  defaultProps as OrderForAccountDefaultProps
};
