/**
 * Defines the data requirements for the component
 *
 * @see StayFilters.md for details
 */
import PropTypes from "prop-types";
import {
  FilterDestinationPropTypes,
  FilterDestinationDefaultProps,
} from "../../FilterDestination";
import {
  FilterAccomodationTypePropTypes,
  FilterAccomodationTypeDefaultProps,
} from "../FilterAccomodationType";
import {
  FilterRoomTypePropTypes,
  FilterRoomTypeDefaultProps,
} from "../FilterRoomType";
import FilterExtras, { FilterExtrasDefaultProps } from "../FilterExtras";

/**
 * Defines the prop types
 */
const propTypes = {
  filterDestination: PropTypes.shape(FilterDestinationPropTypes),
  filterAccomodationType: PropTypes.shape(FilterAccomodationTypePropTypes),
  filterRoomType: PropTypes.shape(FilterRoomTypePropTypes),
  filterExtas: PropTypes.shape(FilterExtras),
};

/**
 * Defines the default props
 */
const defaultProps = {
  filterDestination: FilterDestinationDefaultProps,
  filterAccomodationType: FilterAccomodationTypeDefaultProps,
  filterRoomType: FilterRoomTypeDefaultProps,
  filterExtas: FilterExtrasDefaultProps,
};

export { propTypes, defaultProps };
