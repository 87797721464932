/**
 * Component short description
 *
 * @see OrderItem.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import shortid from "shortid";

/**
 * Imports other components and hooks
 */
import Card2, { Card2SingleRowStylesWithActions } from "../../Card2";
import { TextSection, TextPrice, TextDefault, Actions } from "../../Theme";
import { useUnsplash } from "../../../hooks";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./OrderItem.data";

/**
 * Imports Material UI components
 */
import { makeStyles, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./OrderItem.lang.ro-ro";
import { hu_hu } from "./OrderItem.lang.hu-hu";
import { en_us } from "./OrderItem.lang.en-us";
import { de_de } from "./OrderItem.lang.de-de";

i18n.addResourceBundle("ro-RO", "OrderItem", ro_ro);
i18n.addResourceBundle("hu-HU", "OrderItem", hu_hu);
i18n.addResourceBundle("en-US", "OrderItem", en_us);
i18n.addResourceBundle("de-DE", "OrderItem", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  }
}));

/**
 * Displays the component
 */
const OrderItem = props => {
  const { id, name, destinationName, price, quantity, image, index } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("OrderItem");

  const theme = useTheme();
  const isPortrait = useMediaQuery(theme.breakpoints.down("md"));
  const imageWidth = isPortrait ? "50%" : "25%";
  const contentWidth = isPortrait ? "50%" : "75%";

  /**
   * Loads unsplash images
   */
  const unsplashImages = useUnsplash({
    keyword: "Local market",
    page: index + 1,
    photos: 1,
    params: { orientation: "landscape" }
  });

  const image2 = unsplashImages ? unsplashImages[0] : image;

  /**
   * Displays order details
   */
  const quantity2 = `${t("Quantity")}: ${quantity}`;

  const price2 = <TextPrice text={`${price} RON`} />;

  const cardContent1 = (
    <TextSection description={name} overline={destinationName} />
  );

  const cardActions1 = (
    <Actions
      items={[
        { id: shortid.generate(), action: price2 },
        { id: shortid.generate(), action: quantity2 }
      ]}
      orientation="vertical"
    />
  );

  const cardWithMedia1 = {
    image: image2,
    content: cardContent1,
    actions: cardActions1,
    customStyles: Card2SingleRowStylesWithActions({
      ...props,
      breakpoint: "sm",
      imageWidth: imageWidth,
      contentWidth: contentWidth,
      cardContentWidth: contentWidth,
      cardActonsWidth: imageWidth
    })
  };

  return <Card2 {...cardWithMedia1} />;
};

OrderItem.propTypes = propTypes;
OrderItem.defaultProps = defaultProps;

export default OrderItem;
export {
  propTypes as OrderItemPropTypes,
  defaultProps as OrderItemDefaultProps
};
