/**
 * Defines the data requirements for the component
 *
 * @see Valuta.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import Lorem from "react-lorem-component";

/**
 * Defines the prop types
 */
const propTypes = {
  widget: PropTypes.any
};

/**
 * Defines the default props
 *
 * // NOTE: When theme colors change widget v.3.0 must be updated too
 * // NOTE: Widget v.2.0 seems to be not working
 */
const defaultProps = {
  widget: `<!--  CursBnr.ro Code inceput v.3.0  -->
<iframe style="width: 100%; height: 100%; font-size: 130%" frameborder="0" marginwidth="0" marginheight="0" scrolling="no" src="https://www.cursbnr.ro/insert/cursvalutar.php?b=fff&bl=fff&ttc=bcd659&tc=000000&diff=1&ron=1&cb=0&pics=1"></iframe>
<!--  CursBnr.ro Code sfarsit v.3.0  -->`,
  widget2: `<!-- INCEPUT SCRIPT PRELUARE CURS VALUTAR v2.0 -->
<script type="text/javascript" language="javascript" src="//cdn1.curs-valutar-bnr.ro/custom_widgets/get_widget.php?lw=responsive&rw=1&font=Arial&cft=transparent&ctt=000000&ttb=1&cc=f2f2f2&cfb=ffffff&ct=000000&pd=4&pc=4&aiv=1&val[]=8&val[]=19&val[]=10&mf=14&avc=1&ac=0&aod=0&lang=ro"></script>
<!-- SFARSIT SCRIPT PRELUARE CURS VALUTAR v2.0-->`
};

export { propTypes, defaultProps };
