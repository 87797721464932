/**
 * Defines the data requirements for the component
 *
 * @see OngInfo.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import Lorem from "react-lorem-component";

/**
 * Imports prop types from other components
 */
import {
  ImageResponsivePropTypes,
  ImageResponsivePlaceholderCom169
} from "../../ImageResponsive";

/**
 * Defines the prop types
 *
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/ > PartnerInfo, Partner
 */
const propTypes = {
  description: PropTypes.string,
  quote: PropTypes.string,
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.shape(ImageResponsivePropTypes),
      url: PropTypes.string
    })
  )
};

/**
 * Defines the default props
 */
const defaultProps = {
  description: <Lorem seed="0" count="1" sentenceUpperBound="7" />, // ~250 chars
  quote: <Lorem seed="1" count="1" sentenceUpperBound="7" />,
  partners: Array(12).fill({
    name: <Lorem seed="0" count="1" sentenceUpperBound="3" />,
    logo: { ...ImageResponsivePlaceholderCom169, displayCaption: false },
    url: "http://partnerurl"
  })
};

export { propTypes, defaultProps };
