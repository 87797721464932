/**
 * Component short description
 *
 * @see MarketFilters.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import FilterDestination from "../../FilterDestination";
import FilterCategory from "../FilterCategory/FilterCategory";
import FilterSeller from "../FilterSeller/FilterSeller";
/**
 * Imports data
 */
import { propTypes, defaultProps } from "./MarketFilters.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./MarketFilters.lang.ro-ro";
import { hu_hu } from "./MarketFilters.lang.hu-hu";
import { en_us } from "./MarketFilters.lang.en-us";
import { de_de } from "./MarketFilters.lang.de-de";

i18n.addResourceBundle("ro-RO", "MarketFilters", ro_ro);
i18n.addResourceBundle("hu-HU", "MarketFilters", hu_hu);
i18n.addResourceBundle("en-US", "MarketFilters", en_us);
i18n.addResourceBundle("de-DE", "MarketFilters", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    border: "1px solid",
  },
}));

/**
 * Displays the component
 */
const MarketFilters = (props) => {
  const { filterDestination, filterSeller, filterCategory } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("MarketFilters");

  return (
    <List>
      <FilterDestination {...filterDestination} />
      <FilterSeller {...filterSeller} />
      <FilterCategory {...filterCategory} />
    </List>
  );
};

MarketFilters.propTypes = propTypes;
MarketFilters.defaultProps = defaultProps;

export default MarketFilters;
export {
  propTypes as MarketFiltersPropTypes,
  defaultProps as MarketFiltersDefaultProps,
};
