/**
 * Defines the data requirements for the component
 *
 * @see CategoriesAsTabs.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import Lorem from "react-lorem-component";
import { loremIpsum } from "lorem-ipsum";

/**
 * Imports prop types from other components
 */
import { CategoryPropTypes, CategoryDefaultProps } from "../Category";
import { TabPanelPropTypes, TabPanelDefaultProps } from "../../TabPanel";

/**
 * Defines the prop types
 */
const propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape(CategoryPropTypes)),
  tabPanel: PropTypes.shape(TabPanelPropTypes)
};

const words = [
  "Natural resources",
  "Cultural centres",
  "Wellness stations",
  "Entertainment"
];

/**
 * Defines the default props
 */
const defaultProps = {
  categories: Array(4)
    .fill(CategoryDefaultProps)
    .map((item, index) => {
      return {
        ...item,
        name: words[index],
        alternateName: loremIpsum({
          format: "plain",
          seed: index,
          count: 1,
          sentenceLowerBound: 1,
          sentenceUpperBound: 5,
          paragraphLowerBound: 1,
          paragraphUpperBound: 1
        }),
        description: loremIpsum({ format: "plain", seed: 1, count: 1 })
      };
    }),
  tabPanel: TabPanelDefaultProps
};

export { propTypes, defaultProps };
