/**
 * Defines the data requirements for the component
 *
 * @see Questions.md for details
 */
import PropTypes from "prop-types";
import shortid from "shortid";

import { QuestionPropTypes, QuestionDefaultProps } from "../Question/Question";

/**
 * Defines the prop types
 */
const propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape(QuestionPropTypes))
};

/**
 * Defines the default props
 */
const defaultProps = {
  list: Array(4)
    .fill(QuestionDefaultProps)
    .map((item, index) => {
      return {
        ...item,
        id: shortid.generate(),
        question: `Question ${index + 1}`
      };
    })
};

export { propTypes, defaultProps };
