const en_us = {
  Header: "Header",
  Home: "Home",
  Destinations: "Destinations",
  "Local Market": "Local Market",
  Stay: "Stay",
  News: "News",
  Contact: "Contact"
};

export { en_us };
