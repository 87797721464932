/**
 * Defines the data requirements for the component
 *
 * @see CategoryAsThumb.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { CategoryPropTypes, CategoryDefaultProps } from "../Category";
import { ImageResponsivePlaceholderCom169 } from "../../ImageResponsive";

/**
 * Defines the prop types
 */
const propTypes = {
  ...CategoryPropTypes
};

/**
 * Defines the default props
 */
const defaultProps = {
  ...CategoryDefaultProps,
  image: {
    ...ImageResponsivePlaceholderCom169,
    sizes: "320px"
  }
};

export { propTypes, defaultProps };
