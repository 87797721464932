const en_us = {
  LoginForm: "LoginForm",
  login: "Sign in",
  email: "Email address",
  password: "Password",
  forgot_pass: "Forgot password?",
  remember_me: "Remember me",
  register_text: "Lorem ipsum dolor sit. ",
  register: "Join now!",
};

export { en_us };
