/**
 * Defines the data requirements for the component
 *
 * @see Hero.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import {
  ImageResponsivePropTypes,
  ImageResponsiveDefaultProps,
  ImageResponsivePlaceholderCom169
} from "../../ImageResponsive";

/**
 * Defines the prop types
 *
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/About%20us/getAboutUs
 */
const propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape(ImageResponsivePropTypes))
};

/**
 * Defines the default props
 */
const defaultProps = {
  images: Array(5).fill(ImageResponsivePlaceholderCom169)
};

export { propTypes, defaultProps };
