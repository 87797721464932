/**
 * Component short description
 *
 * @see ProductSearch.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import shortid from "shortid";

/**
 * Imports other components and hooks
 */
import ProductAsThumb from "../ProductAsThumb";
import MarketFilters from "../../_localMarket/MarketFilters";
import { FiltersProvider, useFilters } from "../../../hooks";
import FilteredBy from "../../FilteredBy";
import {
  useDestinationsSimpleSwr,
  useDestinationsSwr
} from "../../../hooks/api-hooks/useDestination/useDestination";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./ProductSearch.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./ProductSearch.lang.ro-ro";
import { hu_hu } from "./ProductSearch.lang.hu-hu";
import { en_us } from "./ProductSearch.lang.en-us";
import { de_de } from "./ProductSearch.lang.de-de";
import useMarket, {
  useMarketManufacturersSwr,
  useMarketCategoriesSwr
} from "../../_localMarket/LocalMarket/LocalMarket.logic";

i18n.addResourceBundle("ro-RO", "ProductSearch", ro_ro);
i18n.addResourceBundle("hu-HU", "ProductSearch", hu_hu);
i18n.addResourceBundle("en-US", "ProductSearch", en_us);
i18n.addResourceBundle("de-DE", "ProductSearch", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {}
}));

/**
 * Displays the component
 */
const ProductSearch = props => {
  const { defaultProducts } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("ProductSearch");

  const [products, setProducts] = useState(defaultProducts);
  const { filters, setData, parseFiltersForQuery } = useFilters();
  const { data: destinations = [] } = useDestinationsSwr();
  const { data: sellers = [] } = useMarketManufacturersSwr();
  const { data: categories = [] } = useMarketCategoriesSwr();
  const { getProducts } = useMarket();

  useEffect(() => {
    if (destinations.length && sellers.length && categories.length) {
      setData({
        destinations,
        sellers,
        categories
      });
    }
  }, [destinations, sellers, categories]);

  const fetchProducts = async filters => {
    getProducts(filters).then(results => {
      setProducts(results.data);
    });
  };

  useEffect(() => {
    fetchProducts(parseFiltersForQuery(filters));
  }, [filters]);

  const productList = products.map((item, index) => (
    <Grid item xs={12} md={6} key={item.id}>
      <ProductAsThumb {...item} />
    </Grid>
  ));

  return (
    <Grid container className={clsx(container, "ProductSearch")}>
      <Grid item xs={12} md={3}>
        <MarketFilters />
      </Grid>
      <Grid container item xs={12} md={9} spacing={2}>
        <Grid item xs={12}>
          <FilteredBy />
        </Grid>
        <Grid item xs={12}>
          {productList}
        </Grid>
      </Grid>
    </Grid>
  );
};

ProductSearch.propTypes = propTypes;
ProductSearch.defaultProps = defaultProps;

export default ProductSearch;
export {
  propTypes as ProductSearchPropTypes,
  defaultProps as ProductSearchDefaultProps
};
