/**
 * Component short description
 *
 * @see Product.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState } from "react";
import clsx from "clsx";
import shortid from "shortid";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Breadcrumbs from "../../Breadcrumbs";
import Card2, {
  Card2SingleRowStyles,
  Card2SingleRowStylesInverted
} from "../../Card2";
import Carousel from "../../Carousel";
import ImageResponsive from "../../ImageResponsive";
import { useUnsplash } from "../../../hooks";
import {
  TextSection,
  Template,
  Actions,
  TextPrice,
  PageHeader
} from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Product.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Product.lang.ro-ro";
import { hu_hu } from "./Product.lang.hu-hu";
import { en_us } from "./Product.lang.en-us";
import { de_de } from "./Product.lang.de-de";

i18n.addResourceBundle("ro-RO", "Product", ro_ro);
i18n.addResourceBundle("hu-HU", "Product", hu_hu);
i18n.addResourceBundle("en-US", "Product", en_us);
i18n.addResourceBundle("de-DE", "Product", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
  form: {
    width: "100%",
    // NOTE: This has to be fixed
    minWidth: "7em"
  }
}));

/**
 * Displays the component
 */
const Product = props => {
  const {
    name,
    images,
    description,
    price,
    units,
    sectionDescription1,
    sectionListing,
    sectionDescription2,
    destinationName
  } = props;
  const { container, form } = useStyles(props);
  const { t } = useTranslation("Product");

  /**
   * Loads unsplash images
   */
  const unsplashImages = useUnsplash({
    keyword: "Vegetables",
    page: 5,
    photos: 3,
    params: { orientation: "landscape" }
  });

  /**
   * Manages the Units select box
   */
  const [unit, setUnit] = useState();

  const handleChange = event => {
    setUnit(event.target.value);
  };

  const unitsList =
    units &&
    units.map((item, index) => {
      const { id, name, value } = item;

      return (
        <MenuItem id={id} value={value} key={id}>
          {name}
        </MenuItem>
      );
    });

  /**
   * Displays the hero card
   */
  const images2 = unsplashImages ? unsplashImages : images;

  const carouselSteps1 = images2.map(image => {
    const { title, id } = image;

    return {
      id: id,
      title: title,
      content: <ImageResponsive {...image} />
    };
  });

  const cardContent1 = <TextSection description={description} />;

  const price1 = <TextPrice text={`${price} ${t("RON / Unit")}`} />;

  const select1 = (
    <FormControl variant="outlined" className={clsx(form)}>
      <InputLabel id="product-units-select-label">{t("Units")}</InputLabel>
      <Select
        labelId="product-units-select-label"
        id="product-units-select"
        value={unit}
        onChange={handleChange}
      >
        {unitsList}
      </Select>
    </FormControl>
  );

  const button1 = (
    <Button variant="contained" color="primary" size="large">
      {t("Add to cart")}
    </Button>
  );

  const list1 = (
    <Actions
      items={[
        { id: shortid.generate(), action: select1 },
        { id: shortid.generate(), action: button1 }
      ]}
    />
  );

  const cardActions1 = (
    <Actions
      items={[
        { id: shortid.generate(), action: price1 },
        { id: shortid.generate(), action: list1 }
      ]}
      orientation="vertical"
    />
  );

  const cardWithMedia1 = {
    image: null,
    carousel: <Carousel steps={carouselSteps1} />,
    content: cardContent1,
    actions: cardActions1,
    customStyles: Card2SingleRowStyles({ ...props, breakpoint: "md" })
  };

  /**
   * Displays the template
   */
  const cardContent2 = (
    <TextSection
      subtitle={t("Description")}
      description={sectionDescription1}
      listing={sectionListing}
      description2={sectionDescription2}
    />
  );

  const cardWithMedia2 = {
    image: null,
    images: null,
    carousel: null,
    content: cardContent2,
    actions: null,
    customStyles: {}
  };

  return (
    <Layout header={{ currentPage: "Local Market" }}>
      <Grid container className={clsx(container, "Product")} spacing={1}>
        <Grid item xs={12}>
          <PageHeader
            breadcrumbs=<Breadcrumbs />
            overline={destinationName}
            title={name}
          />
        </Grid>
        <Grid item xs={12}>
          <Card2 {...cardWithMedia1} />
        </Grid>
        <Grid item xs={12}>
          <Template items={[<Card2 {...cardWithMedia2} />]} />
        </Grid>
      </Grid>
    </Layout>
  );
};

Product.propTypes = propTypes;
Product.defaultProps = defaultProps;

export default Product;
export { propTypes as ProductPropTypes, defaultProps as ProductDefaultProps };
