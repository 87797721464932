/**
 * Displays a destination
 *
 * @see Destination.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { forwardRef, useState, useEffect } from "react";
import clsx from "clsx";
import shortid from "shortid";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Breadcrumbs from "../../Breadcrumbs";
import Card2, {
  Card2SingleRowStyles,
  Card2SingleRowStylesInverted
} from "../../Card2";
import Carousel from "../../Carousel";
import ImageResponsive from "../../ImageResponsive";
import Category from "../../_category/Category";
import CategoriesAsThumbs from "../../_category/CategoriesAsThumbs";
import LocalMarketAsHero from "../../_localMarket/LocalMarketAsHero";
import StayAsHero from "../../_stay/StayAsHero";
import Videos from "../../_videos/Videos";
import Icon from "../../Icon";
import Gps from "../../Gps";
import { useUnsplash } from "../../../hooks";
import { TextSection, Actions, PageHeader } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Destination.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Destination.lang.ro-ro";
import { hu_hu } from "./Destination.lang.hu-hu";
import { en_us } from "./Destination.lang.en-us";
import { de_de } from "./Destination.lang.de-de";

i18n.addResourceBundle("ro-RO", "Destination", ro_ro);
i18n.addResourceBundle("hu-HU", "Destination", hu_hu);
i18n.addResourceBundle("en-US", "Destination", en_us);
i18n.addResourceBundle("de-DE", "Destination", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  }
}));

/**
 * Displays the component
 */
const Destination = props => {
  const {
    slug,
    name,
    alternateName,
    description,
    images,
    videos,
    categories,
    categoriesDescription,
    stay,
    stays,
    localMarket,
    products,
    gps,
    videoIcon,
    pdfIcon,
    transportationIcon,
    webcamIcon,
    longDescription
  } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("Destination");

  /**
   * Loads unsplash images
   */
  const unsplashImages = useUnsplash({
    keyword: "Mountain resort",
    page: 1,
    photos: 3,
    params: { orientation: "landscape" }
  });

  /**
   * Routes to a destination category
   */
  const match = useRouteMatch();
  const { path } = match;

  /**
   * Defines links to routes
   */
  const LinkToVideos = forwardRef((props, ref) => (
    <RouterLink ref={ref} to={`${slug}/videos/`} {...props} />
  ));

  /**
   * Manages the descriptions / Show more
   */
  const [showMoreButtonText, setShowMoreButtonText] = useState(t("Show more"));

  const [description1, setDescription1] = useState(description);

  const handleShowMoreClick = () => {
    const newDescription =
      description1 === description ? longDescription : description;

    setDescription1(newDescription);
  };

  useEffect(() => {
    const newShowMoreButtonText =
      description1 === description ? t("Show more") : t("Show less");

    setShowMoreButtonText(newShowMoreButtonText);
  }, [description1]);

  /**
   * Displays the hero card
   */
  const images2 = unsplashImages ? unsplashImages : images;

  const carouselSteps1 = images2.map(image => {
    const { title } = image;

    return {
      id: shortid.generate(),
      title: title,
      content: <ImageResponsive {...image} />
    };
  });

  const buttonShowMore = (
    <Button variant="outlined" onClick={() => handleShowMoreClick()}>
      {showMoreButtonText}
    </Button>
  );

  const cardContent1 = (
    <TextSection description={description1} button={buttonShowMore} />
  );

  const buttonVideo = (
    <Button variant="outlined" color="secondary" component={LinkToVideos}>
      <Icon {...videoIcon} name={t("Videos")} />
    </Button>
  );

  const buttonPdf = (
    <Button variant="outlined">
      <Icon {...pdfIcon} name={t("Download PDF")} />
    </Button>
  );

  const buttonTransportation = (
    <Button variant="outlined">
      <Icon {...transportationIcon} name={t("Transportation")} />
    </Button>
  );

  const buttonWebcam = (
    <Button variant="outlined">
      <Icon {...webcamIcon} name={t("Webcam")} />
    </Button>
  );

  const cardActions1 = (
    <Actions
      items={[
        { id: shortid.generate(), action: <Gps {...gps} /> },
        { id: shortid.generate(), action: buttonTransportation },
        { id: shortid.generate(), action: buttonPdf },
        { id: shortid.generate(), action: buttonWebcam },
        { id: shortid.generate(), action: buttonVideo }
      ]}
      orientation="horizontal"
    />
  );

  const cardWithMedia1 = {
    image: null,
    images: null,
    carousel: <Carousel steps={carouselSteps1} />,
    content: cardContent1,
    actions: cardActions1,
    customStyles: Card2SingleRowStyles({
      ...props,
      breakpoint: "xxl"
    })
  };

  /**
   * Displays the widget
   *
   * // NOTE: change ${name1} to ${name}, ${slug1} to ${slug}
   */
  const name1 = "Miercurea Ciuc";
  const slug1 = "miercurea-ciuc";
  const url = `https://forecast7.com/en/46d3725d80/${slug1}/`;
  const widget2 = (
    <>
      <a
        class="weatherwidget-io"
        href={url}
        data-label_1={name1}
        data-label_2=""
        data-theme="pure"
      >
        {name1} {t("Weather")}
      </a>
      {
        !(function(d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (!d.getElementById(id)) {
            js = d.createElement(s);
            js.id = id;
            js.src = "https://weatherwidget.io/js/widget.min.js";
            fjs.parentNode.insertBefore(js, fjs);
          }
        })(document, "script", "weatherwidget-io-js")
      }
    </>
  );

  const cardWithMedia3 = {
    image: null,
    widget: widget2
  };

  /**
   * Displays categories
   */
  const cardContent2 = (
    <TextSection
      subtitle={t("Location Categories")}
      description={categoriesDescription}
    />
  );

  const cardWithMedia2 = {
    image: null,
    images: null,
    carousel: null,
    content: cardContent2,
    actions: <CategoriesAsThumbs categories={categories} />,
    customStyles: {}
  };

  return (
    <Switch>
      <Route path={`${path}/videos`}>
        <Videos destinationName={name} />
      </Route>
      <Route path={`${path}/:slug`}>
        <Category destinationName={name} />
      </Route>
      <Route path={path}>
        <Layout header={{ currentPage: "Destinations" }}>
          <Grid
            container
            spacing={1}
            className={clsx(container, "Destination")}
          >
            <Grid item xs={12}>
              <PageHeader breadcrumbs=<Breadcrumbs /> title={name} />
            </Grid>
            <Grid item xs={12}>
              <Card2 {...cardWithMedia1} />
            </Grid>
            <Grid item xs={12}>
              <Card2 {...cardWithMedia3} />
            </Grid>
            <Grid item xs={12}>
              <Card2 {...cardWithMedia2} />
            </Grid>
            <Grid item xs={12}>
              <LocalMarketAsHero
                localMarket={localMarket}
                products={products}
              />
            </Grid>
            <Grid item xs={12}>
              <StayAsHero stay={stay} stays={stays} />
            </Grid>
          </Grid>
        </Layout>
      </Route>
    </Switch>
  );
};

Destination.propTypes = propTypes;
Destination.defaultProps = defaultProps;

export default Destination;
export {
  propTypes as DestinationPropTypes,
  defaultProps as DestinationDefaultProps
};
