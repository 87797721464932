const ro_ro = {
  Routes: "Routes (ro_ro)",
  destinations: "destinatii",
  "local-market": "piata-locala",
  stay: "acomodare",
  faq: "intrebari",
  contact: "contact",
  news: "stiri",
  events: "evenimente",
  "terms-and-conditions": "termeni-si-conditii",
  account: "cont",
  settings: "setari",
  "order-history": "istoric-comenzi",
  "booking-history": "istoric-cazari",
  search: "cautare",
  cart: "cos-cumparaturi",
  order: "comanda",
  checkout: "finalizare-comanda",
  "media-and-press": "media",
  support: "suport",
  "privacy-policy": "confidentialitate",
  sitemap: "harta-site"
};

export { ro_ro };
