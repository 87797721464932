/**
 * Displays an Article (News detail)
 *
 * @see Article.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Breadcrumbs from "../../Breadcrumbs";
import Card2, {
  Card2SingleRowStyles,
  Card2SingleRowStylesInverted
} from "../../Card2";
import ImageResponsive from "../../ImageResponsive";
import { useUnsplash } from "../../../hooks";
import { TextSection, Template, PageHeader } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Article.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Article.lang.ro-ro";
import { hu_hu } from "./Article.lang.hu-hu";
import { en_us } from "./Article.lang.en-us";
import { de_de } from "./Article.lang.de-de";

i18n.addResourceBundle("ro-RO", "Article", ro_ro);
i18n.addResourceBundle("hu-HU", "Article", hu_hu);
i18n.addResourceBundle("en-US", "Article", en_us);
i18n.addResourceBundle("de-DE", "Article", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: { ...theme.custom.grid.removeNegativeMargin }
}));

/**
 * Displays the component
 */
const Article = props => {
  const {
    name,
    destination,
    image,
    description,
    sectionDescription1,
    image1,
    image1Description,
    image2,
    image2Description,
    sectionTitle,
    sectionListing,
    sectionDescription3,
    sectionDescription2
  } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("Article");

  /**
   * Loads unsplash images
   */
  const unsplashImages = useUnsplash({
    keyword: "News",
    page: 11,
    photos: 3,
    params: { orientation: "landscape" }
  });

  /**
   * Displays the hero
   */
  const cardContent1 = <TextSection description={description} />;

  const cardWithMedia1 = {
    image: unsplashImages ? unsplashImages[0] : image,
    images: null,
    carousel: null,
    content: cardContent1,
    customStyles: Card2SingleRowStyles({ ...props, breakpoint: "md" })
  };

  /**
   * Displays the template
   */
  const cardWithMedia2 = {
    image: null,
    images: null,
    content: <TextSection description={sectionDescription1} />,
    customStyles: {}
  };

  const cardWithMedia3 = {
    image: unsplashImages ? unsplashImages[1] : image1,
    images: null,
    content: <TextSection description={image1Description} />,
    customStyles: Card2SingleRowStylesInverted({
      ...props,
      breakpoint: "sm"
    })
  };

  const cardWithMedia4 = {
    image: null,
    images: null,
    content: (
      <TextSection
        subtitle={sectionTitle}
        description={sectionDescription2}
        listing={sectionListing}
      />
    ),
    customStyles: {}
  };

  const cardWithMedia5 = {
    image: unsplashImages ? unsplashImages[2] : image2,
    images: null,
    content: <TextSection description={image2Description} />,
    customStyles: Card2SingleRowStyles({ ...props, breakpoint: "sm" })
  };

  return (
    <Layout header={{ currentPage: "News" }}>
      <Grid container spacing={1} className={clsx(container, "Article")}>
        <Grid item xs={12}>
          <PageHeader
            breadcrumbs=<Breadcrumbs />
            overline={destination}
            title={name}
          />
        </Grid>
        <Grid item xs={12}>
          <Card2 {...cardWithMedia1} />
        </Grid>
        <Grid item xs={12}>
          <Template
            items={[
              <Card2 {...cardWithMedia2} />,
              <Card2 {...cardWithMedia3} />,
              <Card2 {...cardWithMedia4} />,
              <Card2 {...cardWithMedia5} />
            ]}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

Article.propTypes = propTypes;
Article.defaultProps = defaultProps;

export default Article;
export { propTypes as ArticlePropTypes, defaultProps as ArticleDefaultProps };
