/**
 * Component short description
 *
 * @see MenuItems.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { forwardRef } from "react";
import clsx from "clsx";
import shortid from "shortid";
import { Link as RouterLink } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import { convertMenuNameToRoute } from "../Menu";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./MenuItems.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./MenuItems.lang.ro-ro";
import { hu_hu } from "./MenuItems.lang.hu-hu";
import { en_us } from "./MenuItems.lang.en-us";
import { de_de } from "./MenuItems.lang.de-de";

i18n.addResourceBundle("ro-RO", "MenuItems", ro_ro);
i18n.addResourceBundle("hu-HU", "MenuItems", hu_hu);
i18n.addResourceBundle("en-US", "MenuItems", en_us);
i18n.addResourceBundle("de-DE", "MenuItems", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  menu: {
    ["& .MuiTab-wrapper"]: {
      /**
       * Align left the items in the mobile menu
       * - By default they are centered
       */
      alignItems: "flex-start"
    }
  },

  menuItem: {
    /**
     * Remove that default 160px
     */
    minWidth: "auto"
  }
}));

/**
 * Displays the component
 */
const MenuItems = props => {
  const { items, activeItem, orientation } = props;

  const { menu, menuItem } = useStyles(props);
  const { t } = useTranslation("MenuItems");

  /**
   * Creates a link to a route
   */
  const LinkToRoute = forwardRef((props, ref) => (
    <Link component={RouterLink} underline="none" ref={ref} {...props} />
  ));

  /**
   * Defines the menu items as tab labels
   */
  const labels = items.map(item => {
    const { id, name } = item;

    return (
      <Tab
        className={clsx(menuItem, "MenuItem")}
        key={id}
        label={t(name)}
        component={LinkToRoute}
        to={convertMenuNameToRoute(t(name))}
      />
    );
  });

  /**
   * Defines the current menu item
   */
  const currentMenuItem = items.findIndex(item => item.name === activeItem);

  return (
    <Tabs
      className={clsx(menu, "MenuItems")}
      value={currentMenuItem}
      orientation={orientation}
    >
      {labels}
    </Tabs>
  );
};

MenuItems.propTypes = propTypes;
MenuItems.defaultProps = defaultProps;

export default MenuItems;
export {
  propTypes as MenuItemsPropTypes,
  defaultProps as MenuItemsDefaultProps
};
