/**
 * Component short description
 *
 * @see useFaq.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { createContext, useContext, useState } from "react";

/**
 * Imports other components and hooks
 */
import useRequests from "../../useRequests";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./useFaq.data";
import useSWR from "swr";

const useFaqSwr = (props) => {
  const { get } = useRequests();
  return useSWR("faq", get);
};

/**
 * Displays the component
 */
const useFaq = (props) => {
  const { get } = useRequests();

  const getFaq = () => {
    return get("faq");
  };

  return { useFaqSwr, getFaq };
};

useFaq.propTypes = propTypes;
useFaq.defaultProps = defaultProps;

export default useFaq;
export { useFaq };
