/**
 * Defines the data requirements for the component
 *
 * @see RequestRoleForm.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import Lorem from "react-lorem-component";

/**
 * Imports prop types from other components
 */
import { AccountPropTypes, AccountDefaultProps } from "../Account";
import {
  DestinationPropTypes,
  DestinationDefaultProps
} from "../../_destination/Destination";

/**
 * Defines the role types
 */
const roles = ["Seller", "Host"];

/**
 * Defines the prop types
 */
const propTypes = {
  /**
   * From the API
   * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/Account/createRequestRole
   */
  role: PropTypes.oneOf(roles),
  destinationId: PropTypes.number,
  phone: PropTypes.string,
  message: PropTypes.string,
  /**
   * Other props
   */
  destinations: PropTypes.arrayOf(PropTypes.shape(DestinationPropTypes)),
  account: PropTypes.shape(AccountPropTypes),
  terms: PropTypes.string
};

/**
 * Defines the default props
 */
const defaultProps = {
  role: "Seller",
  destinationId: null,
  phone: null,
  message: null,
  destinations: Array(10)
    .fill(DestinationDefaultProps)
    .map((item, index) => {
      return {
        ...item,
        name: `Destination ${index + 1}`,
        id: shortid.generate()
      };
    }),
  account: AccountDefaultProps,
  terms: <Lorem seed="5" count="1" sentenceUpperBound="7" />
};

export { propTypes, defaultProps, roles };
