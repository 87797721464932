/**
 * Defines the data requirements for the component
 *
 * @see MenuItems.md for details
 */
import PropTypes from "prop-types";
import shortid from "shortid";

/**
 * Defines the prop types
 */
const propTypes = {
  /**
   * The menu items
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * An unique ID
       * - Used when displayed in a list
       */
      id: PropTypes.string,
      name: PropTypes.tring
    })
  ),
  /**
   * The current menu item
   */
  activeItem: PropTypes.string,
  /**
   * How to display the menu items
   * @see https://material-ui.com/api/tabs/
   */
  orientation: PropTypes.oneOf(["vertical", "horizontal"])
};

/**
 * Defines the default props
 */
const defaultProps = {
  items: [{ id: shortid.generate(), name: "Menu item 1" }],
  activeItem: "Menu item 1",
  orientation: "horizontal"
};

export { propTypes, defaultProps };
