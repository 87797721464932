/**
 * Displays an Attraction
 *
 * @see Attraction.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import shortid from "shortid";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Breadcrumbs from "../../Breadcrumbs";
import Card2, {
  Card2SingleRowStyles,
  Card2SingleRowStylesInverted
} from "../../Card2";
import Carousel from "../../Carousel";
import ImageResponsive from "../../ImageResponsive";
import Icon from "../../Icon";
import Icons from "../../Icons";
import Gps from "../../Gps";
import { useUnsplash } from "../../../hooks";
import { TextSection, Template, Actions, PageHeader } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Attraction.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Attraction.lang.ro-ro";
import { hu_hu } from "./Attraction.lang.hu-hu";
import { en_us } from "./Attraction.lang.en-us";
import { de_de } from "./Attraction.lang.de-de";

i18n.addResourceBundle("ro-RO", "Attraction", ro_ro);
i18n.addResourceBundle("hu-HU", "Attraction", hu_hu);
i18n.addResourceBundle("en-US", "Attraction", en_us);
i18n.addResourceBundle("de-DE", "Attraction", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: { ...theme.custom.grid.removeNegativeMargin },

  text: {
    /**
     * Keep 45-60 chars per row for long text
     */
    maxWidth: theme.custom.typography.maxTextWidth
  },

  aligned: {
    /**
     * Align opening hours etc icons to the GPS icon above
     * - horizontally the spacing does the job, here we adjust the vertical space
     */
    marginLeft: theme.spacing(2)
  }
}));

/**
 * Displays the component
 */
const Attraction = props => {
  const {
    name,
    description,
    images,
    gps,
    openingHours,
    prices,
    facebook,
    url,
    sectionTitle,
    sectionDescription1,
    sectionListing,
    image1,
    image1Description,
    sectionDescription2,
    image2,
    image2Description,
    destinationName,
    openingHoursIcon,
    pricesIcon,
    facebookIcon,
    webIcon,
    audio,
    audioIcon,
    routeIcon,
    track
  } = props;
  const { container, text, aligned } = useStyles(props);
  const { t } = useTranslation("Attraction");

  /**
   * Loads unsplash images
   */
  const unsplashImages = useUnsplash({
    keyword: "Attractions",
    page: 10,
    photos: 3,
    params: { orientation: "landscape" }
  });

  const images2 = unsplashImages ? unsplashImages : images;

  /**
   * Displays the hero
   */
  const carouselSteps = images2
    .map(image => {
      const { title, id } = image;

      return {
        id: id,
        title: title,
        content: <ImageResponsive {...image} />
      };
    })
    .filter(item => item);

  const cardContent1 = <TextSection description={description} />;

  const buttonAudio = (
    <Button variant="outlined" href={audio}>
      <Icon {...audioIcon} name={t("Audio")} />
    </Button>
  );

  const buttonRoute = (
    <Button variant="outlined" href={track}>
      <Icon {...routeIcon} name={t("Route")} />
    </Button>
  );

  const actions1 = (
    <Actions
      items={[
        { id: shortid.generate(), action: <Gps {...gps} /> },
        { id: shortid.generate(), action: buttonAudio }
      ]}
    />
  );

  const actions1a = (
    <Actions
      items={[
        { id: shortid.generate(), action: buttonRoute },
        { id: shortid.generate(), action: buttonAudio }
      ]}
    />
  );

  const actions2 = (
    <div className={clsx(aligned, "Aligned")}>
      <Actions
        className={clsx(aligned, "Aligned")}
        items={[
          {
            id: shortid.generate(),
            action: <Icon {...openingHoursIcon} name={openingHours} />
          },
          {
            id: shortid.generate(),
            action: <Icon {...pricesIcon} name={prices} />
          }
        ]}
        orientation="vertical"
      />
    </div>
  );

  const actions3 = (
    <div className={clsx(aligned, "Aligned")}>
      <Actions
        items={[
          {
            id: shortid.generate(),
            action: "More info:"
          },
          {
            id: shortid.generate(),
            action: <Icon {...facebookIcon} url={facebook} />
          },
          {
            id: shortid.generate(),
            action: <Icon {...webIcon} url={url} />
          }
        ]}
      />
    </div>
  );

  const cardActions1 = (
    <Actions
      items={[
        { id: shortid.generate(), action: actions1a },
        { id: shortid.generate(), action: actions2 },
        { id: shortid.generate(), action: actions3 }
      ]}
      orientation="vertical"
    />
  );

  const cardWithMedia1 = {
    image: null,
    images: null,
    carousel: <Carousel steps={carouselSteps} />,
    content: cardContent1,
    actions: cardActions1,
    customStyles: Card2SingleRowStyles({ ...props, breakpoint: "xxl" })
  };

  /**
   * Loads unsplash images
   */
  const unsplashImages2 = useUnsplash({
    keyword: "Attractions",
    page: 15,
    photos: 2,
    params: { orientation: "landscape" }
  });

  /**
   * Displays the template
   */
  const cardWithMedia2 = {
    image: unsplashImages2 ? unsplashImages2[0] : image1,
    images: null,
    content: <TextSection description={image1Description} />,
    customStyles: Card2SingleRowStyles({
      ...props,
      breakpoint: "sm"
    }),
    cardContainerProps: { alignItems: "center" }
  };

  const cardWithMedia3 = {
    image: unsplashImages2 ? unsplashImages2[1] : image2,
    images: null,
    content: <TextSection description={image2Description} />,
    customStyles: Card2SingleRowStylesInverted({ ...props, breakpoint: "sm" }),
    cardContainerProps: { alignItems: "center" }
  };

  const cardWithMedia4 = {
    image: null,
    content: (
      <TextSection
        subtitle={sectionTitle}
        description={sectionDescription1}
        listing={sectionListing}
      />
    ),
    customStyles: Card2SingleRowStyles({
      ...props,
      breakpoint: "sm"
    }),
    cardContainerProps: { alignItems: "center" }
  };

  const cardWithMedia5 = {
    image: null,
    content: <TextSection description={sectionDescription2} />,
    customStyles: Card2SingleRowStyles({
      ...props,
      breakpoint: "sm"
    }),
    cardContainerProps: { alignItems: "center" }
  };

  return (
    <Layout header={{ currentPage: "Destinations" }}>
      <Grid container spacing={1} className={clsx(container, "Attraction")}>
        <Grid item xs={12}>
          <PageHeader
            breadcrumbs=<Breadcrumbs />
            overline={destinationName}
            title={name}
          />
        </Grid>
        <Grid item xs={12}>
          {/**/}
          <Card2 {...cardWithMedia1} />
        </Grid>
        <Grid item xs={12}>
          <Template
            items={[
              <Card2 {...cardWithMedia4} />,
              <Card2 {...cardWithMedia2} />,
              <Card2 {...cardWithMedia5} />,
              <Card2 {...cardWithMedia3} />
            ]}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

Attraction.propTypes = propTypes;
Attraction.defaultProps = defaultProps;

export default Attraction;
export {
  propTypes as AttractionPropTypes,
  defaultProps as AttractionDefaultProps
};
