/**
 * Component short description
 *
 * @see RequestRoleForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState } from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
// NOTE: It should be refactored to use `<List2> and <TextSection>`

/**
 * Imports data
 */
import { propTypes, defaultProps, roles } from "./RequestRoleForm.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./RequestRoleForm.lang.ro-ro";
import { hu_hu } from "./RequestRoleForm.lang.hu-hu";
import { en_us } from "./RequestRoleForm.lang.en-us";
import { de_de } from "./RequestRoleForm.lang.de-de";

i18n.addResourceBundle("ro-RO", "RequestRoleForm", ro_ro);
i18n.addResourceBundle("hu-HU", "RequestRoleForm", hu_hu);
i18n.addResourceBundle("en-US", "RequestRoleForm", en_us);
i18n.addResourceBundle("de-DE", "RequestRoleForm", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: { ...theme.custom.grid.removeNegativeMargin },

  select: {
    width: "100%"
    //maxWidth: 320
  },

  grid: {
    ["& .MuiGrid-item"]: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0
      }
    }
  },

  gridHorizontal: {
    ["& .MuiGrid-item"]: {
      [theme.breakpoints.up("sm")]: {
        paddingLeft: 0
      }
    },
    ["& :last-child"]: {
      [theme.breakpoints.up("sm")]: {
        paddingRight: 0
      }
    }
  },
  text: {
    maxWidth: theme.custom.typography.maxTextWidth
  }
}));

/**
 * Displays the component
 */
const RequestRoleForm = props => {
  const {
    role: defaultRole,
    account,
    destinations,
    phone,
    message,
    terms
  } = props;
  const { name, email } = account;

  const { container, select, grid, gridHorizontal, text } = useStyles(props);
  const { t } = useTranslation("RequestRoleForm");

  /**
   * Manages the Role radio input
   */
  const [role, setRole] = useState(defaultRole);

  const handleChangeRole = event => {
    setRole(event.target.value);
  };

  /**
   * Handles the Destinations select box
   */
  const [destination, setDestination] = useState();

  const handleChangeDestination = event => {
    setDestination(event.target.value);
  };

  /**
   * Handles the terms checkbox
   */
  const [accept, setAccept] = useState(false);

  const handleChangeCheckbox = event => {
    setAccept(event.target.checked);
  };

  /**
   * Displays the roles list
   */
  const rolesList = roles.map(item => {
    return <FormControlLabel value={item} control={<Radio />} label={item} />;
  });

  /**
   * Displays the role form
   */
  const roleForm = (
    <FormControl component="fieldset">
      <FormLabel component="legend">{t("Role")}</FormLabel>
      <RadioGroup
        row
        aria-label="role"
        name="role"
        value={role}
        onChange={handleChangeRole}
      >
        {rolesList}
      </RadioGroup>
    </FormControl>
  );

  /**
   * Displays the account info
   */
  const accountInfo = (
    <List dense disablePadding>
      <ListItem dense disableGutters>
        <ListItemText dense primary={`${t("Name")}: ${name}`} />
      </ListItem>
      <ListItem dense disableGutters>
        <ListItemText dense primary={`${t("Email")}: ${email}`} />
      </ListItem>
    </List>
  );

  /**
   * Displays the destinations list
   */
  const destinationsList = destinations.map(item => {
    const { id, name } = item;

    return (
      <MenuItem value={id} key={id}>
        {name}
      </MenuItem>
    );
  });

  /**
   * Displays the Destinations select box
   */
  const destinationsSelect = (
    <FormControl variant="outlined" className={clsx(select)}>
      <InputLabel id="roles-destination-select-label">
        {t("Select destination")}
      </InputLabel>
      <Select
        label={t("Select destination")}
        labelId="roles-destination-select-label"
        id="roles-destination-select"
        value={destination}
        onChange={handleChangeDestination}
      >
        {destinationsList}
      </Select>
    </FormControl>
  );

  /**
   * Displays the phone number text input
   */
  const phoneTextField = (
    <FormControl variant="outlined" className={clsx(select)}>
      <TextField
        label={t("Phone number")}
        variant="outlined"
        id="phone"
        defaultValue={phone}
      />
    </FormControl>
  );

  /**
   * Displays the message text input
   */
  const messageTextField = (
    <FormControl variant="outlined" className={clsx(select)}>
      <TextField
        multiline
        rows={4}
        label={t("Message")}
        variant="outlined"
        id="message"
        defaultValue={message}
      />
    </FormControl>
  );

  /**
   * Displays the terms checkbox
   */
  const termsCheckbox = (
    <FormControlLabel
      className={clsx(text)}
      control={
        <Checkbox
          checked={accept}
          onChange={handleChangeCheckbox}
          name="terms"
        />
      }
      label={terms}
    />
  );

  /**
   * Displays the buttons
   */
  const buttons = (
    <Grid container spacing={1} className={clsx(container)}>
      <Grid item>
        <Button>{t("Cancel")}</Button>
      </Grid>
      <Grid item>
        <Button variant="contained">{t("Send request")}</Button>
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      spacing={2}
      className={clsx(container, grid, "RequestRoleForm")}
    >
      <Grid item xs={12}>
        {accountInfo}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {roleForm}
      </Grid>
      <Grid
        item
        xs={12}
        container
        spacing={2}
        className={clsx(container, grid, gridHorizontal)}
      >
        <Grid item xs={12} md={6}>
          {destinationsSelect}
        </Grid>
        <Grid item xs={12} md={6}>
          {phoneTextField}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {messageTextField}
      </Grid>
      <Grid item xs={12}>
        {termsCheckbox}
      </Grid>
      <Grid item xs={12}>
        {buttons}
      </Grid>
    </Grid>
  );
};

RequestRoleForm.propTypes = propTypes;
RequestRoleForm.defaultProps = defaultProps;

export default RequestRoleForm;
export {
  propTypes as RequestRoleFormPropTypes,
  defaultProps as RequestRoleFormDefaultProps
};
