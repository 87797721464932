/**
 * Defines the data requirements for the component
 *
 * @see Contact.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import Lorem from "react-lorem-component";

/**
 * Imports prop types from other components
 */
import {
  ImageResponsivePropTypes,
  ImageResponsiveDefaultProps,
  ImageResponsivePlaceholderCom169
} from "../../ImageResponsive";
import {
  ContactFormPropTypes,
  ContactFormDefaultProps
} from "../ContactForm/ContactForm";

/**
 * Defines the prop types
 */
const propTypes = {
  description: PropTypes.string,
  image: PropTypes.shape(ImageResponsivePropTypes),
  contactForm: PropTypes.shape(ContactFormPropTypes)
};

/**
 * Defines the default props
 */
const defaultProps = {
  description: <Lorem seed="1" count="1" />,
  image: ImageResponsivePlaceholderCom169,
  contactForm: ContactFormDefaultProps
};

export { propTypes, defaultProps };
