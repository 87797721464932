/**
 * Converts an Unsplash specific image to the component's standard image format
 *
 * @see https://unsplash.com/documentation#supported-parameters
 */
const convertImageFormatFromUnsplash = props => {
  const { data, orientation, originalImageProps } = props;
  const { id, description, urls, user } = data;
  const { raw } = urls;
  const { name, username } = user;

  /**
   * Follows the Unsplash guidelines
   * @see https://help.unsplash.com/en/articles/2511315-guideline-attribution
   */
  const linkBack = "?utm_source=borsec&utm_medium=referral";

  const caption = `Photo by <a href="https://unsplash.com/@${username}${linkBack}">${name}</a> on <a href="https://unsplash.com/${linkBack}">Unsplash</a>`;

  const srcSet =
    orientation === "landscape"
      ? `${raw}${linkBack}&w=160 160w, ${raw}${linkBack}&w=320 320w, ${raw}${linkBack}&w=640 640w, ${raw}${linkBack}&w=1280 1280w`
      : `${raw}${linkBack}&w=100 100w, ${raw}${linkBack}&w=200 200w, ${raw}${linkBack}&w=400 400w`;

  const aspectRatio = orientation === "landscape" ? 9 / 16 : 1 / 1;

  return {
    ...originalImageProps,
    id: id,
    srcSet: srcSet,
    alt: `${description}`,
    aspectRatio: aspectRatio,
    caption: caption
  };
};

/**
 * Converts an API specific image format to the component's standard image format
 */
const convertImageFormatFromAPI = props => {
  const { alternateName, resolutions, author, defaultImage } = props;

  /**
   * Error handling / early return
   */
  if (!resolutions) return props;

  /**
   * If something goes wrong further with `resolutions` we'll return a placeholder image
   */
  let isSrcSetOk = true;

  /**
   * Aspect Ratio will be calculated from the last `resolution`
   */
  let aspectRatio = 1;

  // TODO: Fix the API, then remove the prefix

  const srcSet = resolutions.map(item => {
    const { width, height, path } = item;

    if (!width || !path || !height) isSrcSetOk = false;

    aspectRatio = height / width;
    return `${path} ${width}w`;
  });

  if (!isSrcSetOk) return defaultImage;

  const image = {
    srcSet: srcSet.join(","),
    alt: `${alternateName} by ${author}`,
    aspectRatio: aspectRatio
  };

  return image;
};

export { convertImageFormatFromAPI, convertImageFormatFromUnsplash };
