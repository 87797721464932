/**
 * Defines the data requirements for the component
 *
 * @see AttractionsList.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import Lorem from "react-lorem-component";
import shortid from "shortid";

/**
 * Imports prop types from other components
 */
import { AttractionPropTypes, AttractionDefaultProps } from "../Attraction";

/**
 * Defines the prop types
 */
const propTypes = {
  attractions: PropTypes.arrayOf(PropTypes.shape(AttractionPropTypes))
};

/**
 * Defines the default props
 */
const defaultProps = {
  attractions: Array(6)
    .fill(AttractionDefaultProps)
    .map((item, index) => {
      return {
        ...item,
        id: shortid.generate(),
        name: (
          <Lorem
            format="text"
            seed={index}
            count={1}
            sentenceLowerBound={1}
            sentenceUpperBound={3}
            paragraphLowerBound={1}
            paragraphUpperBound={1}
          />
        ),
        description: (
          <Lorem format="text" seed={index} count={1} sentenceUpperBound={2} />
        )
      };
    })
};

export { propTypes, defaultProps };
