/**
 * Component short description
 *
 * @see StayIntro.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
/**
 * Imports other components and hooks
 */
import Card2, {
  Card2SingleRowStyles,
  Card2SingleRowStylesInverted
} from "../../Card2";
import { useUnsplash } from "../../../hooks";
import { TextSection } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./StayIntro.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./StayIntro.lang.ro-ro";
import { hu_hu } from "./StayIntro.lang.hu-hu";
import { en_us } from "./StayIntro.lang.en-us";
import { de_de } from "./StayIntro.lang.de-de";

i18n.addResourceBundle("ro-RO", "StayIntro", ro_ro);
i18n.addResourceBundle("hu-HU", "StayIntro", hu_hu);
i18n.addResourceBundle("en-US", "StayIntro", en_us);
i18n.addResourceBundle("de-DE", "StayIntro", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {}
}));

/**
 * Displays the component
 */
const StayIntro = props => {
  const { description, image } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("StayIntro");

  /**
   * Loads unsplash images
   */
  const unsplashImages = useUnsplash({
    keyword: "Room",
    page: 3,
    photos: 1,
    params: { orientation: "landscape" }
  });

  /**
   * Displays the hero card
   */
  const cardContent1 = (
    <TextSection title={t("Stay")} description={description} />
  );

  const cardActions1 = (
    <Button variant="contained" color="primary" size="large">
      {t("Accommodation")}
    </Button>
  );

  const cardWithMedia1 = {
    image: unsplashImages ? unsplashImages[0] : image,
    images: null,
    content: cardContent1,
    actions: cardActions1,
    customStyles: Card2SingleRowStylesInverted({ ...props, breakpoint: "md" })
  };

  return <Card2 {...cardWithMedia1} />;
};

StayIntro.propTypes = propTypes;
StayIntro.defaultProps = defaultProps;

export default StayIntro;
export {
  propTypes as StayIntroPropTypes,
  defaultProps as StayIntroDefaultProps
};
