/**
 * Component short description
 *
 * @see Search.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import shortid from "shortid";
import { Redirect, useParams, useLocation } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import SearchForm from "../SearchForm";
import SearchResults from "../SearchResults";
import Layout from "../../Layout";
import Card2, {
  Card2SingleRowStyles,
  Card2SingleRowStylesInverted
} from "../../Card2";
import { TextSection, Actions } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Search.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Search.lang.ro-ro";
import { hu_hu } from "./Search.lang.hu-hu";
import { en_us } from "./Search.lang.en-us";
import { de_de } from "./Search.lang.de-de";

i18n.addResourceBundle("ro-RO", "Search", ro_ro);
i18n.addResourceBundle("hu-HU", "Search", hu_hu);
i18n.addResourceBundle("en-US", "Search", en_us);
i18n.addResourceBundle("de-DE", "Search", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  }
}));

/**
 * Displays the component
 */
const Search = props => {
  const { form, results } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("Search");

  /**
   * Loads the query params
   * - useParams was not suitable for this ....
   */
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const queryParamText = query.has("text") ? String(query.get("text")) : null;
  const queryParamCategory = query.has("category")
    ? String(query.get("category"))
    : null;

  /**
   * Displays the search form
   */
  const searchTitle = queryParamText
    ? `${t("Search results for")} "${queryParamText}"`
    : t("Search");

  const searchTitleWithCategory = queryParamCategory
    ? `${searchTitle} in "${queryParamCategory}"`
    : searchTitle;

  const cardContent1 = <TextSection title={searchTitleWithCategory} />;

  const formParams = {
    ...form,
    text: queryParamText,
    category: { id: "1", name: queryParamCategory }
  };

  const cardActions1 = (
    <Actions
      items={[
        {
          id: shortid.generate,
          action: <SearchForm {...formParams} />
        }
      ]}
      fullGridItems="true"
    />
  );

  const cardWithMedia1 = {
    image: null,
    content: cardContent1,
    actions: cardActions1,
    customStyles: useStyles(props)
  };

  return (
    <Layout header={{ currentPage: "Search" }}>
      <Grid container spacing={1} className={clsx(container, "Search")}>
        <Grid item xs={12}>
          <Card2 {...cardWithMedia1} />
        </Grid>
        <Grid item xs={12}>
          <SearchResults {...results} />
        </Grid>
      </Grid>
    </Layout>
  );
};

Search.propTypes = propTypes;
Search.defaultProps = defaultProps;

export default Search;
export { propTypes as SearchPropTypes, defaultProps as SearchDefaultProps };
