/**
 * Defines the data requirements for the component
 *
 * @see Accommodation.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import Lorem from "react-lorem-component";

/**
 * Imports prop types from other components
 */
import {
  ImageResponsivePropTypes,
  ImageResponsiveDefaultProps,
  ImageResponsivePlaceholderCom169
} from "../../ImageResponsive";

import { IconPropTypes, IconDefaultProps } from "../../Icon";
import { GpsPropTypes, GpsDefaultProps } from "../../Gps";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import SpaIcon from "@material-ui/icons/Spa";

/**
 * Imports default props
 */
import {
  dIdNumber,
  dDescription,
  dName,
  dSectionDescription,
  dSectionListing
} from "../../../App.data";

/**
 * Defines the prop types
 */
const propTypes = {
  /**
   * From API
   *
   * @see https://api.borsecturismregional.web-staging.eu/apidoc/
   * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/Stay/getStayAccommodationIdByID
   */
  id: PropTypes.number,
  name: PropTypes.string,
  slug: PropTypes.string,
  description: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.shape(ImageResponsivePropTypes)),
  destinationName: PropTypes.string,
  price: PropTypes.number,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  accommodationType: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }),
  roomTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ),
  extras: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ),
  sectionTitle: PropTypes.string,
  sectionDescription1: PropTypes.string,
  sectionListing: PropTypes.string,
  sectionDescription2: PropTypes.string,
  /**
   * Other props
   */
  gps: PropTypes.shape(GpsPropTypes),
  accommodationTypeIcon: PropTypes.shape(IconPropTypes),
  roomTypesIcon: PropTypes.shape(IconPropTypes),
  extrasIcon: PropTypes.shape(IconPropTypes)
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: dIdNumber(),
  name: "Accommodation name",
  slug: "accommodation-name",
  description: dDescription(), // ~250 chars
  images: Array(3)
    .fill(ImageResponsivePlaceholderCom169)
    .map(item => {
      return { ...item, id: shortid.generate() };
    }),
  destinationName: "Destination name",
  price: 25,
  latitude: 25.5566678642433,
  longitude: 25.5566678642433,
  accommodationType: {
    id: dIdNumber(),
    name: "Accommodation type 1 name"
  },
  roomTypes: [
    {
      id: dIdNumber(),
      name: "Room type 1 name"
    },
    {
      id: dIdNumber(),
      name: "Room type 2 name"
    }
  ],
  extras: Array(1).fill({
    id: dIdNumber(),
    name: "Extra 1 name"
  }),
  sectionTitle: dName(), // ~128 chars
  sectionDescription1: dSectionDescription(), // ~500 chars
  sectionListing: dSectionListing(),
  sectionDescription2: dSectionDescription(), // ~500 chars
  gps: GpsDefaultProps,
  accommodationTypeIcon: {
    id: shortid.generate(),
    icon: <HomeWorkIcon />
  },
  roomTypesIcon: {
    id: shortid.generate(),
    icon: <MeetingRoomIcon />
  },
  extrasIcon: {
    id: shortid.generate(),
    icon: <SpaIcon />
  }
};

export { propTypes, defaultProps };
