/**
 * Component short description
 *
 * @see News.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import shortid from "shortid";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Article from "../../_article/Article";
import ArticleAsThumb, {
  ArticleAsThumbSkeleton
} from "../../_article/ArticleAsThumb";
import { PageHeader } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./News.data";
import useNewsSwr from "./News.logic";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Skeleton from "@material-ui/lab/Skeleton";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./News.lang.ro-ro";
import { hu_hu } from "./News.lang.hu-hu";
import { en_us } from "./News.lang.en-us";
import { de_de } from "./News.lang.de-de";

i18n.addResourceBundle("ro-RO", "News", ro_ro);
i18n.addResourceBundle("hu-HU", "News", hu_hu);
i18n.addResourceBundle("en-US", "News", en_us);
i18n.addResourceBundle("de-DE", "News", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
  select: {
    width: "100%"
  }
}));

/**
 * Displays the component
 */
const News = props => {
  const { articles, destinations } = props;
  const { container, select } = useStyles(props);
  const { t } = useTranslation("News");

  /**
   * Prepares the path to an article
   */
  const match = useRouteMatch();
  const { path } = match;

  /**
   * Handles the Destinations select box
   */
  const [destination, setDestination] = useState();

  const handleChange = event => {
    setDestination(event.target.value);
  };

  /**
   * Loads the data from the API
   */
  const { data } = useNewsSwr({ destinationId: destination });

  /**
   * Displays a list of Destinations
   */
  const destinationsList = destinations.map(item => {
    const { id, name } = item;

    return (
      <MenuItem value={id} key={id}>
        {name}
      </MenuItem>
    );
  });

  /**
   * Displays the Destinations select box
   */
  const destinationsSelect = (
    <FormControl variant="outlined" className={clsx(select, "Select")}>
      <InputLabel id="news-destination-select-label">
        {t("Select destination")}
      </InputLabel>
      <Select
        label={t("Select destination")}
        labelId="news-destination-select-label"
        id="news-destination-select"
        value={destination}
        onChange={handleChange}
      >
        {destinationsList}
      </Select>
    </FormControl>
  );

  /**
   * Displays the articles
   */
  const articlesList = articles.map((item, index) => {
    const { id } = item;

    return (
      <Grid item key={id} xs={12} sm={6} md={4}>
        <ArticleAsThumb {...item} index={index} />
      </Grid>
    );
  });

  /**
   * Displays a placeholder skeleton while data is loading
   * // NOTE: When defined in `ArticleAsThumb` it throws an error
   */
  const skeletonsList = articles.map((item, index) => {
    const { id } = item;

    return (
      <Grid item key={id} xs={12} sm={6} md={4}>
        <>
          <Skeleton width="60%" />
          <Skeleton variant="rect" width={320} height={180} />
          <Skeleton />
        </>
      </Grid>
    );
  });

  /**
   * Displays either a list of articles or their skeletons
   */
  const articlesListOrSkeleton = data ? articlesList : skeletonsList;

  return (
    <Switch>
      <Route path={`${path}/:slug`}>
        <Article destination="Destination Name XXX" />
      </Route>
      <Route path={path}>
        <Layout header={{ currentPage: "News" }}>
          <Grid container spacing={1} className={clsx(container, "News")}>
            <Grid item xs={12}>
              <PageHeader title={t("News")} />
            </Grid>
            <Grid item xs={12} md={4}>
              {destinationsSelect}
            </Grid>
            <Grid container spacing={1} item xs={12}>
              {articlesListOrSkeleton}
            </Grid>
          </Grid>
        </Layout>
      </Route>
    </Switch>
  );
};

News.propTypes = propTypes;
News.defaultProps = defaultProps;

export default News;
export { propTypes as NewsPropTypes, defaultProps as NewsDefaultProps };
