/**
 * Reusable business logic
 * - For the web and the React Native apps
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import useSWR from "swr";

/**
 * Imports other components and hooks
 */
import useRequests from "../../../hooks/useRequests";

/**
 * Loads data via SWR
 *
 * - Returns an SWR answer: {data, error, isValidating, mutate}
 *
 * Usage: `const { data } = useNewsSwr();`
 *
 * @see https://github.com/vercel/swr#return-values
 */
const useNewsSwr = props => {
  const { destinationId } = props;

  const key = destinationId ? `news?destination=${destinationId}` : "news";
  // NOTE: This throws an infinte loop: const key = destinationId ? ["news", { destination: destinationId }] : "news";

  const { get } = useRequests();

  return useSWR(key, get);
};

export default useNewsSwr;
