/**
 * Component short description
 *
 * @see CategoriesAsCarousel.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState, forwardRef } from "react";
import clsx from "clsx";
import shortid from "shortid";
import { Link as RouterLink } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import TabPanel from "../../TabPanel";
import Card2, { Card2LocalMarketAsHeroStylesInverted } from "../../Card2";
import Carousel from "../../Carousel";
import AttractionAsThumb from "../../_attraction/AttractionAsThumb";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./CategoriesAsCarousel.data";

/**
 * Imports Material UI components
 */
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./CategoriesAsCarousel.lang.ro-ro";
import { hu_hu } from "./CategoriesAsCarousel.lang.hu-hu";
import { en_us } from "./CategoriesAsCarousel.lang.en-us";
import { de_de } from "./CategoriesAsCarousel.lang.de-de";

i18n.addResourceBundle("ro-RO", "CategoriesAsCarousel", ro_ro);
i18n.addResourceBundle("hu-HU", "CategoriesAsCarousel", hu_hu);
i18n.addResourceBundle("en-US", "CategoriesAsCarousel", en_us);
i18n.addResourceBundle("de-DE", "CategoriesAsCarousel", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: { ...theme.custom.grid.removeNegativeMargin },

  tabs: {
    marginBottom: theme.spacing(2)
  },

  imageContainer: {
    order: 2,

    [theme.breakpoints.up("xs")]: {
      order: 1
    }
  }
}));

/**
 * Displays the component
 */
const CategoriesAsCarousel = props => {
  const { categories, attractions } = props;
  const { container, tabs: tabsClass, panels: panelsClass } = useStyles(props);
  const { t } = useTranslation("CategoriesAsCarousel");

  /**
   * Sets up tabs orientation
   */
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isBelowTabletLandscape = useMediaQuery(theme.breakpoints.down("sm"));
  const orientation = isMobile ? "horizontal" : "vertical";

  /**
   * Manages the state of the active tab
   */
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  /**
   * Manages to click on tab labels
   */
  const handleTabClick = (event, newValue) => {
    setActiveTabIndex(newValue);
  };

  /**
   * Displays the tab labels
   */
  const labels = categories.map(category => {
    const { name, id } = category;
    return <Tab label={t(name)} key={id} />;
  });

  /**
   * Prepares the images for the carousel
   */
  const carouselSteps = isMobile
    ? attractions.map((item, index) => {
        return {
          id: shortid.generate(),
          content: <AttractionAsThumb {...item} index={index} />
        };
      })
    : attractions
        .map((item, index) => {
          return index % 2 === 0
            ? {
                id: shortid.generate(),
                content: (
                  <Grid
                    container
                    className={clsx(container)}
                    wrap="nowrap"
                    justify="space-around"
                    spacing={1}
                  >
                    <Grid item xs={6} key={shortid.generate()}>
                      <AttractionAsThumb {...item} index={index} />
                    </Grid>
                    {attractions[index + 1] && (
                      <Grid item xs={6} key={shortid.generate()}>
                        <AttractionAsThumb
                          {...attractions[index + 1]}
                          index={index + 3}
                        />
                      </Grid>
                    )}
                  </Grid>
                )
              }
            : null;
        })
        .filter(item => item);

  /**
   * Prepares the panel content
   */
  const cardWithMedia = {
    image: null,
    carousel: (
      <Carousel steps={carouselSteps} isMobileStepperAlignedRight={true} />
    ),
    customStyles: Card2LocalMarketAsHeroStylesInverted({
      ...props,
      breakpoint: "sm"
    }),
    cardProps: { style: { padding: 0 } },
    cardContainerProps: { alignItems: "center" }
  };

  /**
   * Displays the tab panels
   */
  const panels = categories.map((category, index) => {
    const { id } = category;

    return (
      <TabPanel
        currentTabIndex={index}
        activeTabIndex={activeTabIndex}
        key={id}
      >
        <Card2 {...cardWithMedia} />
      </TabPanel>
    );
  });

  return (
    <Grid
      container
      spacing={1}
      className={clsx(container, "CategoriesAsCarousel")}
    >
      <Grid item xs={12} key={shortid.generate()}>
        <Tabs
          value={activeTabIndex}
          onChange={handleTabClick}
          variant="scrollable"
          scrollButtons="auto"
          className={clsx(tabsClass, "Tabs")}
        >
          {labels}
        </Tabs>
      </Grid>
      <Grid item xs={12} key={shortid.generate()}>
        <div className={clsx(panelsClass, "Panels")}>{panels}</div>
      </Grid>
    </Grid>
  );
};

CategoriesAsCarousel.propTypes = propTypes;
CategoriesAsCarousel.defaultProps = defaultProps;

export default CategoriesAsCarousel;
export {
  propTypes as CategoriesAsCarouselPropTypes,
  defaultProps as CategoriesAsCarouselDefaultProps
};
