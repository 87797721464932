/**
 * Component short description
 *
 * @see Footer.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Logo from "../Logo";
import MenuItems from "../MenuItems";
import Icons from "../Icons";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Footer.data";

/**
 * Imports Material UI components
 */
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Footer.lang.ro-ro";
import { hu_hu } from "./Footer.lang.hu-hu";
import { en_us } from "./Footer.lang.en-us";
import { de_de } from "./Footer.lang.de-de";

i18n.addResourceBundle("ro-RO", "Footer", ro_ro);
i18n.addResourceBundle("hu-HU", "Footer", hu_hu);
i18n.addResourceBundle("en-US", "Footer", en_us);
i18n.addResourceBundle("de-DE", "Footer", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: { ...theme.custom.grid.removeNegativeMargin },

  logoContainer: props => ({
    marginRight: theme.spacing(1)
  }),

  copyright: {
    padding: theme.spacing(1)
  },

  iconsContainer: {
    ["& .Icons"]: {
      ["& .MuiGrid-item"]: {
        ["&:first-of-type"]: {
          paddingLeft: "0 !important"
        }
      }
    }
  }
}));

/**
 * Displays the component
 */
const Footer = props => {
  const { logo, menu, icons, currentPage } = props;
  const { container, logoContainer, iconsContainer, copyright } = useStyles(
    props
  );
  const { t } = useTranslation("Footer");

  const theme = useTheme();
  const isPortrait = useMediaQuery(theme.breakpoints.down("md"));
  const orientation = isPortrait ? "vertical" : "horizontal";
  const wrap = isPortrait ? "wrap" : "nowrap";

  const copy = (
    <Grid item className={clsx(copyright, "Copyright")}>
      <Typography variant="caption">
        &copy; 2020 Pineyard. All rights reserved.
      </Typography>
    </Grid>
  );

  const id = (
    <>
      <Grid
        container
        className={clsx(container)}
        spacing={0}
        alignItems="center"
        wrap="wrap"
      >
        <Grid item className={clsx(logoContainer)}>
          <Logo {...logo} />
        </Grid>
      </Grid>
      <Hidden xsDown>{copy}</Hidden>
    </>
  );

  const lists = (
    <Grid
      item
      container
      className={clsx(container)}
      spacing={0}
      alignItems="center"
    >
      <Grid item xs={12} xl={8}>
        <MenuItems
          {...menu}
          activeItem={currentPage}
          orientation={orientation}
        />
      </Grid>
      <Grid
        item
        xs={12}
        xl={4}
        className={clsx(iconsContainer, "IconsContainer")}
      >
        <Icons {...icons} orientation="horizontal" />
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      spacing={1}
      className={clsx(container, "Footer")}
      alignItems="center"
    >
      <Grid
        item
        container
        className={clsx(container)}
        direction="column"
        xs={12}
        sm={6}
        lg={2}
      >
        {id}
      </Grid>
      <Grid item container className={clsx(container)} xs={12} sm={6} lg={10}>
        {lists}
      </Grid>
      <Hidden smUp>{copy}</Hidden>
    </Grid>
  );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
export { propTypes as FooterPropTypes, defaultProps as FooterDefaultProps };
