/**
 * Displays a Stay
 *
 * @see Stay.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { forwardRef, useState, useEffect } from "react";
import clsx from "clsx";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Card2, {
  Card2SingleRowStyles,
  Card2SingleRowStylesInverted
} from "../../Card2";
import Accommodation from "../../_accommodation/Accommodation";
import AccommodationAsThumb from "../../_accommodation/AccommodationAsThumb";
import StaySearch from "../StaySearch/StaySearch";
import { FiltersProvider } from "../../../hooks";
import { useUnsplash } from "../../../hooks";
import { TextSection, PageHeader } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Stay.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Stay.lang.ro-ro";
import { hu_hu } from "./Stay.lang.hu-hu";
import { en_us } from "./Stay.lang.en-us";
import { de_de } from "./Stay.lang.de-de";

i18n.addResourceBundle("ro-RO", "Stay", ro_ro);
i18n.addResourceBundle("hu-HU", "Stay", hu_hu);
i18n.addResourceBundle("en-US", "Stay", en_us);
i18n.addResourceBundle("de-DE", "Stay", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: { ...theme.custom.grid.removeNegativeMargin }
}));

/**
 * Displays the component
 */
const Stay = props => {
  const { image, description, staySearch } = props;
  const { defaultFilters, defaultData } = staySearch;
  const { container } = useStyles(props);
  const { t } = useTranslation("Stay");

  /**
   * Loads unsplash images
   */
  const unsplashImages = useUnsplash({
    keyword: "Rooms",
    page: 10,
    photos: 1,
    params: { orientation: "landscape" }
  });

  /**
   * Defines the routes for accommodations
   */
  const match = useRouteMatch();
  const { path } = match;

  /**
   * Displays the hero card
   */
  const cardContent1 = <TextSection description={description} />;

  const cardWithMedia1 = {
    image: unsplashImages ? unsplashImages[0] : image,
    content: cardContent1,
    customStyles: Card2SingleRowStyles({ ...props, breakpoint: "md" })
  };

  return (
    <Switch>
      <Route path={`${path}/:slug`}>
        <Accommodation destinationName="Destination Name XXX" />
      </Route>
      <Route path={path}>
        <Layout header={{ currentPage: "Stay" }}>
          <Grid container spacing={1} className={clsx(container, "Stay")}>
            <Grid item xs={12}>
              <PageHeader title={t("Stay")} />
            </Grid>
            <Grid item xs={12}>
              <Card2 {...cardWithMedia1} />
            </Grid>
            <Grid item xs={12}>
              <AccommodationAsThumb />
              <FiltersProvider
                defaultFilters={defaultFilters}
                defaultData={defaultData}
              >
                <StaySearch />
              </FiltersProvider>
            </Grid>
          </Grid>
        </Layout>
      </Route>
    </Switch>
  );
};

Stay.propTypes = propTypes;
Stay.defaultProps = defaultProps;

export default Stay;
export { propTypes as StayPropTypes, defaultProps as StayDefaultProps };
