/**
 * Component short description
 *
 * @see Settings.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Settings.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Settings.lang.ro-ro";
import { hu_hu } from "./Settings.lang.hu-hu";
import { en_us } from "./Settings.lang.en-us";
import { de_de } from "./Settings.lang.de-de";

i18n.addResourceBundle("ro-RO", "Settings", ro_ro);
i18n.addResourceBundle("hu-HU", "Settings", hu_hu);
i18n.addResourceBundle("en-US", "Settings", en_us);
i18n.addResourceBundle("de-DE", "Settings", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  }
}));

/**
 * Displays the component
 */
const Settings = props => {
  const { container } = useStyles(props);
  const { t } = useTranslation("Settings");

  return (
    <Grid container className={clsx(container, "Settings")}>
      <Grid item xs={12}>
        <Typography variant="h6">{t("Settings")}</Typography>
        <Typography>Forms are done usually by the integration team</Typography>
      </Grid>
    </Grid>
  );
};

Settings.propTypes = propTypes;
Settings.defaultProps = defaultProps;

export default Settings;
export { propTypes as SettingsPropTypes, defaultProps as SettingsDefaultProps };
