/**
 * Component short description
 *
 * @see Auth.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState } from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import LoginDialog from "../LoginDialog/LoginDialog";
import RegisterDialog from "../RegisterDialog/RegisterDialog";
import ForgotPassDialog from "../ForgotPassDialog/ForgotPassDialog";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Auth.data";

/**
 * Imports Material UI components
 */
import {
  makeStyles,
  Button,
  useMediaQuery,
  useTheme,
  Dialog
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Auth.lang.ro-ro";
import { hu_hu } from "./Auth.lang.hu-hu";
import { en_us } from "./Auth.lang.en-us";
import { de_de } from "./Auth.lang.de-de";

i18n.addResourceBundle("ro-RO", "Auth", ro_ro);
i18n.addResourceBundle("hu-HU", "Auth", hu_hu);
i18n.addResourceBundle("en-US", "Auth", en_us);
i18n.addResourceBundle("de-DE", "Auth", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: { ...theme.custom.grid.removeNegativeMargin }
}));

/**
 * Displays the component
 */
const Auth = props => {
  const { t } = useTranslation("Auth");

  const { container } = useStyles(props);

  const [open, setOpen] = useState(false);
  const [currentDialog, setCurrentDialog] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = dialog => {
    setCurrentDialog(dialog);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dialog = () => {
    switch (currentDialog) {
      case "register":
        return (
          <RegisterDialog
            dialog={{
              open: true,
              onClose: handleClose
            }}
            open={register}
            onClose={handleClose}
            openDialog={handleOpen}
          />
        );
      case "forgotPass":
        return (
          <ForgotPassDialog
            dialog={{
              open: true,
              onClose: handleClose
            }}
            open={forgotPass}
            openDialog={handleOpen}
          />
        );
      case "login":
      default:
        return (
          <LoginDialog
            dialog={{
              open: true,
              onClose: handleClose
            }}
            open={login}
            openDialog={handleOpen}
          />
        );
    }
  };

  const { login, register, forgotPass } = open;

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<AccountCircleIcon />}
        onClick={handleOpen}
      >
        {t("login")}
      </Button>
      <Dialog fullScreen={isMobile} open={open} onClose={handleClose}>
        {dialog()}
      </Dialog>
    </>
  );
};

Auth.propTypes = propTypes;
Auth.defaultProps = defaultProps;

export default Auth;
export { propTypes as AuthPropTypes, defaultProps as AuthDefaultProps };
