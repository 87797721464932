/**
 * Defines the data requirements for the component
 *
 * @see AccountMenu.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";

/**
 * Imports other components and hooks
 */
import { AccountPropTypes, AccountDefaultProps } from "../Account";
import { IconPropTypes } from "../../Icon";
import { IconsPropTypes } from "../../Icons";
import Logout from "../Logout";

import AccountBoxIcon from "@material-ui/icons/AccountBox";
import SettingsIcon from "@material-ui/icons/Settings";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

/**
 * Defines the prop types
 */
const propTypes = {
  id: PropTypes.string,
  icon: PropTypes.shape(IconPropTypes),
  menuIcons: PropTypes.shape(IconsPropTypes),
  account: PropTypes.shape(AccountPropTypes)
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: shortid.generate(),
  icon: <AccountBoxIcon />,
  menuIcons: {
    items: [
      {
        id: shortid.generate(),
        divider: { variant: "fullWidth" }
      },
      {
        id: shortid.generate(),
        icon: <SettingsIcon />,
        name: "Settings",
        path: "/account?activeTab=0",
        doTranslateName: true
      },
      {
        id: shortid.generate(),
        icon: <HomeWorkIcon />,
        name: "Booking History",
        path: "/account?activeTab=1",
        doTranslateName: true
      },
      {
        id: shortid.generate(),
        icon: <ShoppingCartIcon />,
        name: "Order History",
        path: "/account?activeTab=2",
        doTranslateName: true
      },
      {
        id: shortid.generate(),
        divider: { variant: "fullWidth" }
      },
      {
        id: shortid.generate(),
        icon: <ExitToAppIcon />,
        //// TODO: Add a callback here, or a path
        name: "Sign out",
        doTranslateName: true
      }
    ],
    orientation: "vertical"
  },
  account: AccountDefaultProps
};

export { propTypes, defaultProps };
