/**
 * Defines the data requirements for the component
 *
 * @see Cart.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import Lorem from "react-lorem-component";

/**
 * Imports prop types from other components
 */
import {
  OrdersForCartPropTypes,
  OrdersForCartDefaultProps
} from "../../_account/OrdersForCart";

/**
 * Defines the prop types
 *
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/Market/getMarketCartInfo
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/Market/getCartMarket
 *
 * // NOTE: The API is not in Sync with Figma (destinationName is missing). Going with Figma
 */
const propTypes = {
  description: PropTypes.string,
  orders: PropTypes.shape(OrdersForCartPropTypes)
};

/**
 * Defines the default props
 */
const defaultProps = {
  description: <Lorem seed="2" count="1" sentenceUpperBound="5" />,
  orders: OrdersForCartDefaultProps
};

export { propTypes, defaultProps };
