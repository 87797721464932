/**
 * Component short description
 *
 * @see Terms.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Card2, {
  Card2SingleRowStyles,
  Card2SingleRowStylesInverted
} from "../../Card2";
import { TextSection, Template, PageHeader } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Terms.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Terms.lang.ro-ro";
import { hu_hu } from "./Terms.lang.hu-hu";
import { en_us } from "./Terms.lang.en-us";
import { de_de } from "./Terms.lang.de-de";

i18n.addResourceBundle("ro-RO", "Terms", ro_ro);
i18n.addResourceBundle("hu-HU", "Terms", hu_hu);
i18n.addResourceBundle("en-US", "Terms", en_us);
i18n.addResourceBundle("de-DE", "Terms", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  }
}));

/**
 * Displays the component
 */
const Terms = props => {
  const {
    description,
    sectionTitle1,
    sectionDescription1,
    sectionListing1,
    sectionTitle2,
    sectionDescription2,
    sectionListing2,
    sectionDescription1a,
    sectionDescription2a
  } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("Terms");

  /**
   * Displays the description
   */
  const cardContent1 = <TextSection description={description} />;

  const cardWithMedia1 = {
    image: null,
    content: cardContent1
  };

  /**
   * Displays the template
   */
  const cardContent2 = (
    <TextSection subtitle={sectionTitle1} listing={sectionListing1} />
  );

  const cardWithMedia2 = {
    image: null,
    content: cardContent2
  };

  const cardContent4 = (
    <TextSection
      description={sectionDescription1}
      listing={sectionDescription1a}
    />
  );

  const cardWithMedia4 = {
    image: null,
    content: cardContent4
  };

  const cardContent3 = (
    <TextSection
      subtitle={sectionTitle2}
      description={sectionDescription2}
      listing={sectionListing2}
    />
  );

  const cardWithMedia3 = {
    image: null,
    content: cardContent3
  };

  const cardContent5 = <TextSection description={sectionDescription2a} />;

  const cardWithMedia5 = {
    image: null,
    content: cardContent3
  };

  return (
    <Layout footer={{ currentPage: "Terms & Conditions" }}>
      <Grid container spacing={1} className={clsx(container, "Terms")}>
        <Grid item xs={12}>
          <PageHeader title={t("Terms & Conditions")} />
        </Grid>
        <Grid container spacing={0} className={clsx(container)}>
          <Grid item xs={12}>
            <Card2 {...cardWithMedia1} />
          </Grid>
          <Grid item xs={12}>
            <Template
              items={[
                <Card2 {...cardWithMedia2} />,
                <Card2 {...cardWithMedia4} />,
                <Card2 {...cardWithMedia3} />,
                <Card2 {...cardWithMedia5} />
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

Terms.propTypes = propTypes;
Terms.defaultProps = defaultProps;

export default Terms;
export { propTypes as TermsPropTypes, defaultProps as TermsDefaultProps };
