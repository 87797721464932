/**
 * Component short description
 *
 * @see Question.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Card2, {
  Card2SingleRowStyles,
  Card2SingleRowStylesInverted
} from "../../Card2";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Question.data";

/**
 * Imports Material UI components
 */
import {
  makeStyles,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { ro_ro } from "./Question.lang.ro-ro";
import { hu_hu } from "./Question.lang.hu-hu";
import { en_us } from "./Question.lang.en-us";
import { de_de } from "./Question.lang.de-de";

i18n.addResourceBundle("ro-RO", "Question", ro_ro);
i18n.addResourceBundle("hu-HU", "Question", hu_hu);
i18n.addResourceBundle("en-US", "Question", en_us);
i18n.addResourceBundle("de-DE", "Question", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  panel: {
    width: "100%",
    maxWidth: theme.custom.typography.maxTextWidth
  }
}));

/**
 * Displays the component
 */
const Question = props => {
  const { question, answer } = props;
  const { panel } = useStyles(props);

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={panel}>{question}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography className={panel}>{answer}</Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

Question.propTypes = propTypes;
Question.defaultProps = defaultProps;

export default Question;
export { propTypes as QuestionPropTypes, defaultProps as QuestionDefaultProps };
