const ro_ro = {
  Icon: "Icon (ro_ro)",
  Events: "Evenimente",
  Search: "Cautare",
  Cart: "Cos cumparaturi",
  Settings: "Setari",
  "Booking History": "Istoric acomodari",
  "Order History": "Istoric comenzi",
  "Sign out": "Iesire cont"
};

export { ro_ro };
