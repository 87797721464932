/**
 * Component short description
 *
 * @see Checkout.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Card2 from "../../Card2";
import { TextSection, Template, Actions, PageHeader } from "../../Theme";
import OrderForCheckout from "../../_account/OrderForCheckout";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Checkout.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Checkout.lang.ro-ro";
import { hu_hu } from "./Checkout.lang.hu-hu";
import { en_us } from "./Checkout.lang.en-us";
import { de_de } from "./Checkout.lang.de-de";

i18n.addResourceBundle("ro-RO", "Checkout", ro_ro);
i18n.addResourceBundle("hu-HU", "Checkout", hu_hu);
i18n.addResourceBundle("en-US", "Checkout", en_us);
i18n.addResourceBundle("de-DE", "Checkout", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  }
}));

/**
 * Displays the component
 */
const Checkout = props => {
  const { description, order } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("Checkout");

  /**
   * Displays the Hero card
   */
  const cardContent1 = <TextSection description={description} />;

  const cardWithMedia1 = {
    image: null,
    content: cardContent1
  };

  /**
   * Displays the personal form
   */
  const cardContent2 = (
    <TextSection
      subtitle={t("Personal information")}
      description="Forms are done by the integration team"
    />
  );

  const cardWithMedia2 = {
    image: null,
    content: cardContent2
  };

  /**
   * Displays the delivery form
   */
  const cardContent3 = (
    <TextSection
      subtitle={t("Delivery information")}
      description="Forms are done by the integration team"
    />
  );

  const cardWithMedia3 = {
    image: null,
    content: cardContent3
  };

  return (
    <Layout header={{ currentPage: "Cart" }}>
      <Grid container spacing={1} className={clsx(container, "Checkout")}>
        <Grid item xs={12}>
          <PageHeader title={t("Checkout")} />
        </Grid>
        <Grid item xs={12}>
          <Card2 {...cardWithMedia1} />
        </Grid>
        <Grid item xs={12}>
          <Card2 {...cardWithMedia2} />
        </Grid>
        <Grid item xs={12}>
          <Card2 {...cardWithMedia3} />
        </Grid>
        <Grid item xs={12}>
          <OrderForCheckout {...order} />
        </Grid>
      </Grid>
    </Layout>
  );
};

Checkout.propTypes = propTypes;
Checkout.defaultProps = defaultProps;

export default Checkout;
export { propTypes as CheckoutPropTypes, defaultProps as CheckoutDefaultProps };
