/**
 * Defines the data requirements for the component
 *
 * @see Attraction.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import Lorem from "react-lorem-component";

/**
 * Imports prop types from other components
 */
import {
  ImageResponsivePropTypes,
  ImageResponsiveDefaultProps,
  ImageResponsivePlaceholderCom169
} from "../../ImageResponsive";
import { IconPropTypes, IconDefaultProps } from "../../Icon";
import { GpsPropTypes, GpsDefaultProps } from "../../Gps";

import RoomIcon from "@material-ui/icons/Room";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import FacebookIcon from "@material-ui/icons/Facebook";
import LanguageIcon from "@material-ui/icons/Language";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import MapIcon from "@material-ui/icons/Map";

/**
 * Defines the prop types
 *
 * @see https://schema.org/TouristAttraction
 */
const propTypes = {
  /**
   * Props from the API
   */
  name: PropTypes.string,
  slug: PropTypes.string,
  description: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.shape(ImageResponsivePropTypes)),
  audio: PropTypes.string,
  track: PropTypes.string,
  openingHours: PropTypes.string,
  prices: PropTypes.string,
  facebook: PropTypes.string,
  url: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionDescription1: PropTypes.string,
  sectionListing: PropTypes.string,
  image1: PropTypes.shape(ImageResponsivePropTypes),
  image1Description: PropTypes.string,
  sectionDescription2: PropTypes.string,
  image2: PropTypes.shape(ImageResponsivePropTypes),
  image2Description: PropTypes.string,
  /**
   * Props from the parent
   */
  destinationName: PropTypes.string,
  /**
   * Other props
   */
  id: PropTypes.string,
  gps: PropTypes.shape(GpsPropTypes),
  openingHoursIcon: PropTypes.shape(IconPropTypes),
  pricesIcon: PropTypes.shape(IconPropTypes),
  facebookIcon: PropTypes.shape(IconPropTypes),
  webIcon: PropTypes.shape(IconPropTypes),
  routeIcon: PropTypes.shape(IconPropTypes)
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: shortid.generate(),
  name: "Attraction1 name",
  slug: "attraction1-name",
  description: <Lorem seed="2" count="1" sentenceUpperBound="5" />,
  images: Array(3)
    .fill(ImageResponsivePlaceholderCom169)
    .map(item => {
      return { ...item, id: shortid.generate() };
    }),
  audio: "http://example.com/path/to/audio_file.mp3",
  track: "http://example.com/path/to/track_file.gpx",
  openingHours: "Monday to Friday 8:00 - 12:00",
  prices: "Price 56 RON / Person",
  facebook: "https://facebook.com/attraction1",
  url: "https://www.attraction1.com",
  sectionTitle: "Attraction1 section title",
  sectionDescription1: <Lorem seed="3" count="1" sentenceUpperBound="5" />,
  sectionListing: (
    <Lorem seed="4" count="3" mode="list" sentenceUpperBound="3" />
  ),
  image1: {
    ...ImageResponsivePlaceholderCom169,
    /**
     * For some reson the image on mobile is not displayed.
     * - This is a fix
     */
    boxStyle: {
      width: "100%",
      minWidth: 320,
      ["@media (min-width: 767px)"]: {
        minWidth: "auto"
      }
    }
  },
  image1Description: <Lorem seed="4" count="1" />,
  sectionDescription2: <Lorem seed="5" count="1" />,
  image2: {
    ...ImageResponsivePlaceholderCom169,
    /**
     * For some reson the image on mobile is not displayed.
     * - This is a fix
     */
    boxStyle: {
      width: "100%",
      minWidth: 320,
      ["@media (min-width: 767px)"]: {
        minWidth: "auto"
      }
    }
  },
  image2Description: <Lorem seed="6" count="1" />,
  destinationName: "Destination name",
  gps: GpsDefaultProps,
  audioIcon: {
    icon: <AudiotrackIcon />
  },
  openingHoursIcon: {
    icon: <WatchLaterIcon />
  },
  pricesIcon: {
    icon: <LocalOfferIcon />
  },
  facebookIcon: {
    icon: <FacebookIcon />
  },
  webIcon: {
    icon: <LanguageIcon />
  },
  routeIcon: {
    icon: <MapIcon />
  }
};

export { propTypes, defaultProps };
