/**
 * Component short description
 *
 * @see StayAsHero.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { forwardRef } from "react";
import clsx from "clsx";
import shortid from "shortid";
import { Link as RouterLink } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import Card2, {
  Card2SingleRowStyles,
  Card2SingleRowStylesInverted
} from "../../Card2";
import Carousel from "../../Carousel";
import AccommodationAsThumb from "../../_accommodation/AccommodationAsThumb";
import { TextSection } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./StayAsHero.data";

/**
 * Imports Material UI components
 */
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./StayAsHero.lang.ro-ro";
import { hu_hu } from "./StayAsHero.lang.hu-hu";
import { en_us } from "./StayAsHero.lang.en-us";
import { de_de } from "./StayAsHero.lang.de-de";

i18n.addResourceBundle("ro-RO", "StayAsHero", ro_ro);
i18n.addResourceBundle("hu-HU", "StayAsHero", hu_hu);
i18n.addResourceBundle("en-US", "StayAsHero", en_us);
i18n.addResourceBundle("de-DE", "StayAsHero", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  }
}));

/**
 * Displays the component
 */
const StayAsHero = props => {
  const { stay, stays } = props;
  const { description: stayDescription } = stay;

  const { container } = useStyles(props);
  const { t } = useTranslation("StayAsHero");

  /**
   * Defines links to routes
   */
  const LinkToStay = forwardRef((props, ref) => (
    <RouterLink ref={ref} to="/stay/" {...props} />
  ));

  /**
   * Prepares the images for the carousel
   */
  const carouselSteps = stays.map((item, index) => {
    return {
      id: shortid.generate(),
      content: <AccommodationAsThumb {...item} index={index} />
    };
  });

  const cardContent = (
    <TextSection title={t("Stay")} description={stayDescription} />
  );

  const cardActions = (
    <>
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={LinkToStay}
      >
        {t("View more")}
      </Button>
    </>
  );

  const cardWithMedia = {
    image: null,
    images: null,
    carousel: <Carousel steps={carouselSteps} />,
    content: cardContent,
    actions: cardActions,
    customStyles: Card2SingleRowStyles({
      ...props,
      breakpoint: "md",
      imageWidth: 640
    })
  };

  return <Card2 {...cardWithMedia} />;
};

StayAsHero.propTypes = propTypes;
StayAsHero.defaultProps = defaultProps;

export default StayAsHero;
export {
  propTypes as StayAsHeroPropTypes,
  defaultProps as StayAsHeroDefaultProps
};
