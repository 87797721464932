/**
 * Defines the data requirements for the component
 *
 * @see Layout.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { HeaderPropTypes, HeaderDefaultProps } from "../Header";
import { FooterPropTypes, FooterDefaultProps } from "../Footer";

/**
 * Defines the prop types
 */
const propTypes = {
  header: PropTypes.shape(HeaderPropTypes),
  footer: PropTypes.shape(FooterPropTypes),
  children: PropTypes.any
};

/**
 * Defines the default props
 */
const defaultProps = {
  header: HeaderDefaultProps,
  footer: FooterDefaultProps,
  children: null
};

export { propTypes, defaultProps };
