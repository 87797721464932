/**
 * Defines the data requirements for the component
 *
 * @see Settings.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {};

/**
 * Defines the default props
 */
const defaultProps = {};

export { propTypes, defaultProps };
