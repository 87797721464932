/**
 * Component short description
 *
 * @see StayFilters.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import FilterAccomodationType from "../FilterAccomodationType";
import FilterRoomType from "../FilterRoomType";
import FilterExtras from "../FilterExtras";
import FilterDestination from "../../FilterDestination";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./StayFilters.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./StayFilters.lang.ro-ro";
import { hu_hu } from "./StayFilters.lang.hu-hu";
import { en_us } from "./StayFilters.lang.en-us";
import { de_de } from "./StayFilters.lang.de-de";

i18n.addResourceBundle("ro-RO", "StayFilters", ro_ro);
i18n.addResourceBundle("hu-HU", "StayFilters", hu_hu);
i18n.addResourceBundle("en-US", "StayFilters", en_us);
i18n.addResourceBundle("de-DE", "StayFilters", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    border: "1px solid",
  },
}));

/**
 * Displays the component
 */
const StayFilters = (props) => {
  const {
    filterDestination,
    filterAccomodationType,
    filterRoomType,
    filterExtras,
  } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("StayFilters");

  return (
    <List>
      <FilterDestination {...filterDestination} />
      <FilterAccomodationType {...filterAccomodationType} />
      <FilterRoomType {...filterRoomType} />
      <FilterExtras {...filterExtras} />
    </List>
  );
};

StayFilters.propTypes = propTypes;
StayFilters.defaultProps = defaultProps;

export default StayFilters;
export {
  propTypes as StayFiltersPropTypes,
  defaultProps as StayFiltersDefaultProps,
};
