/**
 * Imports React and third party packages
 */
import React, { useRef } from "react";
import { SnackbarProvider } from "notistack";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

/**
 * Imports other components and hooks
 */
import { AuthProvider } from "./hooks";
import Routes, { RoutesDefaultProps } from "./components/Routes";
import theme from "./theme";

/**
 * Displays the app
 */
const App = () => {
  const notistackRef = useRef();

  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <SnackbarProvider
          ref={notistackRef}
          maxSnack={3}
          action={key => (
            <IconButton onClick={onClickDismiss(key)}>
              <CloseIcon />
            </IconButton>
          )}
        >
          <AuthProvider strategy="oauth">
            <Routes {...RoutesDefaultProps} />
          </AuthProvider>
        </SnackbarProvider>
      </CssBaseline>
    </ThemeProvider>
  );
};

export default App;
