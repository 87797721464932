/**
 * Defines the data requirements for the component
 *
 * @see Logo.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import SvgIconPy from "./SvgIconPy";

/**
 * Defines the prop types
 */
const propTypes = {
  svg: PropTypes.any,
  title: PropTypes.string,
  url: PropTypes.string
};

/**
 * Defines the default props
 */
const defaultProps = {
  svg: SvgIconPy,
  title: "Pineyard",
  url: "/"
};

export { propTypes, defaultProps };
