/**
 * Component short description
 *
 * @see Order.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import shortid from "shortid";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Breadcrumbs from "../../Breadcrumbs";
import Card2 from "../../Card2";
import List2 from "../../List2";
import { TextSection, Template, Actions, PageHeader } from "../../Theme";
import OrderItem from "../OrderItem";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Order.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Order.lang.ro-ro";
import { hu_hu } from "./Order.lang.hu-hu";
import { en_us } from "./Order.lang.en-us";
import { de_de } from "./Order.lang.de-de";

i18n.addResourceBundle("ro-RO", "Order", ro_ro);
i18n.addResourceBundle("hu-HU", "Order", hu_hu);
i18n.addResourceBundle("en-US", "Order", en_us);
i18n.addResourceBundle("de-DE", "Order", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  }
}));

/**
 * Displays the component
 */
const Order = props => {
  const {
    id,
    seller,
    orderDate,
    orderItems,
    totalPrice,
    status,
    statusDescription,
    personalName,
    personalAddress,
    personalPhone,
    deliveryName,
    deliveryAddress,
    deliveryPhone,
    items
  } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("Order");

  /**
   * Displays the order header
   */
  const title = `${t("Order")}: ${id}`;

  const details1 = (
    <List2
      items={[
        { id: shortid.generate(), listItem: `${t("Seller")}: ${seller}` },
        {
          id: shortid.generate(),
          listItem: `${t("Order date")}: ${orderDate}`
        },
        {
          id: shortid.generate(),
          listItem: `${t("Total price")}: ${totalPrice} RON`
        }
      ]}
    />
  );

  const details2 = (
    <List2
      items={[
        { id: shortid.generate(), listItem: `${status}` },
        { id: shortid.generate(), listItem: `${statusDescription}` }
      ]}
    />
  );

  const cancelButton = <Button variant="outlined">{t("Cancel order")}</Button>;

  const cardContent1 = <TextSection subtitle={title} description={details1} />;

  const cardWithMedia1 = {
    image: null,
    content: cardContent1
  };

  const cardContent2 = (
    <TextSection subtitle={t("Order status")} description={details2} />
  );

  const cardActions2 = (
    <Actions items={[{ id: shortid.generate(), action: cancelButton }]} />
  );

  const cardWithMedia2 = {
    image: null,
    content: cardContent2,
    actions: cardActions2
  };

  /**
   * Displays personal information
   */
  const personalInfo = (
    <List2
      items={[
        { id: shortid.generate(), listItem: `${t("Name")}: ${personalName}` },
        {
          id: shortid.generate(),
          listItem: `${t("Address")}: ${personalAddress}`
        },
        { id: shortid.generate(), listItem: `${t("Phone")}: ${personalPhone}` }
      ]}
    />
  );

  const cardContent3 = (
    <TextSection
      subtitle={t("Personal information")}
      description={personalInfo}
    />
  );

  const cardWithMedia3 = {
    image: null,
    content: cardContent3
  };

  /**
   * Displays delivery information
   */
  const deliveryInfo = (
    <List2
      items={[
        { id: shortid.generate(), listItem: `${t("Name")}: ${deliveryName}` },
        {
          id: shortid.generate(),
          listItem: `${t("Address")}: ${deliveryAddress}`
        },
        { id: shortid.generate(), listItem: `${t("Phone")}: ${deliveryPhone}` }
      ]}
    />
  );

  const cardContent4 = (
    <TextSection
      subtitle={t("Delivery information")}
      description={deliveryInfo}
    />
  );

  const cardWithMedia4 = {
    image: null,
    content: cardContent4
  };

  /**
   * Displays the items
   */
  const itemsList =
    items &&
    items.map((item, index) => {
      const { id } = item;

      return (
        <Grid item key={id} xs={12}>
          <OrderItem {...item} index={index} />
        </Grid>
      );
    });

  return (
    <Layout header={{ currentPage: "Account" }}>
      <Grid container spacing={1} className={clsx(container, "Order")}>
        <Grid item xs={12}>
          <PageHeader breadcrumbs=<Breadcrumbs /> title={t("Order")} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card2 {...cardWithMedia1} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card2 {...cardWithMedia2} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card2 {...cardWithMedia3} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card2 {...cardWithMedia4} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} className={clsx(container, "OrderItems")}>
            {itemsList}
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

Order.propTypes = propTypes;
Order.defaultProps = defaultProps;

export default Order;
export { propTypes as OrderPropTypes, defaultProps as OrderDefaultProps };
