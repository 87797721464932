/**
 * Translates a path
 *
 * Ex.: '/destinations' => '/destinatii'
 * Ex.: '/account?activeTab=0' => '/cont/activeTab=0'
 */
const localizePath = props => {
  const { path, t } = props;

  if (!path) return;

  const split = path.split("?");
  const queryParams = split[1] ? `?${split[1]}` : "";
  const pathWithoutQueryParams = split[0] ? split[0] : path;

  const pathWithoutSlash = pathWithoutQueryParams.substring(1);
  const pathWithoutSlashLocalized = t(pathWithoutSlash, "not-found");

  return pathWithoutSlashLocalized === "not-found"
    ? path
    : `/${pathWithoutSlashLocalized}${queryParams}`;
};

/**
 * Translates a set of route paths
 */
const localizeRoutes = props => {
  const { routes, t } = props;

  return (
    routes &&
    routes.map(item => {
      const { path } = item;
      return { ...item, path: localizePath({ path: path, t: t }) };
    })
  );
};

export { localizeRoutes, localizePath };
