/**
 * Component short description
 *
 * @see Hero.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import shortid from "shortid";

/**
 * Imports other components and hooks
 */
import Card2 from "../../Card2";
import Carousel from "../../Carousel";
import ImageResponsive from "../../ImageResponsive";
import { useUnsplash } from "../../../hooks";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Hero.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Hero.lang.ro-ro";
import { hu_hu } from "./Hero.lang.hu-hu";
import { en_us } from "./Hero.lang.en-us";
import { de_de } from "./Hero.lang.de-de";

i18n.addResourceBundle("ro-RO", "Hero", ro_ro);
i18n.addResourceBundle("hu-HU", "Hero", hu_hu);
i18n.addResourceBundle("en-US", "Hero", en_us);
i18n.addResourceBundle("de-DE", "Hero", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({}));

/**
 * Displays the component
 */
const Hero = props => {
  const { images } = props;
  const { container, headlineStyle, searchStyle } = useStyles(props);
  const { t } = useTranslation("Hero");

  /**
   * Loads unsplash images
   */
  const unsplashImages = useUnsplash({
    keyword: "Mountain resort",
    page: 1,
    photos: 5,
    params: { orientation: "landscape" }
  });

  /**
   * Displays the card
   */
  const images2 = unsplashImages ? unsplashImages : images;
  const carouselSteps1 = images2.map(image => {
    const { title } = image;

    return {
      id: shortid.generate(),
      title: title,
      content: <ImageResponsive {...image} />
    };
  });

  const cardWithMedia1 = {
    image: null,
    carousel: (
      <Carousel
        steps={carouselSteps1}
        isAutoPlay={true}
        isMobileStepperDisplayed={false}
      />
    )
  };

  return <Card2 {...cardWithMedia1} />;
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
export { propTypes as HeroPropTypes, defaultProps as HeroDefaultProps };
