/**
 * Displays an icon
 *
 * @see Icon.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import { Link as RouterLink, Redirect } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import { routesLocalizePath } from "../Routes";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Icon.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Icon.lang.ro-ro";
import { hu_hu } from "./Icon.lang.hu-hu";
import { en_us } from "./Icon.lang.en-us";
import { de_de } from "./Icon.lang.de-de";

i18n.addResourceBundle("ro-RO", "Icon", ro_ro);
i18n.addResourceBundle("hu-HU", "Icon", hu_hu);
i18n.addResourceBundle("en-US", "Icon", en_us);
i18n.addResourceBundle("de-DE", "Icon", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  },

  iconButton: {
    maxWidth: 64
  },

  buttonWithoutLabel: {
    minWidth: "auto"
  },

  hr: {
    width: "100%"
  }
}));

/**
 * Displays the component
 */
const Icon = props => {
  const {
    icon,
    name: originalName,
    url,
    path: originalPath,
    component,
    disabled,
    callback,
    divider,
    highlighted,
    displayName,
    doTranslateName
  } = props;

  const { container, iconButton, hr, buttonWithoutLabel } = useStyles(props);
  const { t } = useTranslation("Icon");
  const { t: t2 } = useTranslation("Routes");

  /**
   * Translates the name if necessary
   */
  const name = doTranslateName ? t(originalName) : originalName;
  const path = doTranslateName
    ? routesLocalizePath({ path: originalPath, t: t2 })
    : originalPath;

  /**
   * Checks if the icon name should be displayed
   */
  const iconName = displayName ? (
    <span className="ButtonLabel">{name}</span>
  ) : null;

  /**
   * Ads className to Button
   */
  const buttonKlass = displayName ? null : buttonWithoutLabel;

  /**
   * Highlights an icon
   */
  const color = highlighted ? "primary" : "default";

  /**
   * Displays a divider
   */
  if (divider) {
    return <Divider {...divider} className={clsx(hr)} />;
  }

  /**
   * Uses a callback and path
   */
  if (callback && path) {
    return (
      <Button
        startIcon={icon}
        to={path}
        component={RouterLink}
        disabled={disabled}
        onClick={() => callback()}
        color={color}
        className={clsx(buttonKlass)}
      >
        {iconName}
      </Button>
    );
  }

  /**
   * Uses a callback
   */
  if (callback) {
    return (
      <Button
        startIcon={icon}
        disabled={disabled}
        onClick={() => callback()}
        color={color}
        className={clsx(buttonKlass)}
      >
        {iconName}
      </Button>
    );
  }

  /**
   * Displays the component
   */
  if (component) {
    return icon ? (
      <Button startIcon={icon} className={clsx(buttonKlass)}>
        {component}
      </Button>
    ) : (
      component
    );
  }

  /**
   * Displays a button with icon
   */
  if (name) {
    return url ? (
      <Button
        startIcon={icon}
        href={url}
        disabled={disabled}
        color={color}
        className={clsx(buttonKlass)}
      >
        {iconName}
      </Button>
    ) : path ? (
      <Button
        startIcon={icon}
        to={path}
        component={RouterLink}
        disabled={disabled}
        color={color}
        className={clsx(buttonKlass)}
      >
        {iconName}
      </Button>
    ) : (
      <Button
        startIcon={icon}
        disabled={disabled}
        color={color}
        className={clsx(buttonKlass)}
      >
        {iconName}
      </Button>
    );
  }

  /**
   * Displays an IconButton
   */
  return url ? (
    <IconButton
      href={url}
      className={clsx(iconButton)}
      disabled={disabled}
      color={color}
    >
      {icon}
    </IconButton>
  ) : path ? (
    <IconButton
      to={path}
      component={RouterLink}
      className={clsx(iconButton)}
      disabled={disabled}
      color={color}
    >
      {icon}
    </IconButton>
  ) : null;
};

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;
export { propTypes as IconPropTypes, defaultProps as IconDefaultProps };
