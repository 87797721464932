/**
 * Component short description
 *
 * @see Sitemap.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Card2, {
  Card2SingleRowStyles,
  Card2SingleRowStylesInverted
} from "../../Card2";
import { TextSection, Template, PageHeader } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Sitemap.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Sitemap.lang.ro-ro";
import { hu_hu } from "./Sitemap.lang.hu-hu";
import { en_us } from "./Sitemap.lang.en-us";
import { de_de } from "./Sitemap.lang.de-de";

i18n.addResourceBundle("ro-RO", "Sitemap", ro_ro);
i18n.addResourceBundle("hu-HU", "Sitemap", hu_hu);
i18n.addResourceBundle("en-US", "Sitemap", en_us);
i18n.addResourceBundle("de-DE", "Sitemap", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  }
}));

/**
 * Displays the component
 */
const Sitemap = props => {
  const { container } = useStyles(props);
  const { t } = useTranslation("Sitemap");

  return (
    <Layout footer={{ currentPage: "Sitemap" }}>
      <Grid container spacing={1} className={clsx(container, "Sitemap")}>
        <Grid item xs={12}>
          <PageHeader title={t("Sitemap")} />
        </Grid>
        <Grid container spacing={0} className={clsx(container)}>
          <Grid item xs={12}>
            ...content here
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

Sitemap.propTypes = propTypes;
Sitemap.defaultProps = defaultProps;

export default Sitemap;
export { propTypes as SitemapPropTypes, defaultProps as SitemapDefaultProps };
