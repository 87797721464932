/**
 * Component short description
 *
 * @see FilterRoomType.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import FilterListItem from "../../FilterListItem/FilterListItem";
import { useFilters } from "../../../hooks";
import {
  getSelectedArrayValues,
  getArrayOfKeys,
} from "../../../hooks/useFilters";
import FilterChips from "../../FilterChips/FilterChips";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./FilterRoomType.data";

/**
 * Imports Material UI components
 */
import { ListItemText } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./FilterRoomType.lang.ro-ro";
import { hu_hu } from "./FilterRoomType.lang.hu-hu";
import { en_us } from "./FilterRoomType.lang.en-us";
import { de_de } from "./FilterRoomType.lang.de-de";

i18n.addResourceBundle("ro-RO", "FilterRoomType", ro_ro);
i18n.addResourceBundle("hu-HU", "FilterRoomType", hu_hu);
i18n.addResourceBundle("en-US", "FilterRoomType", en_us);
i18n.addResourceBundle("de-DE", "FilterRoomType", de_de);

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const FilterRoomType = (props) => {
  const { filterListItem, defaultValue } = props;
  const { t } = useTranslation("FilterRoomType");

  /**
   * Handles the filters
   */
  const { filters, setFilters, data } = useFilters();
  const { roomTypes } = data;

  const selected = getSelectedArrayValues(
    "id",
    filters.roomType?.filterValue || defaultValue,
    roomTypes
  );

  const selectHandler = (data) => {
    const {
      target: { value },
    } = data;
    if (value[0] === "empty") {
      return;
    }

    setFilters((prevState) => {
      return {
        ...prevState,
        roomType: {
          filterValue: getArrayOfKeys("id", value),
          label: getArrayOfKeys("name", value),
        },
      };
    });
  };

  return (
    <FilterListItem {...filterListItem} primary={t("name")}>
      <Select
        multiple
        name="seller"
        // open={open}
        // onOpen={handleOpenSelect}
        // onClose={handleCloseSelect}
        value={selected}
        onChange={selectHandler}
        fullWidth
        displayEmpty
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <FilterChips selected={selected} emptyText={t("placeholder")} />
        )}
      >
        {roomTypes.map((r) => {
          return (
            <MenuItem key={r.id} value={r}>
              <ListItemText primary={r.name} />
            </MenuItem>
          );
        })}
      </Select>
    </FilterListItem>
  );
};

FilterRoomType.propTypes = propTypes;
FilterRoomType.defaultProps = defaultProps;

export default FilterRoomType;
export {
  propTypes as FilterRoomTypePropTypes,
  defaultProps as FilterRoomTypeDefaultProps,
};
