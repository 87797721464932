import * as React from "react";

function SvgPyLogoHorizontalColorCropped(props) {
  return (
    <svg
      id="py_logo_horizontal_color-cropped_svg__Layer_1"
      x={0}
      y={0}
      xmlSpace="preserve"
      width={505.1}
      height={78.5}
      {...props}
    >
      <style type="text/css" id="style2">
        {".py_logo_horizontal_color-cropped_svg__st0{fill:#005837}"}
      </style>
      <g
        id="py_logo_horizontal_color-cropped_svg__g32"
        transform="translate(-42.7 -350.2)"
      >
        <g id="py_logo_horizontal_color-cropped_svg__g20">
          <path
            className="py_logo_horizontal_color-cropped_svg__st0"
            d="M173.5 372.9c-1.7-.6-3.9-.9-6.7-.9H162c-1.3 0-2.5.1-3.7.1-1.1.1-2.3.2-3.6.3v34.2h5v-13.5c1.1.1 2.3.2 3.5.3 1.3.1 2.7.1 4.1.1 2.6 0 4.7-.3 6.3-.9 1.6-.6 2.7-1.7 3.4-3.3.7-1.6 1-3.8 1-6.6s-.4-5-1.1-6.6c-.6-1.5-1.7-2.6-3.4-3.2zm-1 13.9c-.4 1-1.1 1.6-2 2-.9.4-2.2.6-3.8.6h-6.9v-13.3h7c1.6 0 2.8.2 3.8.6.9.4 1.6 1 2 2 .4 1 .6 2.3.6 4.1-.1 1.7-.3 3.1-.7 4z"
            id="py_logo_horizontal_color-cropped_svg__path4"
            fill="#005837"
          />
          <path
            className="py_logo_horizontal_color-cropped_svg__st0"
            id="py_logo_horizontal_color-cropped_svg__rect6"
            fill="#005837"
            d="M209.1 372.4h5v34.2h-5z"
          />
          <path
            className="py_logo_horizontal_color-cropped_svg__st0"
            d="M269.1 396.2v2.6c0 .9.1 1.8.1 2.7h-.8c-.4-.9-.8-1.7-1.2-2.6-.4-.9-.8-1.7-1.2-2.4L255.3 374c-.2-.5-.5-.9-.8-1.1-.3-.3-.8-.4-1.3-.4H249c-1.3 0-1.9.6-1.9 1.9v32.3h4.9V382v-2.4c0-.8-.1-1.5-.1-2.3h.7c.2.7.5 1.5.9 2.4.4.8.7 1.6 1 2.3l11.2 23.1c.2.5.5.9.8 1.1.3.3.8.4 1.3.4h4.3c1.2 0 1.8-.6 1.8-1.9v-32.3h-4.8z"
            id="py_logo_horizontal_color-cropped_svg__path8"
            fill="#005837"
          />
          <path
            className="py_logo_horizontal_color-cropped_svg__st0"
            d="M312.4 377.2c.5-.5 1.2-.7 2.2-.7h13.6l.2-3.8c-1.5-.1-3.1-.2-4.8-.2-1.7 0-3.4-.1-5.1-.1h-5.1c-2 0-3.7.5-4.9 1.6-1.2 1.1-1.8 2.6-1.9 4.5v22.1c0 1.9.7 3.3 1.9 4.4 1.2 1.1 2.8 1.7 4.9 1.7h5.1c1.7 0 3.4 0 5.1-.1 1.7 0 3.3-.1 4.8-.2l-.2-3.8h-13.6c-1 0-1.7-.3-2.2-.8-.5-.5-.7-1.3-.7-2.4v-8.9h14.6v-3.8h-14.6v-7.1c0-1.1.2-1.9.7-2.4z"
            id="py_logo_horizontal_color-cropped_svg__path10"
            fill="#005837"
          />
          <path
            className="py_logo_horizontal_color-cropped_svg__st0"
            d="M378.4 372.4l-6.9 14.1c-.3.6-.5 1.2-.8 1.9-.2.7-.5 1.3-.7 2h-.3c-.2-.7-.5-1.3-.7-2-.2-.7-.5-1.3-.8-2l-6.9-14.1H356l11.3 22.3v11.9h5v-11.8l11.3-22.4h-5.2z"
            id="py_logo_horizontal_color-cropped_svg__path12"
            fill="#005837"
          />
          <path
            className="py_logo_horizontal_color-cropped_svg__st0"
            d="M425.4 372.8c-.3-.2-.7-.4-1.1-.4h-5.1c-.4 0-.8.1-1.1.4-.3.2-.6.6-.7 1l-10.8 32.9h5.1l3.2-10.4h13.6l3.2 10.4h5.2l-10.8-32.9c-.2-.4-.4-.8-.7-1zm-9.2 19.3l4-13c.2-.5.3-1 .4-1.5.1-.5.3-1 .4-1.4h1.4c.1.4.3.9.4 1.4.1.5.3 1 .4 1.5l4 13z"
            id="py_logo_horizontal_color-cropped_svg__path14"
            fill="#005837"
          />
          <path
            className="py_logo_horizontal_color-cropped_svg__st0"
            d="M486.5 393.3c-.5-.7-1-1.2-1.6-1.6-.6-.4-1.1-.7-1.7-.8v-.2c1.6-.2 2.8-.6 3.7-1.3.9-.7 1.6-1.7 2-2.9.4-1.2.6-2.7.6-4.6 0-2.6-.4-4.6-1.1-6-.8-1.4-2-2.4-3.6-3-1.7-.6-3.9-.9-6.6-.9h-4.8c-1.3 0-2.6.1-3.7.1-1.1.1-2.3.2-3.6.3v34.2h4.9v-14.2h7.2c1.1 0 2 .3 2.7.8.7.5 1.2 1.4 1.6 2.7l3 10.7h5.3l-3.2-10.8c-.2-1-.6-1.8-1.1-2.5zm-4.5-5.5c-.9.3-2.2.5-3.8.6h-7v-12.2h7c1.6 0 2.9.2 3.8.5.9.3 1.6 1 2 1.8.4.9.6 2.1.6 3.7s-.2 2.9-.6 3.8c-.4.8-1.1 1.5-2 1.8z"
            id="py_logo_horizontal_color-cropped_svg__path16"
            fill="#005837"
          />
          <path
            className="py_logo_horizontal_color-cropped_svg__st0"
            d="M547.1 381.2c-.5-2.3-1.2-4.1-2.3-5.4a9.64 9.64 0 00-4.2-2.9c-1.7-.6-3.9-.9-6.4-.9-2.4 0-4.6 0-6.6.1s-4 .2-5.8.3v34.2c1.8.1 3.7.2 5.8.3 2 .1 4.2.1 6.6.1 2.5 0 4.6-.3 6.4-.9 1.7-.6 3.1-1.6 4.2-2.9 1.1-1.3 1.8-3.1 2.3-5.4.5-2.3.7-5 .7-8.3 0-3.3-.2-6.1-.7-8.3zm-5.3 16.3c-.5 1.9-1.4 3.3-2.6 4.1-1.2.8-2.9 1.2-5 1.2s-4 0-5.7-.1c-.6 0-1.2 0-1.8-.1V376h7.4c2.1 0 3.8.4 5 1.3 1.2.8 2.1 2.2 2.6 4.2.5 1.9.8 4.6.8 8 .1 3.4-.1 6.1-.7 8z"
            id="py_logo_horizontal_color-cropped_svg__path18"
            fill="#005837"
          />
        </g>
        <g id="py_logo_horizontal_color-cropped_svg__g30">
          <g id="py_logo_horizontal_color-cropped_svg__g24">
            <path
              d="M69.3 393.8c3.6 3.6 8.3 10.8 8.3 10.8 3.9-1 1.2-4 1.2-4 4.8 1.8.6 7.2.6 7.2 1.4-.4 5.6-2.4 5.4-2.8 1.9 2.7-3.5 4.8-3.5 4.8 5 .9 10.5-.5 10.5-.5-3.4 2.5-8.2 2.8-8.2 2.8C94.4 415.9 98 410 98 410c.2 2.1-2.5 4.5-2.5 4.5 3.9-.4 8.8-3.2 8.8-3.2-1.4 3.6-9 7.3-9 7.3 11.4 1.9 15.9-3.6 15.9-3.6.8 3-4.9 9.5-4.9 9.5 15.3 0 15.9-12.7 15.9-12.7v-14.5c-19.3 13-47.3-.5-52.9-3.5z"
              id="py_logo_horizontal_color-cropped_svg__path22"
              fill="#89a527"
            />
          </g>
          <path
            className="py_logo_horizontal_color-cropped_svg__st0"
            d="M122.2 350.2s-34.8 7.6-52 .9c0 0 6.7 9.6 18.4 8.3 11.7-1.3-.9 4.1-.9 4.1s12.3 4.9 20.5-2.9c0 0-.9 4.6-9.1 9 0 0 13.1 3.6 16.8-4.8 0 0 .4 3.4-3.3 10.8 0 0 6.1.4 9.5-9.8v-15.6z"
            id="py_logo_horizontal_color-cropped_svg__path26"
            fill="#005837"
          />
          <path
            d="M60 350.5v-.1H42.7v13c1-.4 2.2-7.6 2.2-7.6 1.8 2-.8 11.3-.8 11.3 3-1.6 5.2-7.4 5.2-7.4-.2 1.5-.3 5.9-.3 10.4 0 0-4.1 4-6.3 3.8v23.7c0 .4 6.2-3.7 7.1-14.2.5 6.3 1.2 12.4 2.1 17.9 0 0-4.6 5.2-9.2 6.9v19.1s3.5-5.3 5.2-14.5c0 0 4.4 4.7 1.9 12.3 0 0 2.7-1.3 3.7-14.3 2 10.8 4.1 17.9 4.1 17.9H73c-1.8-2.6-1.8-4.6-2.2-6.2.4 0 1.8.8 4.2 4.2.5.6.7 1.4 1.2 2h5.9c-1.7-.9-2.6-4.8-2.6-4.8 1.5 1.9 4.6 2.8 6 4.8H95c0-.8.1-1.3.1-1.3.3.5.6.9.9 1.3h26.3v-.3c-27.6-.8-56.8-20.7-58.2-21.6-3.8-11.5-5-18.2-5.3-27.8.4 1.9 2.2 3.4 2.2 3.4-1.2-4 0-9.7 0-9.7 1.8 17.1 13.9 19.4 13.9 19.4-4.3-8.3 1.5-12.9 1.5-12.9-1.3 11.9 12.6 16 12.6 16-1.9-2.4-2.1-4.6-2.1-4.6 4.3 6.1 15.3 4.2 15.3 4.2-18.7-3.7-14.7-14.3-14.7-14.3 7.3 14.3 23.7 11.6 23.7 11.6-11.1-4.5-9.2-10.5-9.2-10.5 8.2 8.4 20.3 7.9 20.3 7.9v-9.9C78.4 377 61.4 352.3 60 350.5z"
            id="py_logo_horizontal_color-cropped_svg__path28"
            fill="#2e8232"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgPyLogoHorizontalColorCropped;

