/**
 * Component short description
 *
 * @see NewsletterForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./NewsletterForm.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./NewsletterForm.lang.ro-ro";
import { hu_hu } from "./NewsletterForm.lang.hu-hu";
import { en_us } from "./NewsletterForm.lang.en-us";
import { de_de } from "./NewsletterForm.lang.de-de";

i18n.addResourceBundle("ro-RO", "NewsletterForm", ro_ro);
i18n.addResourceBundle("hu-HU", "NewsletterForm", hu_hu);
i18n.addResourceBundle("en-US", "NewsletterForm", en_us);
i18n.addResourceBundle("de-DE", "NewsletterForm", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: { ...theme.custom.grid.removeNegativeMargin },

  select: {
    width: "100%",
    maxWidth: "90%"
  }
}));

/**
 * Displays the component
 */
const NewsletterForm = props => {
  const { container, select } = useStyles(props);
  const { t } = useTranslation("NewsletterForm");

  return (
    <Grid
      container
      spacing={1}
      className={clsx(container, "NewsletterForm")}
      alignItems="center"
    >
      <Grid item xs={12}>
        <TextField
          label={t("Email address")}
          variant="outlined"
          className={clsx(select)}
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" size="large">
          {t("Subscribe")}
        </Button>
      </Grid>
    </Grid>
  );
};

NewsletterForm.propTypes = propTypes;
NewsletterForm.defaultProps = defaultProps;

export default NewsletterForm;
export {
  propTypes as NewsletterFormPropTypes,
  defaultProps as NewsletterFormDefaultProps
};
