/**
 * Component short description
 *
 * @see VideoAsThumb.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Card2 from "../../Card2";
import { useUnsplash } from "../../../hooks";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./VideoAsThumb.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./VideoAsThumb.lang.ro-ro";
import { hu_hu } from "./VideoAsThumb.lang.hu-hu";
import { en_us } from "./VideoAsThumb.lang.en-us";
import { de_de } from "./VideoAsThumb.lang.de-de";

i18n.addResourceBundle("ro-RO", "VideoAsThumb", ro_ro);
i18n.addResourceBundle("hu-HU", "VideoAsThumb", hu_hu);
i18n.addResourceBundle("en-US", "VideoAsThumb", en_us);
i18n.addResourceBundle("de-DE", "VideoAsThumb", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {}
}));

/**
 * Displays the component
 */
const VideoAsThumb = props => {
  const { name, url, image, index } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("VideoAsThumb");

  /**
   * Loads unsplash images
   */
  const unsplashImages = useUnsplash({
    keyword: "Mountain resort",
    page: index + 1,
    photos: 1,
    params: { orientation: "landscape" }
  });

  /**
   * Displays thumb as a card
   */
  const cardContent = (
    <Typography variant="body1">
      <Link href={url}>{name}</Link>
    </Typography>
  );

  const image2 = unsplashImages ? unsplashImages[0] : image;

  //// TODO: Add link support in Card2
  const cardWithMedia = {
    image: image2,
    images: null,
    content: cardContent,
    customStyles: useStyles(props)
  };

  return <Card2 {...cardWithMedia} breakpoint="sm" />;
};

VideoAsThumb.propTypes = propTypes;
VideoAsThumb.defaultProps = defaultProps;

export default VideoAsThumb;
export {
  propTypes as VideoAsThumbPropTypes,
  defaultProps as VideoAsThumbDefaultProps
};
