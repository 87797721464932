/**
 * Component short description
 *
 * @see LocalMarketIntro.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Card2, {
  Card2SingleRowStyles,
  Card2SingleRowStylesInverted
} from "../../Card2";
import { useUnsplash } from "../../../hooks";
import { TextSection } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./LocalMarketIntro.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./LocalMarketIntro.lang.ro-ro";
import { hu_hu } from "./LocalMarketIntro.lang.hu-hu";
import { en_us } from "./LocalMarketIntro.lang.en-us";
import { de_de } from "./LocalMarketIntro.lang.de-de";

i18n.addResourceBundle("ro-RO", "LocalMarketIntro", ro_ro);
i18n.addResourceBundle("hu-HU", "LocalMarketIntro", hu_hu);
i18n.addResourceBundle("en-US", "LocalMarketIntro", en_us);
i18n.addResourceBundle("de-DE", "LocalMarketIntro", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  }
}));

/**
 * Displays the component
 */
const LocalMarketIntro = props => {
  const { description, image } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("LocalMarketIntro");

  /**
   * Loads unsplash images
   */
  const unsplashImages = useUnsplash({
    keyword: "Vegetables",
    page: 5,
    photos: 1,
    params: { orientation: "landscape" }
  });

  /**
   * Displays the hero card
   */
  const cardContent1 = (
    <TextSection title={t("Local market")} description={description} />
  );

  const cardActions1 = (
    <Button variant="contained" color="primary" size="large">
      {t("Marketplace")}
    </Button>
  );

  const cardWithMedia1 = {
    image: unsplashImages ? unsplashImages[0] : image,
    content: cardContent1,
    actions: cardActions1,
    customStyles: Card2SingleRowStyles({ ...props, breakpoint: "md" })
  };

  return <Card2 {...cardWithMedia1} />;
};

LocalMarketIntro.propTypes = propTypes;
LocalMarketIntro.defaultProps = defaultProps;

export default LocalMarketIntro;
export {
  propTypes as LocalMarketIntroPropTypes,
  defaultProps as LocalMarketIntroDefaultProps
};
