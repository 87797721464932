/**
 * Component short description
 *
 * @see Dialog2.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Icon from "../Icon";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Dialog2.data";

/**
 * Imports Material UI components
 */
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Dialog2.lang.ro-ro";
import { hu_hu } from "./Dialog2.lang.hu-hu";
import { en_us } from "./Dialog2.lang.en-us";
import { de_de } from "./Dialog2.lang.de-de";

i18n.addResourceBundle("ro-RO", "Dialog2", ro_ro);
i18n.addResourceBundle("hu-HU", "Dialog2", hu_hu);
i18n.addResourceBundle("en-US", "Dialog2", en_us);
i18n.addResourceBundle("de-DE", "Dialog2", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  },

  /**
   * Spacing will be done by the Grid
   */
  titleKlass: {
    padding: 0,
    paddingLeft: theme.spacing(1),

    ["& .MuiTypography-root"]: {
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      alignItems: "center"
    }
  },

  /**
   * Stretching the dialog content
   * - `<Dialog maxWidth="lg" ...` is not enough
   */
  contentKlass: props => ({
    width: props.width ? props.width : "99vw",
    height: props.height ? props.height : "99vh",
    maxWidth: "100%",
    maxHeight: "100%",
    padding: "0 !important"
  }),

  removeTopPadding: {
    paddingTop: "0 !important"
  }
}));

/**
 * Displays the component
 */
const Dialog2 = props => {
  const { id, open, onClose, title, content, maxWidth, width, height } = props;
  const { container, titleKlass, contentKlass, removeTopPadding } = useStyles(
    props
  );
  const { t } = useTranslation("Dialog2");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const spacing = isMobile ? 4 : 8;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={id}
      maxWidth={maxWidth}
    >
      <Grid container spacing={spacing} className={clsx(container, "Dialog2")}>
        <Grid item xs={12}>
          <DialogTitle id={id} className={clsx(titleKlass, "Dialog2Title")}>
            {title}
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        </Grid>
        <Grid item xs={12} className={clsx(removeTopPadding)}>
          <DialogContent className={clsx(contentKlass, "Dialog2Content")}>
            {content}
          </DialogContent>
        </Grid>
      </Grid>
    </Dialog>
  );
};

Dialog2.propTypes = propTypes;
Dialog2.defaultProps = defaultProps;

export default Dialog2;
export { propTypes as Dialog2PropTypes, defaultProps as Dialog2DefaultProps };
