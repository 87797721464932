/**
 * Component short description
 *
 * @see AccountMenu.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState } from "react";
import clsx from "clsx";
import shortid from "shortid";
import { useRouteMatch } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import Icons from "../../Icons";
import { getRoles } from "../AccountRoles";
import Auth from "../Auth";
import { useAuth } from "../../../hooks";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./AccountMenu.data";

/**
 * Imports Material UI components
 */
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./AccountMenu.lang.ro-ro";
import { hu_hu } from "./AccountMenu.lang.hu-hu";
import { en_us } from "./AccountMenu.lang.en-us";
import { de_de } from "./AccountMenu.lang.de-de";

i18n.addResourceBundle("ro-RO", "AccountMenu", ro_ro);
i18n.addResourceBundle("hu-HU", "AccountMenu", hu_hu);
i18n.addResourceBundle("en-US", "AccountMenu", en_us);
i18n.addResourceBundle("de-DE", "AccountMenu", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: { ...theme.custom.grid.removeNegativeMargin }
}));

/**
 * Displays the component
 */
const AccountMenu = props => {
  const { icon, menuIcons, account } = props;
  const { items, orientation } = menuIcons;
  const { name } = account;
  const { isAuthenticated } = useAuth();

  const { container } = useStyles(props);
  const { t } = useTranslation("AccountMenu");

  /**
   * Manages the state of the menu
   */
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Gets the current path
   */
  const match = useRouteMatch();
  const { path: currentPath } = match;

  /**
   * Puts together the menu items
   */
  const userRoles = getRoles(account);
  const icons = [
    {
      name: name,
      icon: <AccountBoxIcon />,
      id: shortid.generate(),
      doTranslateName: true
    },
    ...userRoles.map(item => {
      /**
       * Disables the icon if it links to the current page
       */
      const { path } = item;
      return { ...item, disabled: path === currentPath };
    }),
    ...items.map(item => {
      /**
       * Adds a menu close callback for items having their path point to `/account`
       */
      const { name } = item;
      return {
        ...item,
        callback: ["Settings", "Booking History", "Order History"].includes(
          name
        )
          ? () => {
              setAnchorEl(null);
            }
          : null
      };
    })
  ];

  /**
   * Displays a text near the icon on small devices
   * - Check Header.js for details
   */
  const theme = useTheme();
  const isHamburgerMenu = useMediaQuery(theme.breakpoints.down("md"));
  const canIconNameBeDisplayed = useMediaQuery(theme.breakpoints.up("xl"));
  const text = isHamburgerMenu || canIconNameBeDisplayed ? t("Account") : null;

  /**
   * Displays a menu for authenticated users
   */
  const authenticatedMenu = (
    <>
      <Button
        startIcon=<AccountBoxIcon />
        aria-controls="account-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {text}
      </Button>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Icons items={icons} orientation={orientation} />
      </Menu>
    </>
  );

  /**
   * Displays a menu for non-authenticated users
   */
  const nonAuthenticatedMenu = <Auth />;

  const menu = isAuthenticated ? authenticatedMenu : nonAuthenticatedMenu;

  return <div className={clsx(container, "AccountMenu")}>{menu}</div>;
};

AccountMenu.propTypes = propTypes;
AccountMenu.defaultProps = defaultProps;

export default AccountMenu;
export {
  propTypes as AccountMenuPropTypes,
  defaultProps as AccountMenuDefaultProps
};
