/**
 * Defines the data requirements for the component
 *
 * @see Terms.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import Lorem from "react-lorem-component";

/**
 * Defines the prop types
 *
 * No API for this, yet
 */
const propTypes = {
  description: PropTypes.string,
  sectionTitle1: PropTypes.string,
  sectionDescription1: PropTypes.string,
  sectionDescription1a: PropTypes.string,
  sectionListing1: PropTypes.string,
  sectionTitle2: PropTypes.string,
  sectionDescription2: PropTypes.string,
  sectionDescription2a: PropTypes.string,
  sectionListing2: PropTypes.string
};

/**
 * Defines the default props
 */
const defaultProps = {
  description: <Lorem seed="0" count="1" sentenceUpperBound="14" />,
  sectionTitle1: "Section Title",
  sectionDescription1: <Lorem seed="1" count="1" sentenceUpperBound="14" />,
  sectionDescription1a: <Lorem seed="10" count="1" sentenceUpperBound="14" />,
  sectionListing1: (
    <Lorem seed="3" mode="list" count="3" sentenceUpperBound="7" />
  ),
  sectionTitle2: "Section Title",
  sectionDescription2: <Lorem seed="2" count="1" sentenceUpperBound="14" />,
  sectionDescription2a: <Lorem seed="20" count="1" sentenceUpperBound="14" />,
  sectionListing2: (
    <Lorem seed="4" mode="list" count="3" sentenceUpperBound="7" />
  )
};

export { propTypes, defaultProps };
