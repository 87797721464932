/**
 * Defines the data requirements for the component
 *
 * @see Product.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import Lorem from "react-lorem-component";

/**
 * Imports prop types from other components
 */
import {
  ImageResponsivePropTypes,
  ImageResponsiveDefaultProps,
  ImageResponsivePlaceholderCom169
} from "../../ImageResponsive";

/**
 * Defines the prop types
 */
const propTypes = {
  /**
   * From API
   *
   * @see https://api.borsecturismregional.web-staging.eu/apidoc/ > MarketProduct
   */
  id: PropTypes.string,
  name: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.shape(ImageResponsivePropTypes)),
  description: PropTypes.string,
  price: PropTypes.number,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      id: shortid.generate(),
      name: PropTypes.string,
      value: PropTypes.tring
    })
  ),
  sectionDescription1: PropTypes.string,
  sectionListing: PropTypes.string,
  sectionDescription2: PropTypes.string,
  /**
   * From parent
   */
  destinationName: PropTypes.string,
  /**
   * Other props
   *
   * // NOTE: Tell backend slug is missing
   */
  slug: PropTypes.string
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: shortid.generate(),
  name: "Product name",
  slug: "product-name",
  images: Array(3)
    .fill(ImageResponsivePlaceholderCom169)
    .map(item => {
      return { ...item, id: shortid.generate() };
    }),
  // Around 250 chars
  description: <Lorem seed="3" count="1" sentenceUpperBound="7" />,
  price: 10.55,
  units: Array(3)
    .fill("Unit")
    .map((item, index) => {
      return { ...item, id: shortid.generate(), name: `${index + 1} ${item}` };
    }),
  sectionDescription1: <Lorem seed="1" count="2" sentenceUpperBound="5" />,
  sectionListing: (
    <Lorem seed="4" count="3" mode="list" sentenceUpperBound="3" />
  ),
  sectionDescription2: <Lorem seed="2" count="2" sentenceUpperBound="5" />,
  destinationName: "Destination name"
};

export { propTypes, defaultProps };
