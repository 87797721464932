/**
 * Defines the data requirements for the component
 *
 * @see Order.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import Lorem from "react-lorem-component";
import shortid from "shortid";

/**
 * Imports prop types from other components
 */
import { OrderItemPropTypes, OrderItemDefaultProps } from "../OrderItem";

/**
 * Defines the prop PropTypes
 *
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/Market/getMarketOrderByID
 */
const propTypes = {
  id: PropTypes.string,
  seller: PropTypes.string,
  orderDate: PropTypes.string,
  orderItems: PropTypes.number,
  totalPrice: PropTypes.number,
  status: PropTypes.string,
  statusDescription: PropTypes.string,
  refusalReason: PropTypes.string,
  personalName: PropTypes.string,
  personalAddress: PropTypes.string,
  personalPhone: PropTypes.string,
  deliveryName: PropTypes.string,
  deliveryAddress: PropTypes.string,
  deliveryPhone: PropTypes.string,
  items: PropTypes.shape(OrderItemPropTypes)
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: shortid.generate(),
  seller: "Seller1",
  orderDate: "2020-07-25T17:32:28.000Z",
  orderItems: 5,
  totalPrice: 100,
  status: "Status1",
  refusal_reason: "Reason for refusal",
  statusDescription: "Status1 description",
  personalName: "User name",
  personalAddress: "User address",
  personalPhone: "0751378457",
  deliveryName: "User name",
  deliveryAddress: "User name delivery address",
  deliveryPhone: "0751378457",
  items: Array(3)
    .fill(OrderItemDefaultProps)
    .map((item, index) => {
      return {
        ...item,
        id: shortid.generate(),
        name: `Order item ${index + 1}`,
        destinationName: `Destination name ${index + 1}`,
        price: Math.floor(Math.random() * Math.floor(100)) + 1,
        quantity: Math.floor(Math.random() * Math.floor(10)) + 1
      };
    })
};

export { propTypes, defaultProps };
