/**
 * Defines the data requirements for the component
 *
 * @see Checkout.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import Lorem from "react-lorem-component";

/**
 * Imports prop types from other components
 */
import {
  OrderForCheckoutPropTypes,
  OrderForCheckoutDefaultProps
} from "../../_account/OrderForCheckout";

/**
 * Defines the prop types
 *
 * // NOTE: Couldn't find anything in ApiDoc, going with Figma
 */
const propTypes = {
  description: PropTypes.string,
  personalName: PropTypes.string,
  personalAddress: PropTypes.string,
  personalPhone: PropTypes.string,
  deliveryName: PropTypes.string,
  deliveryAddress: PropTypes.string,
  deliveryPhone: PropTypes.string,
  order: PropTypes.shape(OrderForCheckoutPropTypes)
};

/**
 * Defines the default props
 */
const defaultProps = {
  description: <Lorem seed="0" count="1" sentenceUpperBound="14" />,
  personalName: "User name",
  personalAddress: "User address",
  personalPhone: "0751378457",
  deliveryName: "User name",
  deliveryAddress: "User name delivery address",
  deliveryPhone: "0751378457",
  order: OrderForCheckoutDefaultProps
};

export { propTypes, defaultProps };
