/**
 * Defines the data requirements for the component
 *
 * @see OrderForCheckout.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { OrderPropTypes, OrderDefaultProps } from "../Order";

/**
 * Defines the prop types
 */
const propTypes = { ...OrderPropTypes };

/**
 * Defines the default props
 */
const defaultProps = { ...OrderDefaultProps };

export { propTypes, defaultProps };
