/**
 * Defines the data requirements for the component
 *
 * @see Events.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";

/**
 * Imports prop types from other components
 */
import {
  EventAsThumbPropTypes,
  EventAsThumbDefaultProps
} from "../../_event/EventAsThumb";
import {
  DestinationPropTypes,
  DestinationDefaultProps
} from "../../_destination/Destination";

import { IconPropTypes, IconDefaultProps } from "../../Icon";
import DateRangeIcon from "@material-ui/icons/DateRange";

/**
 * Defines the prop types
 *
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/Events/getEvents
 */
const propTypes = {
  /**
   * Internal props
   */
  events: PropTypes.arrayOf(PropTypes.shape(EventAsThumbPropTypes)),
  destinations: PropTypes.arrayOf(PropTypes.shape(DestinationPropTypes)),
  monthIcon: PropTypes.shape(IconPropTypes)
};

/**
 * Defines the default props
 */
const defaultProps = {
  events: Array(12)
    .fill(EventAsThumbDefaultProps)
    .map((item, index) => {
      return {
        ...item,
        id: shortid.generate(),
        name: `Event ${index + 1}`,
        slug: `event-${index + 1}`
      };
    }),
  destinations: Array(3)
    .fill(DestinationDefaultProps)
    .map((item, index) => {
      return {
        ...item,
        id: shortid.generate(),
        name: `Destination ${index + 1}`
      };
    }),
  monthIcon: {
    icon: <DateRangeIcon />
  }
};

export { propTypes, defaultProps };
