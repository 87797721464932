/**
 * Imports React and third party packages
 */
import useSWR from "swr";
import queryString from "query-string";

/**
 * Imports other components and hooks
 */
import { useRequests } from "../../../hooks";

const useStayInfoSwr = () => {
  const { get } = useRequests();
  return useSWR("stay/info", get);
};

const useAccomodationTypesSwr = () => {
  const { get } = useRequests();
  return useSWR("stay/accommodation-types", get);
};

const useRoomTypesSwr = () => {
  const { get } = useRequests();
  return useSWR("stay/room-types", get);
};

const useExtrasSwr = () => {
  const { get } = useRequests();
  return useSWR("stay/extras", get);
};

const useStaySwr = (id) => {
  const { get } = useRequests();
  return useSWR(`stay/accommodation/${id}`, get);
};

const useStayHistorySwr = () => {
  const { get } = useRequests();
  return useSWR(`stay/bookings`, get);
};

const useStay = () => {
  const { get, post } = useRequests();

  const getStays = (params) => {
    return get("stay/accommodations", params);
  };

  const createReservation = (stayId, reservation) => {
    return post(`stay/booking/${stayId}`, reservation);
  };

  return {
    getStays,
    createReservation,
    useStayInfoSwr,
    useAccomodationTypesSwr,
    useRoomTypesSwr,
    useExtrasSwr,
    useStaySwr,
    useStayHistorySwr,
  };
};

export default useStay;
export {
  useStayInfoSwr,
  useAccomodationTypesSwr,
  useRoomTypesSwr,
  useExtrasSwr,
  useStaySwr,
  useStayHistorySwr,
};
