/**
 * Displays the Destinations page
 *
 * @see Destinations.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import { Switch, Route, useRouteMatch } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Destination from "../../_destination/Destination";
import CategoriesAsTabs from "../../_category/CategoriesAsTabs";
import DestinationsList from "../DestinationsList";
import { PageHeader } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Destinations.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Destinations.lang.ro-ro";
import { hu_hu } from "./Destinations.lang.hu-hu";
import { en_us } from "./Destinations.lang.en-us";
import { de_de } from "./Destinations.lang.de-de";

i18n.addResourceBundle("ro-RO", "Destinations", ro_ro);
i18n.addResourceBundle("hu-HU", "Destinations", hu_hu);
i18n.addResourceBundle("en-US", "Destinations", en_us);
i18n.addResourceBundle("de-DE", "Destinations", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  }
}));

/**
 * Displays the component
 */
const Destinations = props => {
  const { categoriesAsTabs, destinationsList } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("Destinations");

  /**
   * Routes to a single desitination
   */
  const match = useRouteMatch();
  const { path } = match;

  return (
    <Switch>
      <Route path={`${path}/:slug`}>
        <Destination />
      </Route>
      <Route path={path}>
        <Layout header={{ currentPage: "Destinations" }}>
          <Grid
            container
            className={clsx(container, "Destinations")}
            spacing={1}
          >
            <Grid item xs={12}>
              <PageHeader title={t("Destinations")} />
            </Grid>
            <Grid item xs={12}>
              <CategoriesAsTabs {...categoriesAsTabs} />
              {/**/}
            </Grid>
            <Grid item xs={12}>
              <DestinationsList {...destinationsList} />
              {/**/}
            </Grid>
          </Grid>
        </Layout>
      </Route>
    </Switch>
  );
};

Destinations.propTypes = propTypes;
Destinations.defaultProps = defaultProps;

export default Destinations;
export {
  propTypes as DestinationsPropTypes,
  defaultProps as DestinationsDefaultProps
};
