/**
 * Defines the data requirements for the component
 *
 * @see Category.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import Lorem from "react-lorem-component";
import shortid from "shortid";

/**
 * Imports prop types from other components
 */
import {
  ImageResponsivePropTypes,
  ImageResponsiveDefaultProps,
  ImageResponsivePlaceholderCom169
} from "../../ImageResponsive";
import {
  AttractionPropTypes,
  AttractionDefaultProps
} from "../../_attraction/Attraction";

/**
 * Defines the prop types
 *
 * @see http://api.borsecturismregional.web-staging.eu/apidoc/#/Destinations/getDestinationCategories
 */
const propTypes = {
  id: PropTypes.string,
  slug: PropTypes.string,
  name: PropTypes.string,
  alternateName: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.shape(ImageResponsivePropTypes),
  icon: PropTypes.shape({
    alternateName: PropTypes.string,
    url: PropTypes.string
  }),
  count: PropTypes.number,
  attractions: PropTypes.arrayOf(PropTypes.shape(AttractionPropTypes)),
  destinationName: PropTypes.string
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: shortid.generate(),
  slug: "destination-category1-name",
  name: "Destination category1 name",
  alternateName: "Destination category1 alternate name",
  description: <Lorem seed="1" count="1" />,
  image: ImageResponsivePlaceholderCom169,
  icon: {
    alternateName: "Icon alternate name",
    url: "https://placeholder.pics/svg/320x180"
  },
  count: 10,
  attractions: Array(3).fill(AttractionDefaultProps),
  destinationName: "Destination Name"
};

export { propTypes, defaultProps };
