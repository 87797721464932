/**
 * Component short description
 *
 * @see Home.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Hero from "../Hero";
import About from "../About";
import CategoriesAsCarousel from "../../_category/CategoriesAsCarousel";
import OngInfo from "../OngInfo";
import Afir from "../Afir";
import LocalMarketIntro from "../../_localMarket/LocalMarketIntro";
import StayIntro from "../../_stay/StayIntro";
import NewsAsHero from "../../_news/NewsAsHero";
import Valuta from "../Valuta";
import Newsletter from "../../Newsletter";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Home.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Home.lang.ro-ro";
import { hu_hu } from "./Home.lang.hu-hu";
import { en_us } from "./Home.lang.en-us";
import { de_de } from "./Home.lang.de-de";

i18n.addResourceBundle("ro-RO", "Home", ro_ro);
i18n.addResourceBundle("hu-HU", "Home", hu_hu);
i18n.addResourceBundle("en-US", "Home", en_us);
i18n.addResourceBundle("de-DE", "Home", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  }
}));

/**
 * Displays the component
 */
const Home = props => {
  const { header } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("Home");

  return (
    <Layout header={{ currentPage: "Home" }}>
      <Grid container spacing={1} className={clsx(container, "Home")}>
        <Grid item xs={12}>
          <Hero />
          {/**/}
        </Grid>
        <Grid item xs={12}>
          <About />
          {/**/}
        </Grid>
        <Grid item xs={12}>
          <CategoriesAsCarousel />
          {/**/}
        </Grid>
        <Grid item xs={12}>
          <OngInfo />
          {/**/}
        </Grid>
        <Grid item xs={12}>
          <Afir />
          {/**/}
        </Grid>
        <Grid item xs={12}>
          <LocalMarketIntro />
          {/**/}
        </Grid>
        <Grid item xs={12}>
          <StayIntro />
          {/**/}
        </Grid>
        <Grid item xs={12}>
          <NewsAsHero />
          {/**/}
        </Grid>
        <Grid container spacing={1} className={clsx(container)}>
          <Grid item xs={12} sm={6}>
            <Newsletter />
            {/**/}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Valuta />
            {/**/}
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

Home.propTypes = propTypes;
Home.defaultProps = defaultProps;

export default Home;
export { propTypes as HomePropTypes, defaultProps as HomeDefaultProps };
