/**
 * Defines the data requirements for the component
 *
 * @see CategoriesAsCarousel.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import Lorem from "react-lorem-component";
import shortid from "shortid";

/**
 * Imports prop types from other components
 */
import {
  CategoriesAsTabsPropTypes,
  CategoriesAsTabsDefaultProps
} from "../CategoriesAsTabs";

import {
  AttractionAsThumbPropTypes,
  AttractionAsThumbDefaultProps
} from "../../_attraction/AttractionAsThumb";

/**
 * Defines the prop types
 */
const propTypes = {
  ...CategoriesAsTabsPropTypes,
  attractions: PropTypes.arrayOf(PropTypes.shape(AttractionAsThumbPropTypes))
};

/**
 * Defines the default props
 */
const defaultProps = {
  ...CategoriesAsTabsDefaultProps,
  attractions: Array(6)
    .fill(AttractionAsThumbDefaultProps)
    .map((item, index) => {
      return {
        ...item,
        id: shortid.generate(),
        name: `Attraction name ${index + 1}`,
        description: (
          <Lorem format="text" seed={index} count={1} sentenceUpperBound={2} />
        )
      };
    })
};

export { propTypes, defaultProps };
