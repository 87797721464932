const ro_ro = {
  MenuItems: "MenuItems (ro_ro)",
  Home: "Pagina principala",
  Destinations: "Destinatii",
  "Local Market": "Piata locala",
  Stay: "Acomodare",
  News: "Stiri",
  Contact: "Contact",
  FAQ: "intrebari",
  "Media & Press": "Media",
  Support: "Suport",
  "Privacy Policy": "Confidentialitate",
  "Terms & Conditions": "Termeni si conditii",
  Sitemap: "Harta site"
};

export { ro_ro };
