/**
 * Imports React and third party packages
 */
import useSWR from "swr";
import queryString from "query-string";

/**
 * Imports other components and hooks
 */
import { useRequests } from "../../../hooks";

const useMarketInfoSwr = () => {
  const { get } = useRequests();
  return useSWR("market/info", get);
};

const useMarketCategoriesSwr = () => {
  const { get } = useRequests();
  return useSWR("market/categories", get);
};

const useMarketManufacturersSwr = () => {
  const { get } = useRequests();
  return useSWR(`market/manufacturers`, get);
};

const useMarketProductsSwr = (filters) => {
  const { get } = useRequests();
  return useSWR("market/products", get);
};

const useMarketProductSwr = (id) => {
  const { get } = useRequests();
  return useSWR(`market/${id}`, get);
};

const useMarketCartSwr = () => {
  const { get } = useRequests();
  return useSWR("market/cart", get);
};

const useMarket = () => {
  const { post, deleteReq, put, get } = useRequests();

  const getProducts = (params) => {
    return get(`market/products`, params);
  };

  const createCart = (cart) => {
    return post("market/cart", cart);
  };

  const updateCart = (id, cart) => {
    return put(`market/cart/${id}`, cart);
  };

  const deleteCart = (id) => {
    return deleteReq(`market/cart/${id}`);
  };

  return {
    useMarketInfoSwr,
    useMarketCategoriesSwr,
    useMarketManufacturersSwr,
    useMarketProductSwr,
    useMarketProductsSwr,
    useMarketCartSwr,
    createCart,
    updateCart,
    deleteCart,
    getProducts,
  };
};

export default useMarket;
export {
  useMarketInfoSwr,
  useMarketCategoriesSwr,
  useMarketManufacturersSwr,
  useMarketProductSwr,
  useMarketProductsSwr,
  useMarketCartSwr,
};
