/**
 * The business logic for the component
 *
 * @see AccountRoles.md for details
 */

import shortid from "shortid";
import { has } from "lodash";

/**
 * Returns an array of Profile Roles and links
 * - The API returns only booleans which has to be transformed into menu items
 */
const getRoles = props => {
  const { isManufacturer, isOwner, isLocalAdmin, isSuperAdmin } = props;

  let roles = [
    {
      id: shortid.generate(),
      name: "Profile Role - Normal",
      path: "/account"
    }
  ];

  if (isManufacturer)
    roles.push({
      id: shortid.generate(),
      name: "Profile Role - Local market",
      url: "https://dashboardurl"
    });

  if (isOwner)
    roles.push({
      id: shortid.generate(),
      name: "Profile Role - Stay",
      url: "https://dashboardurl"
    });

  return roles;
};

/**
 * Returns an array of external Profile Roles and links
 */
const getExternalRoles = props => {
  const roles = getRoles(props);
  return roles.filter(item => has(item, "url"));
};

/**
 * Return the internal Profile Role
 */
const getInternalRole = props => {
  const roles = getRoles(props);
  return roles.filter(item => has(item, "path")).shift();
};

export { getRoles, getExternalRoles, getInternalRole };
