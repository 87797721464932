/**
 * Defines the data requirements for the component
 *
 * @see CategoriesAsThumbs.md for details
 */
import PropTypes from "prop-types";
import shortid from "shortid";

/**
 * Imports prop types from other components
 */
import {
  CategoryAsThumbPropTypes,
  CategoryAsThumbDefaultProps
} from "../CategoryAsThumb";

/**
 * Defines the prop types
 */
const propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape(CategoryAsThumbPropTypes))
};

/**
 * Defines the default props
 */
const defaultProps = {
  categories: Array(6)
    .fill(CategoryAsThumbDefaultProps)
    .map((item, index) => {
      const { image } = item;

      return {
        ...item,
        id: shortid.generate(),
        name: `Destination category ${index + 1} name`,
        slug: `destination-category-${index + 1}-name`,
        count: Math.round(Math.random(100) * 100),
        image: { ...image, displayCaptionPosition: "below" }
      };
    })
};

export { propTypes, defaultProps };
