/**
 * Component short description
 *
 * @see SearchResults.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState } from "react";
import clsx from "clsx";
import { Redirect, useParams, useLocation } from "react-router-dom";
import { TextSection } from "../../Theme";

/**
 * Imports other components and hooks
 */
import Card2, {
  Card2SingleRowStyles,
  Card2SingleRowStylesInverted
} from "../../Card2";
import DestinationAsThumb from "../../_destination/DestinationAsThumb";
import ProductAsThumb from "../../_product/ProductAsThumb";
import AccommodationAsThumb from "../../_accommodation/AccommodationAsThumb";
import AttractionAsThumb from "../../_attraction/AttractionAsThumb";
import ArticleAsThumb from "../../_article/ArticleAsThumb";
import EventAsThumb from "../../_event/EventAsThumb";
import TabPanel from "../../TabPanel";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./SearchResults.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./SearchResults.lang.ro-ro";
import { hu_hu } from "./SearchResults.lang.hu-hu";
import { en_us } from "./SearchResults.lang.en-us";
import { de_de } from "./SearchResults.lang.de-de";

i18n.addResourceBundle("ro-RO", "SearchResults", ro_ro);
i18n.addResourceBundle("hu-HU", "SearchResults", hu_hu);
i18n.addResourceBundle("en-US", "SearchResults", en_us);
i18n.addResourceBundle("de-DE", "SearchResults", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  },

  tabs: {
    marginBottom: theme.spacing(2)
  }
}));

/**
 * Displays the component
 */
const SearchResults = props => {
  const {
    destinations,
    products,
    accommodations,
    attractions,
    articles,
    events,
    tabLabels
  } = props;
  const { container, tabs: tabsClass } = useStyles(props);
  const { t } = useTranslation("SearchResults");

  /**
   * Manages the state of the active tab
   */
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  /**
   * Manages to click on tab labels
   */
  const handleTabClick = (event, newValue) => {
    setActiveTabIndex(newValue);
  };

  /**
   * Displays the no results
   */
  const cardContent1 = (
    <TextSection title={t("No results found. Please try another search.")} />
  );

  const cardWithMedia1 = {
    image: null,
    content: cardContent1,
    customStyles: useStyles(props)
  };

  const noResults =
    !destinations.length ||
    !products.length ||
    !accommodations.length ||
    !attractions.length ||
    !articles.length ||
    !events.length;

  if (noResults) {
    return (
      <Grid item xs={12}>
        <Card2 {...cardWithMedia1} />
      </Grid>
    );
  }

  /**
   * Displays the results
   */
  const destinationsList = destinations.map((item, index) => {
    const { id } = item;

    return (
      <Grid item key={id} xs={12} sm={6} md={4}>
        <DestinationAsThumb {...item} index={index} />
      </Grid>
    );
  });

  const attractionsList = attractions.map((item, index) => {
    const { id } = item;

    return (
      <Grid item key={id} xs={12} sm={6} md={4}>
        <AttractionAsThumb {...item} index={index} />
      </Grid>
    );
  });

  const eventsList = events.map((item, index) => {
    const { id } = item;

    return (
      <Grid item key={id} xs={12} sm={6} md={4}>
        <EventAsThumb {...item} index={index} />
      </Grid>
    );
  });

  const productsList = products.map((item, index) => {
    const { id } = item;

    return (
      <Grid item key={id} xs={12} sm={6} md={4}>
        <ProductAsThumb {...item} index={index} />
      </Grid>
    );
  });

  const accommodationsList = accommodations.map((item, index) => {
    const { id } = item;

    return (
      <Grid item key={id} xs={12} sm={6} md={4}>
        <AccommodationAsThumb {...item} index={index} />
      </Grid>
    );
  });

  const articlesList = articles.map((item, index) => {
    const { id } = item;

    return (
      <Grid item key={id} xs={12} sm={6} md={4}>
        <ArticleAsThumb {...item} index={index} />
      </Grid>
    );
  });

  /**
   * Displays the tab labels
   */
  const labels = tabLabels.map(item => {
    const { name, id } = item;
    return <Tab key={id} label={t(name)} />;
  });

  /**
   * Displays the tab panels
   */
  const panels = tabLabels.map((item, index) => {
    const { id, name } = item;

    let results = cardContent1;

    switch (name) {
      case "Destinations":
        results = (
          <>
            {destinationsList}
            {attractionsList}
          </>
        );
        break;
      case "Local market":
        results = <>{productsList}</>;
        break;
      case "Stay":
        results = <>{accommodationsList}</>;
        break;
      case "News":
        results = <>{articlesList}</>;
        break;
      case "Events":
        results = <>{eventsList}</>;
        break;
      case "All":
      default:
        results = (
          <>
            {destinationsList}
            {attractionsList}
            {productsList}
            {accommodationsList}
            {articlesList}
            {eventsList}
          </>
        );
    }

    return (
      <TabPanel
        currentTabIndex={index}
        activeTabIndex={activeTabIndex}
        key={id}
      >
        <Grid container spacing={1} className={clsx(container, "Panels")}>
          {results}
        </Grid>
      </TabPanel>
    );
  });

  return (
    <Grid container spacing={1} className={clsx(container, "SearchResults")}>
      <Grid item xs={12}>
        <Tabs
          value={activeTabIndex}
          onChange={handleTabClick}
          variant="scrollable"
          scrollButtons="auto"
          className={clsx(tabsClass, "Tabs")}
        >
          {labels}
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        {panels}
      </Grid>
    </Grid>
  );
};

SearchResults.propTypes = propTypes;
SearchResults.defaultProps = defaultProps;

export default SearchResults;
export {
  propTypes as SearchResultsPropTypes,
  defaultProps as SearchResultsDefaultProps
};
