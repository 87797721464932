/**
 * Component short description
 *
 * @see NewsAsHero.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import shortid from "shortid";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import { TextSection } from "../../Theme";

/**
 * Imports other components and hooks
 */
import Card2, {
  Card2LocalMarketAsHeroStyles,
  Card2LocalMarketAsHeroStylesInverted
} from "../../Card2";
import Carousel from "../../Carousel";
import ArticleAsThumb from "../../_article/ArticleAsThumb";
import AttractionAsThumb from "../../_attraction/AttractionAsThumb";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./NewsAsHero.data";

/**
 * Imports Material UI components
 */
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./NewsAsHero.lang.ro-ro";
import { hu_hu } from "./NewsAsHero.lang.hu-hu";
import { en_us } from "./NewsAsHero.lang.en-us";
import { de_de } from "./NewsAsHero.lang.de-de";

i18n.addResourceBundle("ro-RO", "NewsAsHero", ro_ro);
i18n.addResourceBundle("hu-HU", "NewsAsHero", hu_hu);
i18n.addResourceBundle("en-US", "NewsAsHero", en_us);
i18n.addResourceBundle("de-DE", "NewsAsHero", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  }
}));

/**
 * Displays the component
 */
const NewsAsHero = props => {
  const { description, articles } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("NewsAsHero");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Loads the current route
   */
  const match = useRouteMatch();
  const { url } = match;

  /**
   * Prepares the card
   */
  const cardContent = (
    <TextSection title={t("News")} description={description} />
  );

  const cardActions = (
    <Button variant="outlined" component={RouterLink} to={`${url}/news`}>
      {t("View more")}
    </Button>
  );

  const carouselSteps = isMobile
    ? articles.map((item, index) => {
        return {
          id: shortid.generate(),
          content: <ArticleAsThumb {...item} index={index} />
        };
      })
    : isTablet
    ? articles
        .map((item, index) => {
          return index % 2 === 0
            ? {
                id: shortid.generate(),
                content: (
                  <Grid
                    container
                    className={clsx(container)}
                    wrap="nowrap"
                    justify="space-around"
                    spacing={1}
                  >
                    <Grid item xs={6}>
                      <ArticleAsThumb {...item} index={index} />
                    </Grid>
                    {articles[index + 1] && (
                      <Grid item xs={6}>
                        <ArticleAsThumb
                          {...articles[index + 1]}
                          index={index + 3}
                        />
                      </Grid>
                    )}
                  </Grid>
                )
              }
            : null;
        })
        .filter(item => item)
    : articles
        .map((item, index) => {
          return index % 3 === 0
            ? {
                id: shortid.generate(),
                content: (
                  <Grid
                    container
                    className={clsx(container)}
                    wrap="nowrap"
                    justify="space-around"
                    spacing={1}
                  >
                    <Grid item xs={4}>
                      <ArticleAsThumb {...item} index={index} />
                    </Grid>
                    {articles[index + 1] && (
                      <Grid item xs={4}>
                        <ArticleAsThumb
                          {...articles[index + 1]}
                          index={index + 3}
                        />
                      </Grid>
                    )}
                    {articles[index + 2] && (
                      <Grid item xs={4}>
                        <ArticleAsThumb
                          {...articles[index + 2]}
                          index={index + 4}
                        />
                      </Grid>
                    )}
                  </Grid>
                )
              }
            : null;
        })
        .filter(item => item);

  const cardWithMedia = {
    image: null,
    carousel: (
      <Carousel steps={carouselSteps} isMobileStepperAlignedRight={true} />
    ),
    content: cardContent,
    actions: cardActions,
    customStyles: Card2LocalMarketAsHeroStyles({
      ...props,
      breakpoint: "xxl",
      imageWidth: "50%"
    })
  };

  return <Card2 {...cardWithMedia} />;
};

NewsAsHero.propTypes = propTypes;
NewsAsHero.defaultProps = defaultProps;

export default NewsAsHero;
export {
  propTypes as NewsAsHeroPropTypes,
  defaultProps as NewsAsHeroDefaultProps
};
