const en_us = {
  ContactForm: "ContactForm",
  contact_submitted: "Contact submitted",
  destination: "Destination",
  name: "Your name",
  email: "Email address",
  message: "Message",
  terms: "Terms and Conditions",
};

export { en_us };
