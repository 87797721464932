/**
 * Defines the data requirements for the component
 *
 * @see Destination.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import Lorem from "react-lorem-component";

/**
 * Imports prop types from other components
 */
import {
  ImageResponsivePropTypes,
  ImageResponsiveDefaultProps,
  ImageResponsivePlaceholderCom169
} from "../../ImageResponsive";

import {
  CategoriesAsThumbsPropTypes,
  CategoriesAsThumbsDefaultProps
} from "../../_category/CategoriesAsThumbs";

import {
  LocalMarketAsHeroPropTypes,
  LocalMarketAsHeroDefaultProps
} from "../../_localMarket/LocalMarketAsHero";

import {
  StayAsHeroPropTypes,
  StayAsHeroDefaultProps
} from "../../_stay/StayAsHero";

import { IconPropTypes, IconDefaultProps } from "../../Icon";
import { VideosPropTypes, VideosDefaultProps } from "../../_videos/Videos";
import { GpsPropTypes, GpsDefaultProps } from "../../Gps";

import YouTubeIcon from "@material-ui/icons/YouTube";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DepartureBoardIcon from "@material-ui/icons/DepartureBoard";
import CameraIcon from "@material-ui/icons/Camera";

/**
 * Defines the prop types
 *
 * @see http://api.borsecturismregional.web-staging.eu/apidoc/#/Destinations/getDestinations
 * @see http://api.borsecturismregional.web-staging.eu/apidoc/#/Destinations/getDestination
 */
const propTypes = {
  /**
   * From API
   */
  id: PropTypes.string,
  slug: PropTypes.string,
  name: PropTypes.string,
  alternateName: PropTypes.string,
  description: PropTypes.string,
  longDescription: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.shape(ImageResponsivePropTypes)),
  videos: PropTypes.shape(VideosPropTypes),
  categoriesDescription: PropTypes.string,
  ...CategoriesAsThumbsPropTypes,
  ...LocalMarketAsHeroPropTypes,
  ...StayAsHeroPropTypes,
  /**
   * Other props
   */
  gps: PropTypes.shape(GpsPropTypes),
  videoIcon: PropTypes.shape(IconPropTypes),
  pdfIcon: PropTypes.shape(IconPropTypes),
  transportationIcon: PropTypes.shape(IconPropTypes),
  webcamIcon: PropTypes.shape(IconPropTypes),
  widget: PropTypes.any
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: shortid.generate(),
  slug: "destination1-name",
  name: "Destination name",
  alternateName: "Destination1 alternate name",
  description: <Lorem seed="2" count="1" sentenceUpperBound="5" />,
  longDescription: <Lorem seed="2" count="1" sentenceUpperBound="10" />,
  latitude: 44.44444,
  longitude: 23.123456,
  images: Array(3).fill(ImageResponsivePlaceholderCom169),
  videos: VideosDefaultProps,
  categoriesDescription: <Lorem seed="4" count="1" sentenceUpperBound="5" />,
  ...CategoriesAsThumbsDefaultProps,
  ...LocalMarketAsHeroDefaultProps,
  ...StayAsHeroDefaultProps,
  gps: GpsDefaultProps,
  videoIcon: {
    icon: <YouTubeIcon />
  },
  pdfIcon: {
    icon: <PictureAsPdfIcon />
  },
  transportationIcon: {
    icon: <DepartureBoardIcon />
  },
  webcamIcon: {
    icon: <CameraIcon />
  },
  widget: null
};

export { propTypes, defaultProps };
