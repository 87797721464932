/**
 * Displays the list of destinations
 *
 * @see DestinationsList.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import DestinationAsHero from "../../_destination/DestinationAsHero";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./DestinationsList.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./DestinationsList.lang.ro-ro";
import { hu_hu } from "./DestinationsList.lang.hu-hu";
import { en_us } from "./DestinationsList.lang.en-us";
import { de_de } from "./DestinationsList.lang.de-de";

i18n.addResourceBundle("ro-RO", "DestinationsList", ro_ro);
i18n.addResourceBundle("hu-HU", "DestinationsList", hu_hu);
i18n.addResourceBundle("en-US", "DestinationsList", en_us);
i18n.addResourceBundle("de-DE", "DestinationsList", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  },
  destinationsListItem: {
    marginTop: theme.spacing(4)
  }
}));

/**
 * Displays the component
 */
const DestinationsList = props => {
  const { destinations } = props;
  const { container, destinationsListItem } = useStyles(props);
  const { t } = useTranslation("DestinationsList");

  const list = destinations.map((item, index) => {
    const { id } = item;

    return (
      <Grid
        item
        key={id}
        xs={12}
        className={clsx(destinationsListItem, "DestinationsListItem")}
      >
        <DestinationAsHero {...item} index={index} />
      </Grid>
    );
  });

  return (
    <Grid container className={clsx(container, "DestinationsList")}>
      {list}
    </Grid>
  );
};

DestinationsList.propTypes = propTypes;
DestinationsList.defaultProps = defaultProps;

export default DestinationsList;
export {
  propTypes as DestinationsListPropTypes,
  defaultProps as DestinationsListDefaultProps
};
