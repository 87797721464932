/**
 * Defines the data requirements for the component
 *
 * @see SearchForm.md for details
 */
import PropTypes from "prop-types";
import shortid from "shortid";

/**
 * Imports prop types from other components
 */
import {
  CategoryPropTypes,
  CategoryDefaultProps
} from "../../_category/Category";

/**
 * Defines the prop types
 */
const propTypes = {
  text: PropTypes.string,
  category: PropTypes.shape(CategoryPropTypes),
  categories: PropTypes.arrayOf(CategoryPropTypes)
};

/**
 * Defines the default props
 */
const defaultProps = {
  text: null,
  category: {},
  categories: Array(3)
    .fill(CategoryPropTypes)
    .map((item, index) => {
      return {
        ...item,
        id: shortid.generate(),
        name: `Category ${index + 1}`
      };
    })
};

export { propTypes, defaultProps };
