/**
 * Component short description
 *
 * @see RegisterDialog.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import RegisterForm from "../RegisterForm/RegisterForm";
import Dialog2 from "../../Dialog2";
import { useSnackbar } from "notistack";
import { useAuthRequests } from "../Auth/Auth.logic";
/**
 * Imports data
 */
import { propTypes, defaultProps } from "./RegisterDialog.data";

/**
 * Imports Material UI components
 */
import {
  DialogContent,
  Typography,
  Grid,
  Link,
  makeStyles
} from "@material-ui/core";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./RegisterDialog.lang.ro-ro";
import { hu_hu } from "./RegisterDialog.lang.hu-hu";
import { en_us } from "./RegisterDialog.lang.en-us";
import { de_de } from "./RegisterDialog.lang.de-de";

i18n.addResourceBundle("ro-RO", "RegisterDialog", ro_ro);
i18n.addResourceBundle("hu-HU", "RegisterDialog", hu_hu);
i18n.addResourceBundle("en-US", "RegisterDialog", en_us);
i18n.addResourceBundle("de-DE", "RegisterDialog", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: { ...theme.custom.grid.removeNegativeMargin }
}));

/**
 * Displays the component
 */
const RegisterDialog = props => {
  const { dialog, registerForm, openDialog } = props;
  const { onClose, open } = dialog;

  const { t } = useTranslation("RegisterDialog");
  const { enqueueSnackbar } = useSnackbar();
  const { register } = useAuthRequests();

  const handleSubmit = async data => {
    try {
      await register(data);
      enqueueSnackbar(t("register_success"), { variant: "success" });
      openDialog("login");
    } catch (e) {
      enqueueSnackbar(t("register_fail"), { variant: "error" });
    }
  };

  return (
    <Dialog2
      open={open}
      onClose={onClose}
      id="register-dialog"
      maxWidth="sm"
      width="auto"
      height="auto"
      title={t("register")}
      content=<RegisterForm
        {...registerForm}
        onSubmit={handleSubmit}
        openDialog={openDialog}
      />
    />
  );
};

RegisterDialog.propTypes = propTypes;
RegisterDialog.defaultProps = defaultProps;

export default RegisterDialog;
export {
  propTypes as RegisterDialogPropTypes,
  defaultProps as RegisterDialogDefaultProps
};
