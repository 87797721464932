/**
 * Component short description
 *
 * @see ForgotPassDialog.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Dialog2 from "../../Dialog2";
import ForgotPassForm from "../ForgotPassForm";
import { useSnackbar } from "notistack";
import { useAuthRequests } from "../Auth/Auth.logic";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./ForgotPassDialog.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./ForgotPassDialog.lang.ro-ro";
import { hu_hu } from "./ForgotPassDialog.lang.hu-hu";
import { en_us } from "./ForgotPassDialog.lang.en-us";
import { de_de } from "./ForgotPassDialog.lang.de-de";

i18n.addResourceBundle("ro-RO", "ForgotPassDialog", ro_ro);
i18n.addResourceBundle("hu-HU", "ForgotPassDialog", hu_hu);
i18n.addResourceBundle("en-US", "ForgotPassDialog", en_us);
i18n.addResourceBundle("de-DE", "ForgotPassDialog", de_de);

/**
 * Styles the component
 */

const useStyles = makeStyles(theme => ({}));

/**
 * Displays the component
 */
const ForgotPassDialog = props => {
  const { dialog, forgotPassForm, openDialog } = props;
  const { onClose, open } = dialog;
  const { container } = useStyles();

  const { t } = useTranslation("ForgotPassDialog");

  const { enqueueSnackbar } = useSnackbar();
  const { forgotPass } = useAuthRequests();

  const handleSubmit = data => {
    forgotPass(data)
      .then(() => {
        enqueueSnackbar(t("reset_success"), { variant: "success" });
        openDialog("login");
      })
      .catch(() => {
        enqueueSnackbar(t("reset_fail"), { variant: "error" });
      });
  };

  return (
    <Dialog2
      open={open}
      onClose={onClose}
      id="forgot-password-dialog"
      maxWidth="sm"
      width="auto"
      height="auto"
      title={t("title")}
      content=<ForgotPassForm
        {...forgotPassForm}
        onSubmit={handleSubmit}
        openDialog={openDialog}
      />
    />
  );
};

ForgotPassDialog.propTypes = propTypes;
ForgotPassDialog.defaultProps = defaultProps;

export default ForgotPassDialog;
export {
  propTypes as ForgotPassDialogPropTypes,
  defaultProps as ForgotPassDialogDefaultProps
};
