/**
 * Defines the data requirements for the component
 *
 * @see ArticleAsThumb.md for details
 */

import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { ArticlePropTypes, ArticleDefaultProps } from "../Article";
import { ImageResponsivePlaceholderCom169 } from "../../ImageResponsive";

/**
 * Defines the prop types
 */
const propTypes = {
  ...ArticlePropTypes
};

/**
 * Defines the default props
 */
const defaultProps = {
  ...ArticleDefaultProps,
  image: {
    ...ImageResponsivePlaceholderCom169
  }
};

export { propTypes, defaultProps };
