/**
 * Displays an Article as a thumb
 *
 * @see ArticleAsThumb.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import Card2 from "../../Card2";
import { useUnsplash } from "../../../hooks";
import { TextThumbnail } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./ArticleAsThumb.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./ArticleAsThumb.lang.ro-ro";
import { hu_hu } from "./ArticleAsThumb.lang.hu-hu";
import { en_us } from "./ArticleAsThumb.lang.en-us";
import { de_de } from "./ArticleAsThumb.lang.de-de";

i18n.addResourceBundle("ro-RO", "ArticleAsThumb", ro_ro);
i18n.addResourceBundle("hu-HU", "ArticleAsThumb", hu_hu);
i18n.addResourceBundle("en-US", "ArticleAsThumb", en_us);
i18n.addResourceBundle("de-DE", "ArticleAsThumb", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: { ...theme.custom.grid.removeNegativeMargin }
}));

/**
 * Displays the component
 */
const ArticleAsThumb = props => {
  const { name, slug, image, destination, index } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("ArticleAsThumb");

  /**
   * Loads unsplash images
   */
  const unsplashImages = useUnsplash({
    keyword: "News",
    page: index + 1,
    photos: 1,
    params: { orientation: "landscape" }
  });

  /**
   * Loads the current route
   */
  const match = useRouteMatch();
  const { url } = match;

  /**
   * Prepares the card
   */
  const cardContent = (
    <TextThumbnail overline={destination} to={`${url}/${slug}`} title={name} />
  );

  const cardWithMedia = {
    image: unsplashImages ? unsplashImages[0] : image,
    images: null,
    content: cardContent,
    customStyles: useStyles(props)
  };

  return <Card2 {...cardWithMedia} />;
};

ArticleAsThumb.propTypes = propTypes;
ArticleAsThumb.defaultProps = defaultProps;

export default ArticleAsThumb;
export {
  propTypes as ArticleAsThumbPropTypes,
  defaultProps as ArticleAsThumbDefaultProps
};
