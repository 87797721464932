/**
 * Component short description
 *
 * @see Questions.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Question from "../Question";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Questions.data";

/**
 * Imports Material UI components
 */
import { makeStyles, List, ListItem } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Questions.lang.ro-ro";
import { hu_hu } from "./Questions.lang.hu-hu";
import { en_us } from "./Questions.lang.en-us";
import { de_de } from "./Questions.lang.de-de";

i18n.addResourceBundle("ro-RO", "Questions", ro_ro);
i18n.addResourceBundle("hu-HU", "Questions", hu_hu);
i18n.addResourceBundle("en-US", "Questions", en_us);
i18n.addResourceBundle("de-DE", "Questions", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({}));

/**
 * Displays the component
 */
const Questions = props => {
  const { list } = props;
  const { t } = useTranslation("Questions");

  const questions = list.map(item => {
    const { id } = item;

    return (
      <ListItem id={id} key={id} disableGutters>
        <Question {...item} />
      </ListItem>
    );
  });

  return <List disablePadding>{questions}</List>;
};

Questions.propTypes = propTypes;
Questions.defaultProps = defaultProps;

export default Questions;
export {
  propTypes as QuestionsPropTypes,
  defaultProps as QuestionsDefaultProps
};
