/**
 * Defines the data requirements for the component
 *
 * @see Videos.md for details
 */
import PropTypes from "prop-types";
import shortid from "shortid";

/**
 * Imports prop types from other components
 */
import {
  VideoAsThumbPropTypes,
  VideoAsThumbDefaultProps
} from "../VideoAsThumb";

/**
 * Defines the prop types
 *
 * @see http://api.borsecturismregional.web-staging.eu/apidoc/#/Destinations/getDestination
 */
const propTypes = {
  /**
   * From API
   */
  items: PropTypes.arrayOf(PropTypes.shape(VideoAsThumbPropTypes)),
  /**
   * From parents
   */
  destinationName: PropTypes.string
};

/**
 * Defines the default props
 */
const defaultProps = {
  items: Array(6)
    .fill(VideoAsThumbDefaultProps)
    .map((item, index) => {
      return {
        ...item,
        id: shortid.generate(),
        name: `Video ${index + 1}`
      };
    }),
  destinationName: "Destination name"
};

export { propTypes, defaultProps };
