/**
 * Component short description
 *
 * @see About.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import SearchForm from "../../_search/SearchForm";
import Card2, {
  Card2SingleRowStyles,
  Card2SingleRowStylesInverted
} from "../../Card2";
import { TextLarge, TextHeadline } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./About.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./About.lang.ro-ro";
import { hu_hu } from "./About.lang.hu-hu";
import { en_us } from "./About.lang.en-us";
import { de_de } from "./About.lang.de-de";

i18n.addResourceBundle("ro-RO", "About", ro_ro);
i18n.addResourceBundle("hu-HU", "About", hu_hu);
i18n.addResourceBundle("en-US", "About", en_us);
i18n.addResourceBundle("de-DE", "About", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({}));

/**
 * Displays the component
 */
const About = props => {
  const { headline, description, searchForm } = props;
  const { t } = useTranslation("About");

  /**
   * Displays the card
   */
  const cardContent1 = <TextLarge text={description} />;

  const cardActions1 = <SearchForm {...searchForm} />;

  const cardWithMedia1 = {
    klassName: "About",
    image: null,
    widget: <TextHeadline text={t(headline)} />,
    content: cardContent1,
    actions: cardActions1,
    customStyles: Card2SingleRowStyles({ ...props, breakpoint: "md" }),
    cardContainerProps: { alignItems: "center" }
  };

  return <Card2 {...cardWithMedia1} />;
};

About.propTypes = propTypes;
About.defaultProps = defaultProps;

export default About;
export { propTypes as AboutPropTypes, defaultProps as AboutDefaultProps };
