/**
 * Component short description
 *
 * @see Faq.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect, useState } from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Questions from "../Questions";
import Card2, {
  Card2SingleRowStyles,
  Card2SingleRowStylesInverted
} from "../../Card2";
import { TextSection, PageHeader } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Faq.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Faq.lang.ro-ro";
import { hu_hu } from "./Faq.lang.hu-hu";
import { en_us } from "./Faq.lang.en-us";
import { de_de } from "./Faq.lang.de-de";
import useFaq from "../../../hooks/api-hooks/useFaq";

i18n.addResourceBundle("ro-RO", "Faq", ro_ro);
i18n.addResourceBundle("hu-HU", "Faq", hu_hu);
i18n.addResourceBundle("en-US", "Faq", en_us);
i18n.addResourceBundle("de-DE", "Faq", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
  text: { maxWidth: theme.custom.typography.maxTextWidth }
}));

/**
 * Displays the component
 */
const Faq = props => {
  const { description, questions } = props;
  const { container, text } = useStyles(props);
  const { t, i18n } = useTranslation("Faq");

  const { language } = i18n;

  const { useFaqSwr } = useFaq();
  const { data, mutate } = useFaqSwr();

  useEffect(() => {
    mutate();
  }, [language, mutate]);

  const cardContent1 = <TextSection description={description} />;

  const cardWithMedia1 = {
    image: null,
    content: cardContent1,
    actions: <Questions {...questions} />
  };

  return (
    <Layout footer={{ currentPage: "FAQ" }}>
      <Grid container spacing={1} className={clsx(container, "FAQ")}>
        <Grid item xs={12}>
          <PageHeader title={t("FAQ")} />
        </Grid>
        <Grid item xs={12}>
          <Card2 {...cardWithMedia1} />
        </Grid>
      </Grid>
    </Layout>
  );
};

Faq.propTypes = propTypes;
Faq.defaultProps = defaultProps;

export default Faq;
export { propTypes as FaqPropTypes, defaultProps as FaqDefaultProps };
