/**
 * Component short description
 *
 * @see useUnsplash.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState, useEffect } from "react";
import Unsplash from "unsplash-js";

/**
 * Imports other components and hooks
 */
import { convertImageFormatFromUnsplash } from "../../components/ImageResponsive";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./useUnsplash.data";

/**
 * Sets up Unsplash
 */
const unsplash = new Unsplash({
  accessKey: "TKUw2zcnfJ61DmFT4CBB2CYLIb8O7Z1OwcZUULx--Fk"
});

/**
 * Displays the component
 */
const useUnsplash = props => {
  const { keyword, page, photos, params, originalImageProps } = props;
  const { orientation } = params;

  const [images, setImages] = useState();

  useEffect(() => {
    const fetch = async () => {
      const response = await unsplash.search.photos(
        keyword,
        page,
        photos,
        params
      );
      const { results } = await response.json();

      setImages(
        results.map(item =>
          convertImageFormatFromUnsplash({
            data: item,
            orientation: orientation,
            originalImageProps: originalImageProps
          })
        )
      );
    };

    fetch();
  }, []);

  return images;
};

useUnsplash.propTypes = propTypes;
useUnsplash.defaultProps = defaultProps;

export default useUnsplash;
export {
  propTypes as useUnsplashPropTypes,
  defaultProps as useUnsplashDefaultProps
};
