/**
 * Component short description
 *
 * @see FilterSeller.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import FilterListItem from "../../FilterListItem/FilterListItem";
import { useFilters } from "../../../hooks";
import {
  getSelectedArrayValues,
  getArrayOfKeys,
} from "../../../hooks/useFilters";
import FilterChips from "../../FilterChips/FilterChips";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./FilterSeller.data";

/**
 * Imports Material UI components
 */
import { ListItemText, TextField } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./FilterSeller.lang.ro-ro";
import { hu_hu } from "./FilterSeller.lang.hu-hu";
import { en_us } from "./FilterSeller.lang.en-us";
import { de_de } from "./FilterSeller.lang.de-de";

i18n.addResourceBundle("ro-RO", "FilterSeller", ro_ro);
i18n.addResourceBundle("hu-HU", "FilterSeller", hu_hu);
i18n.addResourceBundle("en-US", "FilterSeller", en_us);
i18n.addResourceBundle("de-DE", "FilterSeller", de_de);

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const FilterSeller = (props) => {
  const { filterListItem, defaultValue } = props;
  const { t } = useTranslation("FilterSeller");

  /**
   * Handles the filters
   */
  const { filters, setFilters, data } = useFilters();
  const { sellers } = data;

  const selected = getSelectedArrayValues(
    "id",
    filters.seller?.filterValue || defaultValue,
    sellers
  );

  const selectHandler = (_event, value) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        manufacturer: {
          filterValue: getArrayOfKeys("id", value),
          label: getArrayOfKeys("name", value),
        },
      };
    });
  };

  return (
    <FilterListItem {...filterListItem} primary={t("name")}>
      <Autocomplete
        multiple
        name="seller"
        filterSelectedOptions
        getOptionLabel={(option) => option.name}
        options={sellers}
        // open={open}
        // onOpen={handleOpenSelect}
        // onClose={handleCloseSelect}
        closeIcon={null}
        value={selected}
        onChange={selectHandler}
        fullWidth
        renderInput={(params) => (
          <TextField {...params} variant="standard" label={t("placeholder")} />
        )} // renderValue={(selected) => (
        //   <FilterChips selected={selected} emptyText={t("placeholder")} />
        // )}
      />
    </FilterListItem>
  );
};

FilterSeller.propTypes = propTypes;
FilterSeller.defaultProps = defaultProps;

export default FilterSeller;
export {
  propTypes as FilterSellerPropTypes,
  defaultProps as FilterSellerDefaultProps,
};
