/**
 * Displays a category
 *
 * @see Category.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import { Switch, Route, useRouteMatch } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Breadcrumbs from "../../Breadcrumbs";
import AttractionsList from "../../_attraction/AttractionsList";
import Attraction from "../../_attraction/Attraction";
import { PageHeader } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Category.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Category.lang.ro-ro";
import { hu_hu } from "./Category.lang.hu-hu";
import { en_us } from "./Category.lang.en-us";
import { de_de } from "./Category.lang.de-de";

i18n.addResourceBundle("ro-RO", "Category", ro_ro);
i18n.addResourceBundle("hu-HU", "Category", hu_hu);
i18n.addResourceBundle("en-US", "Category", en_us);
i18n.addResourceBundle("de-DE", "Category", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: { ...theme.custom.grid.removeNegativeMargin }
}));

/**
 * Displays the component
 */
const Category = props => {
  const {
    name,
    alternateName,
    description,
    image,
    icon,
    count,
    destinationName
  } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("Category");

  /**
   * Routes to an attraction
   */
  const match = useRouteMatch();
  const { path } = match;

  return (
    <Switch>
      <Route path={`${path}/:slug`}>
        <Attraction destinationName={name} />
      </Route>
      <Route path={path}>
        <Layout header={{ currentPage: "Destinations" }}>
          <Grid container spacing={1} className={clsx(container, "Category")}>
            <Grid item xs={12}>
              <PageHeader
                breadcrumbs=<Breadcrumbs />
                overline={destinationName}
                title={name}
              />
            </Grid>
            <Grid item xs={12}>
              <AttractionsList />
            </Grid>
          </Grid>
        </Layout>
      </Route>
    </Switch>
  );
};

Category.propTypes = propTypes;
Category.defaultProps = defaultProps;

export default Category;
export { propTypes as CategoryPropTypes, defaultProps as CategoryDefaultProps };
