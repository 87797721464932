/**
 * Defines the data requirements for the component
 *
 * @see Question.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import Lorem from "react-lorem-component";

/**
 * Defines the prop types
 */
const propTypes = {
  id: PropTypes.string,
  question: PropTypes.string,
  answer: PropTypes.string
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: shortid.generate(),
  question: "Question 1",
  answer: <Lorem seed="0" count="1" sentenceUpperBound="14" />
};

export { propTypes, defaultProps };
