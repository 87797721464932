/**
 * Defines the data requirements for the component
 *
 * @see Footer.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";

/**
 * Imports prop types from other components
 */
import { LogoPropTypes, LogoDefaultProps } from "../Logo";
import { MenuPropTypes } from "../Menu";
import { IconsPropTypes } from "../Icons";

import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";

/**
 * Defines the prop types
 */
const propTypes = {
  logo: PropTypes.shape(LogoPropTypes),
  menu: PropTypes.shape(MenuPropTypes),
  icons: PropTypes.shape(IconsPropTypes),
  currentPage: PropTypes.string
};

/**
 * Defines the default props
 */
const defaultProps = {
  logo: LogoDefaultProps,
  menu: {
    items: [
      { id: shortid.generate(), name: "FAQ" },
      { id: shortid.generate(), name: "Media & Press" },
      { id: shortid.generate(), name: "Support" },
      { id: shortid.generate(), name: "Privacy Policy" },
      { id: shortid.generate(), name: "Terms & Conditions" },
      { id: shortid.generate(), name: "Sitemap" }
    ],
    activeItem: ""
  },
  icons: {
    items: [
      {
        id: shortid.generate(),
        icon: <FacebookIcon />,
        url: "https://facebook.com"
      },
      {
        id: shortid.generate(),
        icon: <InstagramIcon />,
        url: "https://instagram.com"
      },
      {
        id: shortid.generate(),
        icon: <TwitterIcon />,
        url: "https://twitter.com"
      },
      {
        id: shortid.generate(),
        icon: <YouTubeIcon />,
        url: "https://youtube.com"
      }
    ]
  },
  currentPage: ""
};

export { propTypes, defaultProps };
