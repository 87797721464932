/**
 * Defines the data requirements for the component
 *
 * @see ContactForm.md for details
 */
import PropTypes from "prop-types";
import {
  DestinationPropTypes,
  DestinationDefaultProps
} from "../../_destination/Destination";

/**
 * Defines the prop types
 */
const propTypes = {
  destinations: PropTypes.arrayOf(PropTypes.shape(DestinationPropTypes)),
  defaultValues: PropTypes.any,
  onSubmit: PropTypes.func
};

/**
 * Defines the default props
 */
const defaultProps = {
  destinations: Array(5).fill(DestinationDefaultProps),
  //// NOTE: THIS IS VERY VERY DIRTY ... Balazs has to refactor it
  defaultValues: {
    destination: "",
    name: "",
    email: "",
    message: "",
    terms: false
  },
  onSubmit: () => {}
};

export { propTypes, defaultProps };
