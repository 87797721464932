/**
 * Defines the data requirements for the component
 *
 * @see SearchResults.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import Lorem from "react-lorem-component";

/**
 * Imports prop types from other components
 */
import {
  DestinationAsThumbPropTypes,
  DestinationAsThumbDefaultProps
} from "../../_destination/DestinationAsThumb";
import {
  ProductAsThumbPropTypes,
  ProductAsThumbDefaultProps
} from "../../_product/ProductAsThumb";
import {
  AccommodationAsThumbPropTypes,
  AccommodationAsThumbDefaultProps
} from "../../_accommodation/AccommodationAsThumb";
import {
  AttractionAsThumbPropTypes,
  AttractionAsThumbDefaultProps
} from "../../_attraction/AttractionAsThumb";
import {
  ArticleAsThumbPropTypes,
  ArticleAsThumbDefaultProps
} from "../../_article/ArticleAsThumb";
import {
  EventAsThumbPropTypes,
  EventAsThumbDefaultProps
} from "../../_event/EventAsThumb";
import { TabPanelPropTypes, TabPanelDefaultProps } from "../../TabPanel";

/**
 * Defines the prop types
 */
const propTypes = {
  destinations: PropTypes.arrayOf(PropTypes.shape(DestinationAsThumbPropTypes)),
  products: PropTypes.arrayOf(PropTypes.shape(ProductAsThumbPropTypes)),
  accommodations: PropTypes.arrayOf(
    PropTypes.shape(AccommodationAsThumbPropTypes)
  ),
  attractions: PropTypes.arrayOf(PropTypes.shape(AttractionAsThumbPropTypes)),
  articles: PropTypes.arrayOf(PropTypes.shape(ArticleAsThumbPropTypes)),
  events: PropTypes.arrayOf(PropTypes.shape(EventAsThumbPropTypes)),
  tabPanel: PropTypes.shape(TabPanelPropTypes),
  tabLabels: PropTypes.arrayOf(PropTypes.string)
};

/**
 * Defines the default props
 */
const defaultProps = {
  destinations: Array(3)
    .fill(DestinationAsThumbDefaultProps)
    .map((item, index) => {
      return {
        ...item,
        id: shortid.generate(),
        name: `Destination name ${index + 1}`,
        description: <Lorem seed={index + 1} count="1" sentenceUpperBound="3" />
      };
    }),
  attractions: Array(3)
    .fill(AttractionAsThumbDefaultProps)
    .map((item, index) => {
      return {
        ...item,
        id: shortid.generate(),
        name: `Attraction name ${index + 1}`,
        description: <Lorem seed={index + 1} count="1" sentenceUpperBound="3" />
      };
    }),
  products: Array(3)
    .fill(ProductAsThumbDefaultProps)
    .map((item, index) => {
      return {
        ...item,
        id: shortid.generate(),
        name: `Product name ${index + 1}`,
        destinationName: `Destination name ${index + 1}`,
        price: Math.floor(Math.random() * Math.floor(100)) + 1
      };
    }),
  accommodations: Array(3)
    .fill(AccommodationAsThumbDefaultProps)
    .map((item, index) => {
      return {
        ...item,
        id: shortid.generate(),
        name: `Accommodation name ${index + 1}`,
        destinationName: `Destination name ${index + 1}`,
        price: Math.floor(Math.random() * Math.floor(100)) + 1
      };
    }),
  articles: Array(3)
    .fill(ArticleAsThumbDefaultProps)
    .map((item, index) => {
      return {
        ...item,
        id: shortid.generate(),
        name: `Article name ${index + 1}`,
        destinationName: `Destination name ${index + 1}`
      };
    }),
  events: Array(3)
    .fill(EventAsThumbDefaultProps)
    .map((item, index) => {
      return {
        ...item,
        id: shortid.generate(),
        name: `Event name ${index + 1}`
      };
    }),
  tabPanel: TabPanelDefaultProps,
  tabLabels: [
    { id: shortid.generate(), name: "All" },
    { id: shortid.generate(), name: "Destinations" },
    { id: shortid.generate(), name: "Local market" },
    { id: shortid.generate(), name: "Stay" },
    { id: shortid.generate(), name: "News" },
    { id: shortid.generate(), name: "Events" }
  ]
};

export { propTypes, defaultProps };
