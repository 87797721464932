/**
 * Defines the data requirements for the component
 *
 * @see RequestRole.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import Lorem from "react-lorem-component";

/**
 * Imports prop types from other components
 */
import {
  RequestRoleFormPropTypes,
  RequestRoleFormDefaultProps
} from "../RequestRoleForm";

/**
 * Defines the prop types
 */
const propTypes = {
  description: PropTypes.string,
  ...RequestRoleFormPropTypes
};

/**
 * Defines the default props
 */
const defaultProps = {
  description: <Lorem seed="2" count="1" sentenceUpperBound="5" />,
  ...RequestRoleFormDefaultProps
};

export { propTypes, defaultProps };
