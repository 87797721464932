/**
 * Component short description
 *
 * @see Accommodation.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import shortid from "shortid";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Breadcrumbs from "../../Breadcrumbs";
import Card2, {
  Card2SingleRowStyles,
  Card2SingleRowStylesInverted
} from "../../Card2";
import Carousel from "../../Carousel";
import ImageResponsive from "../../ImageResponsive";
import Icon from "../../Icon";
import Icons from "../../Icons";
import Gps from "../../Gps";
import { useUnsplash } from "../../../hooks";
import {
  TextSection,
  Template,
  Actions,
  TextPrice,
  PageHeader
} from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Accommodation.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Accommodation.lang.ro-ro";
import { hu_hu } from "./Accommodation.lang.hu-hu";
import { en_us } from "./Accommodation.lang.en-us";
import { de_de } from "./Accommodation.lang.de-de";

i18n.addResourceBundle("ro-RO", "Accommodation", ro_ro);
i18n.addResourceBundle("hu-HU", "Accommodation", hu_hu);
i18n.addResourceBundle("en-US", "Accommodation", en_us);
i18n.addResourceBundle("de-DE", "Accommodation", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  },

  icons: {
    /**
     * Align opening hours etc icons to the GPS icon above
     * - horizontally the spacing does the job, here we adjust the vertical space
     */
    "& .MuiGrid-item": {
      paddingBottom: 0
    }
  }
}));

/**
 * Displays the component
 */
const Accommodation = props => {
  const {
    name,
    images,
    description,
    price,
    units,
    sectionTitle,
    sectionDescription1,
    sectionListing,
    sectionDescription2,
    destinationName,
    accommodationType,
    accommodationTypeIcon,
    roomTypes,
    roomTypesIcon,
    extras,
    extrasIcon
  } = props;

  const { name: accommodationTypeName } = accommodationType;

  const { container, icons } = useStyles(props);
  const { t } = useTranslation("Accommodation");

  /**
   * Loads unsplash images
   */
  const unsplashImages = useUnsplash({
    keyword: "Rooms",
    page: 11,
    photos: 3,
    params: { orientation: "landscape" }
  });

  /**
   * Displays the hero card
   */
  const images2 = unsplashImages ? unsplashImages : images;

  const carouselSteps1 = images2.map(image => {
    const { title, id } = image;

    return {
      id: id,
      title: title,
      content: <ImageResponsive {...image} />
    };
  });

  const cardContent1 = <TextSection description={description} />;

  const roomTypesList =
    roomTypes && roomTypes.map(item => item.name).join(", ");

  const extrasList = extras && extras.map(item => item.name).join(", ");

  const iconsList = (
    <Icons
      items={[
        {
          ...accommodationTypeIcon,
          name: accommodationTypeName
        },
        { ...roomTypesIcon, name: roomTypesList },
        { ...extrasIcon, name: extrasList }
      ]}
      orientation="vertical"
    />
  );

  const cardIcons1 = (
    <Actions
      items={[
        { id: shortid.generate(), action: <Gps /> },
        { id: shortid.generate(), action: iconsList }
      ]}
      orientation="vertical"
    />
  );

  const button1 = (
    <Button variant="contained" color="primary" size="large">
      {t("Request stay")}
    </Button>
  );

  const price1 = (
    <TextPrice text={`${t("From")} ${price} RON / ${t("Night")}`} />
  );

  const cardButtons1 = (
    <Actions
      items={[
        { id: shortid.generate(), action: price1 },
        { id: shortid.generate(), action: button1 }
      ]}
      orientation="vertical"
    />
  );

  const cardActions1 = (
    <Actions
      items={[
        { id: shortid.generate(), action: cardIcons1 },
        { id: shortid.generate(), action: cardButtons1 }
      ]}
      orientation="vertical"
    />
  );

  const cardWithMedia1 = {
    image: null,
    images: null,
    carousel: <Carousel steps={carouselSteps1} />,
    content: cardContent1,
    actions: cardActions1,
    customStyles: Card2SingleRowStyles({ ...props, breakpoint: "md" })
  };

  /**
   * Displays the Template
   */
  const cardContent2 = (
    <TextSection
      subtitle={sectionTitle}
      description={sectionDescription1}
      listing={sectionListing}
      description2={sectionDescription2}
    />
  );

  const cardWithMedia2 = {
    image: null,
    content: cardContent2
  };

  return (
    <Layout header={{ currentPage: "Stay" }}>
      <Grid container className={clsx(container, "Accommodation")} spacing={1}>
        <Grid item xs={12}>
          <PageHeader
            breadcrumbs=<Breadcrumbs />
            overline={destinationName}
            title={name}
          />
        </Grid>
        <Grid item xs={12}>
          <Card2 {...cardWithMedia1} />
        </Grid>
        <Grid item xs={12}>
          <Template items={[<Card2 {...cardWithMedia2} />]} />
        </Grid>
      </Grid>
    </Layout>
  );
};

Accommodation.propTypes = propTypes;
Accommodation.defaultProps = defaultProps;

export default Accommodation;
export {
  propTypes as AccommodationPropTypes,
  defaultProps as AccommodationDefaultProps
};
