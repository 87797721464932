/**
 * Displays destination as a Hero
 *
 * @see DestinationAsHero.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import shortid from "shortid";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import Card2, {
  Card2SingleRowStyles,
  Card2SingleRowStylesInverted
} from "../../Card2";
import CategoriesAsThumbs from "../../_category/CategoriesAsThumbs";
import Carousel from "../../Carousel";
import Image from "../../Image";
import { useUnsplash } from "../../../hooks";
import { TextSection } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./DestinationAsHero.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./DestinationAsHero.lang.ro-ro";
import { hu_hu } from "./DestinationAsHero.lang.hu-hu";
import { en_us } from "./DestinationAsHero.lang.en-us";
import { de_de } from "./DestinationAsHero.lang.de-de";

i18n.addResourceBundle("ro-RO", "DestinationAsHero", ro_ro);
i18n.addResourceBundle("hu-HU", "DestinationAsHero", hu_hu);
i18n.addResourceBundle("en-US", "DestinationAsHero", en_us);
i18n.addResourceBundle("de-DE", "DestinationAsHero", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  }
}));

/**
 * Displays the component
 */
const DestinationAsHero = props => {
  const {
    name,
    slug,
    alternateName,
    description,
    images,
    categories,
    index
  } = props;

  const { container } = useStyles(props);
  const { t } = useTranslation("DestinationAsHero");

  /**
   * Loads unsplash images
   */
  const unsplashImages = useUnsplash({
    keyword: "Small village",
    page: index + 1,
    photos: 3,
    params: { orientation: "landscape" }
  });

  /**
   * Loads the current route
   */
  const match = useRouteMatch();
  const { url } = match;

  /**
   * Prepares the card
   */
  const cardContent = (
    <TextSection
      title={name}
      subtitle={alternateName}
      description={description}
    />
  );

  const cardActions = (
    <Button
      variant="contained"
      color="primary"
      size="large"
      component={RouterLink}
      to={`${url}/${slug}`}
    >
      {t("Explore")}
    </Button>
  );

  const styles =
    index % 2 === 0 ? Card2SingleRowStylesInverted : Card2SingleRowStyles;

  const images3 = unsplashImages ? unsplashImages : images;

  const carouselSteps = images3.map(image => {
    const { title } = image;

    return {
      id: shortid.generate(),
      title: title,
      content: <Image {...image} />
    };
  });

  const cardWithMedia = {
    image: null,
    carousel: (
      <Carousel
        steps={carouselSteps}
        isMobileStepperAlignedRight={index % 2 === 0}
        customStyles={{ breakpoint: "sm", spacingTop: 2 }}
      />
    ),
    content: cardContent,
    actions: cardActions,
    customStyles: styles({ ...props, breakpoint: "md", imageWidth: 640 })
  };

  return (
    <Grid container className={clsx(container, "DestinationAsHero")}>
      <Grid item xs={12}>
        <Card2 {...cardWithMedia} />
      </Grid>
      <Grid item xs={12} className={clsx(container, "Categories")}>
        <CategoriesAsThumbs categories={categories} />
      </Grid>
    </Grid>
  );
};

DestinationAsHero.propTypes = propTypes;
DestinationAsHero.defaultProps = defaultProps;

export default DestinationAsHero;
export {
  propTypes as DestinationAsHeroPropTypes,
  defaultProps as DestinationAsHeroDefaultProps
};
