/**
 * Component short description
 *
 * @see Routes.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Routes.data";

/**
 * Imports logic
 */
import { localizeRoutes, localizePath } from "./Routes.logic";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Routes.lang.ro-ro";
import { hu_hu } from "./Routes.lang.hu-hu";
import { en_us } from "./Routes.lang.en-us";
import { de_de } from "./Routes.lang.de-de";

i18n.addResourceBundle("ro-RO", "Routes", ro_ro);
i18n.addResourceBundle("hu-HU", "Routes", hu_hu);
i18n.addResourceBundle("en-US", "Routes", en_us);
i18n.addResourceBundle("de-DE", "Routes", de_de);

/**
 * Displays the component
 */
const Routes = props => {
  const { routes } = props;
  const { t } = useTranslation("Routes");

  const localizedRoutes = localizeRoutes({ routes: routes, t: t });

  return (
    <Router>
      <Switch>
        {localizedRoutes && localizedRoutes.map(route => <Route {...route} />)}
      </Switch>
    </Router>
  );
};

Routes.propTypes = propTypes;
Routes.defaultProps = defaultProps;

export default Routes;
export {
  propTypes as RoutesPropTypes,
  defaultProps as RoutesDefaultProps,
  localizePath as routesLocalizePath
};
