/**
 * Defines the data requirements for the component
 *
 * @see News.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import Lorem from "react-lorem-component";

/**
 * Imports prop types from other components
 */
import {
  ArticleAsThumbPropTypes,
  ArticleAsThumbDefaultProps
} from "../../_article/ArticleAsThumb";
import {
  DestinationPropTypes,
  DestinationDefaultProps
} from "../../_destination/Destination";

/**
 * Defines the prop types
 *
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/ > NewsInfo
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/News/getNews
 */
const propTypes = {
  /**
   * Props from the API
   */
  /**
   * Short description (for the frontpage)
   */
  description: PropTypes.string,
  /**
   * Internal props
   *
   * // NOTE: It seems not only articles are listed here but also events. In this case please be inspired by SearchResults
   */
  articles: PropTypes.arrayOf(PropTypes.shape(ArticleAsThumbPropTypes)),
  destinations: PropTypes.arrayOf(PropTypes.shape(DestinationPropTypes))
};

/**
 * Defines the default props
 */
const defaultProps = {
  description: <Lorem seed="0" count="1" sentenceUpperBound="7" />,
  articles: Array(12)
    .fill(ArticleAsThumbDefaultProps)
    .map((item, index) => {
      return {
        ...item,
        id: shortid.generate(),
        name: `Article ${index + 1}`,
        slug: `article-${index + 1}`
      };
    }),
  destinations: Array(3)
    .fill(DestinationDefaultProps)
    .map((item, index) => {
      return {
        ...item,
        id: shortid.generate(),
        name: `Destination ${index + 1}`
      };
    })
};

export { propTypes, defaultProps };
