/**
 * Component short description
 *
 * @see FilteredBy.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import { isEmpty, capitalize } from "lodash";

/**
 * Imports other components and hooks
 */
import { useFilters } from "../../hooks";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./FilteredBy.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./FilteredBy.lang.ro-ro";
import { hu_hu } from "./FilteredBy.lang.hu-hu";
import { en_us } from "./FilteredBy.lang.en-us";
import { de_de } from "./FilteredBy.lang.de-de";

i18n.addResourceBundle("ro-RO", "FilteredBy", ro_ro);
i18n.addResourceBundle("hu-HU", "FilteredBy", hu_hu);
i18n.addResourceBundle("en-US", "FilteredBy", en_us);
i18n.addResourceBundle("de-DE", "FilteredBy", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    ...theme.custom.mui.multiLineChip,
  },
}));

const hasFilterValue = (filter) => {
  if (!filter) {
    return false;
  }

  const { filterValue: value } = filter;
  if (Array.isArray(value) && !value.length) {
    return false;
  }

  switch (typeof value) {
    case "object":
      return !isEmpty(value);
    case "number":
      return value > 0;
    case "string":
      return value.length > 0;
    default:
      return true;
  }
};

/**
 * Checks if a filter is set
 */
const isFilterSet = (filter, filters) => {
  const [_, value] = filter;
  return hasFilterValue(value);
};

/**
 * Creates a label for a FilteredBy item
 */
const getFilterLabel = (filter, translations) => {
  const key = filter[0];
  const value = filter[1];

  const { label } = value;
  const {
    destination,
    manufacturer,
    category,
    accomodationType,
    roomType,
    extras,
  } = translations;

  switch (key) {
    case "destination":
      return `${destination}: ${label}`;
    case "manufacturer":
      return `${manufacturer}: ${label}`;
    case "category":
      return `${category}: ${label}`;
    case "accomodationType":
      return `${accomodationType}: ${label}`;
    case "roomType":
      return `${roomType}: ${label}`;
    case "extras":
      return `${extras}: ${label}`;
    default:
      return `${capitalize(key)}: ${label}`;
  }
};

/**
 * Displays the component
 */
const FilteredBy = (props) => {
  const { filters, setFilters, resetFilters } = useFilters();
  const filtersMap = Object.entries(filters);

  const { t } = useTranslation("FilteredBy");
  const classes = useStyles();
  const { chips, chip } = classes;

  /**
   * Match filter names with filter display values
   *
   * - A filter name in the API (`category`) can differ from the filter display name (`Industry`)
   *
   */
  const translations = {
    destination: t("destination"),
    manufacturer: t("manufacturer"),
    category: t("category"),
    accomodationType: t("accomodation_type"),
    roomType: t("room_type"),
    extras: t("extras"),
  };

  /**
   * Handles the delete actions
   *
   * - The country filter cannot be removed
   */
  const handleDelete = (key) => () => {
    setFilters((prevState) => {
      return { ...prevState, [key]: null };
    });
  };

  const handleDeleteAll = () => {
    resetFilters({});
  };

  /**
   * Prepares the items
   */
  const items =
    (filtersMap &&
      filtersMap
        .filter((filter) => isFilterSet(filter, filtersMap))
        .map((filter) => {
          const key = filter[0];
          const label = getFilterLabel(filter, translations);
          return (
            <Chip
              key={key}
              label={label}
              onDelete={handleDelete(key)}
              className={chip}
            />
          );
        })) ||
    [];

  return (
    <FormControl>
      <div className={chips}>
        <Chip
          key="clear-all"
          label={t("clear_all")}
          onDelete={handleDeleteAll}
        />
        {items}
      </div>
    </FormControl>
  );
};

FilteredBy.propTypes = propTypes;
FilteredBy.defaultProps = defaultProps;

export default FilteredBy;
export {
  propTypes as FilteredByPropTypes,
  defaultProps as FilteredByDefaultProps,
};
