/**
 * Component short description
 *
 * @see useDestination.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./useDestination.data";
import useRequests from "../../useRequests";
import useSWR from "swr";

export const useDestinationsSwr = () => {
  const { get } = useRequests();
  return useSWR("destinations", get);
};

export const useDestinationsSimpleSwr = () => {
  const { get } = useRequests();
  return useSWR("destinations/simple", get);
};

export const useDestinationsCategoriesSwr = () => {
  const { get } = useRequests();
  return useSWR("destinations/categories", get);
};

export const useDestinationsCategoryInfo = () => {
  const { get } = useRequests();
  return useSWR("destinations/categories/info", get);
};

export const useDestinationSwr = (id) => {
  const { get } = useRequests();
  return useSWR(`destinations/${id}`, get);
};

export const useDestinationCategorySwr = (destinationId, categoryId) => {
  const { get } = useRequests();
  return useSWR(`destinations/${destinationId}/categories/${categoryId}`, get);
};

export const useAttractionSwr = (id) => {
  const { get } = useRequests();
  return useSWR(`destinations/attractions/${id}`, get);
};

const useDestination = (props) => {
  return { useDestinationSwr };
};

useDestination.propTypes = propTypes;
useDestination.defaultProps = defaultProps;

export default useDestination;
export {
  propTypes as useDestinationPropTypes,
  defaultProps as useDestinationDefaultProps,
};
