/**
 * Component short description
 *
 * @see FilterExtras.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import FilterListItem from "../../FilterListItem/FilterListItem";
import { useFilters } from "../../../hooks";
import {
  getSelectedArrayValues,
  getArrayOfKeys,
} from "../../../hooks/useFilters";
import FilterChips from "../../FilterChips/FilterChips";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./FilterExtras.data";

/**
 * Imports Material UI components
 */
import { ListItemText } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./FilterExtras.lang.ro-ro";
import { hu_hu } from "./FilterExtras.lang.hu-hu";
import { en_us } from "./FilterExtras.lang.en-us";
import { de_de } from "./FilterExtras.lang.de-de";

i18n.addResourceBundle("ro-RO", "FilterExtras", ro_ro);
i18n.addResourceBundle("hu-HU", "FilterExtras", hu_hu);
i18n.addResourceBundle("en-US", "FilterExtras", en_us);
i18n.addResourceBundle("de-DE", "FilterExtras", de_de);

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const FilterExtras = (props) => {
  const { filterListItem, defaultValue } = props;

  const { t } = useTranslation("FilterExtras");
  /**
   * Handles the filters
   */
  const { filters, setFilters, data } = useFilters();
  const { extras } = data;

  const selected = getSelectedArrayValues(
    "id",
    filters.extras?.filterValue || defaultValue,
    extras
  );

  const selectHandler = (data) => {
    const {
      target: { value },
    } = data;
    if (value[0] === "empty") {
      return;
    }

    setFilters((prevState) => {
      return {
        ...prevState,
        extras: {
          filterValue: getArrayOfKeys("id", value),
          label: getArrayOfKeys("name", value),
        },
      };
    });
  };

  return (
    <FilterListItem {...filterListItem} primary={t("name")}>
      <Select
        multiple
        name="seller"
        // open={open}
        // onOpen={handleOpenSelect}
        // onClose={handleCloseSelect}
        value={selected}
        onChange={selectHandler}
        fullWidth
        displayEmpty
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <FilterChips selected={selected} emptyText={t("placeholder")} />
        )}
      >
        {extras.map((e) => {
          return (
            <MenuItem key={e.id} value={e}>
              <ListItemText primary={e.name} />
            </MenuItem>
          );
        })}
      </Select>
    </FilterListItem>
  );
};
FilterExtras.propTypes = propTypes;
FilterExtras.defaultProps = defaultProps;

export default FilterExtras;
export {
  propTypes as FilterExtrasPropTypes,
  defaultProps as FilterExtrasDefaultProps,
};
