const en_us = {
  RegisterDialog: "RegisterDialog",
  register: "Join Us",
  description:
    "Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incidid unt ut labore et",
  account: "Already have an account?",
  login: "Sign in!",
  register_success: "Successfull registration. You can now log in.",
  register_fail: "Could not register",
};

export { en_us };
