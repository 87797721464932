/**
 * Defines the data requirements for the component
 *
 * @see Dialog2.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  open: PropTypes.bool,
  id: PropTypes.string,
  onClose: PropTypes.func,
  maxWidth: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.height,
  title: PropTypes.string,
  content: PropTypes.any
};

/**
 * Defines the default props
 */
const defaultProps = {
  open: false,
  id: "dialog-id",
  onClose: () => {
    console.log("Dialog close clicked");
  },
  maxWidth: "lg",
  width: "99vw",
  height: "99vh",
  title: "Dialog title",
  content: "Dialog content"
};

export { propTypes, defaultProps };
