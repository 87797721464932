/**
 * Component short description
 *
 * @see SearchForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState } from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./SearchForm.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./SearchForm.lang.ro-ro";
import { hu_hu } from "./SearchForm.lang.hu-hu";
import { en_us } from "./SearchForm.lang.en-us";
import { de_de } from "./SearchForm.lang.de-de";

i18n.addResourceBundle("ro-RO", "SearchForm", ro_ro);
i18n.addResourceBundle("hu-HU", "SearchForm", hu_hu);
i18n.addResourceBundle("en-US", "SearchForm", en_us);
i18n.addResourceBundle("de-DE", "SearchForm", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  },

  select: {
    width: "100%"
  }
}));

/**
 * Displays the component
 */
const SearchForm = props => {
  const { text, category, categories } = props;
  const { name: categoryName } = category;
  const { container, select } = useStyles(props);
  const { t } = useTranslation("SearchForm");

  /**
   * Handles the Categories select box
   */
  const [category1, setCategory1] = useState(categoryName);

  const handleChange = event => {
    setCategory1(event.target.value);
  };

  /**
   * Displays the text field
   */
  const textField = (
    <TextField
      label={t("Search text")}
      defaultValue={text !== "null" ? text : ""}
      variant="outlined"
      className={clsx(select)}
    />
  );

  /**
   * Displays a list of Categories
   */
  const categoriesList = categories.map(item => {
    const { id, name } = item;

    return (
      <MenuItem value={name} key={id}>
        {name}
      </MenuItem>
    );
  });

  /**
   * Displays the Categories select box
   */
  const categoriesSelect = (
    <FormControl variant="outlined" className={clsx(select, "FormControl")}>
      <InputLabel id="search-categories-select-label">
        {t("Category")}
      </InputLabel>
      <Select
        label={t("Category")}
        labelId="search-categories-select-label"
        id="search-categories-select"
        value={category1}
        onChange={handleChange}
      >
        {categoriesList}
      </Select>
    </FormControl>
  );

  /**
   * The grid is aligned to the Home/About section above this component
   */
  return (
    <Grid
      container
      spacing={1}
      className={clsx(container, "SearchForm")}
      alignItems="center"
    >
      <Grid item xs={12} sm={6} md={4}>
        {textField}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        {categoriesSelect}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Button variant="contained" color="primary" size="large">
          {t("Search")}
        </Button>
      </Grid>
    </Grid>
  );
};

SearchForm.propTypes = propTypes;
SearchForm.defaultProps = defaultProps;

export default SearchForm;
export {
  propTypes as SearchFormPropTypes,
  defaultProps as SearchFormDefaultProps
};
