/**
 * Component short description
 *
 * @see Contact.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect } from "react";
import clsx from "clsx";
import { useSnackbar } from "notistack";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Card2, {
  Card2SingleRowStyles,
  Card2SingleRowStylesInverted
} from "../../Card2";
import ContactForm from "../ContactForm/ContactForm";
import { useUnsplash } from "../../../hooks";
import { TextSection, PageHeader } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Contact.data";
import useDestination, {
  useDestinationsSimpleSwr,
  useDestinationSwr,
  useDestinationsSwr
} from "../../../hooks/api-hooks/useDestination/useDestination";
import useContact from "../../../hooks/api-hooks/useContact/useContact";

/**
 * Imports Material UI components
 */
import { makeStyles, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Contact.lang.ro-ro";
import { hu_hu } from "./Contact.lang.hu-hu";
import { en_us } from "./Contact.lang.en-us";
import { de_de } from "./Contact.lang.de-de";

i18n.addResourceBundle("ro-RO", "Contact", ro_ro);
i18n.addResourceBundle("hu-HU", "Contact", hu_hu);
i18n.addResourceBundle("en-US", "Contact", en_us);
i18n.addResourceBundle("de-DE", "Contact", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: { ...theme.custom.grid.removeNegativeMargin }
}));

/**
 * Displays the component
 */
const Contact = props => {
  const { description, image, contactForm } = props;
  const { t, i18n } = useTranslation("Contact");
  const { language } = i18n;
  const { enqueueSnackbar } = useSnackbar();
  const { container } = useStyles(props);

  const { createContact, useContactInfoSwr } = useContact();
  const { data } = useDestinationsSwr();
  const { data: contact = {}, mutate } = useContactInfoSwr();

  /**
   * Loads unsplash images
   */
  const unsplashImages = useUnsplash({
    keyword: "Contact",
    page: 5,
    photos: 1,
    params: { orientation: "landscape" }
  });

  useEffect(() => {
    mutate();
  }, [language, mutate]);

  const handleFormSubmit = async form => {
    createContact(form).then(resp => {
      enqueueSnackbar(t("contact_submitted"), { variant: "success" });
    });
  };

  /**
   * Displays the card
   */
  const cardContent = <TextSection description={description} />;

  const cardActions = (
    <ContactForm
      {...contactForm}
      destinations={data ?? []}
      onSubmit={handleFormSubmit}
    />
  );

  const cardWithMedia = {
    image: unsplashImages ? unsplashImages[0] : image,
    content: cardContent,
    actions: cardActions,
    customStyles: Card2SingleRowStylesInverted({ ...props, breakpoint: "lg" })
  };

  return (
    <Layout header={{ currentPage: "Contact" }}>
      <Grid container spacing={1} className={clsx(container, "Contact")}>
        <Grid item xs={12}>
          <PageHeader title={t("Contact")} />
        </Grid>
        <Grid item xs={12}>
          <Card2 {...cardWithMedia} />
        </Grid>
      </Grid>
    </Layout>
  );
};

Contact.propTypes = propTypes;
Contact.defaultProps = defaultProps;

export default Contact;
export { propTypes as ContactPropTypes, defaultProps as ContactDefaultProps };
