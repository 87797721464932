/**
 * Component short description
 *
 * @see useFilters.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { createContext, useContext, useState } from "react";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./useFilters.data";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { ro_ro } from "./useFilters.lang.ro-ro";
import { hu_hu } from "./useFilters.lang.hu-hu";
import { en_us } from "./useFilters.lang.en-us";
import { de_de } from "./useFilters.lang.de-de";

i18n.addResourceBundle("ro-RO", "useFilters", ro_ro);
i18n.addResourceBundle("hu-HU", "useFilters", hu_hu);
i18n.addResourceBundle("en-US", "useFilters", en_us);
i18n.addResourceBundle("de-DE", "useFilters", de_de);

const FilterContext = createContext();

const getSelectedArrayValues = (key, selected, items) => {
  if (!selected) {
    return [];
  }
  return items.filter((item) => selected.includes(item[key]));
};

const getArrayOfKeys = (key, items) => {
  return items.map((item) => {
    return item[key];
  });
};

const FiltersProvider = ({ children, defaultFilters, defaultData, type }) => {
  const [filters, setFilters] = useState(defaultFilters);
  const [data, setData] = useState(defaultData);
  const resetFilters = (resetToValues = {}) => {
    setFilters({
      ...defaultFilters,
      ...resetToValues,
    });
  };

  const parseFiltersForQuery = (filtersToParse) => {
    return Object.keys(filtersToParse).reduce((obj, key) => {
      const value = filtersToParse[key];

      if (!value) {
        return obj;
      }

      const { filterValue } = value;
      if (filterValue) {
        return {
          ...obj,
          [key]: filterValue,
        };
      }
    }, {});
  };

  return (
    <FilterContext.Provider
      value={{
        filters: filters,
        setFilters: setFilters,
        data: data,
        setData: setData,
        resetFilters: resetFilters,
        defaultFilters: defaultFilters,
        parseFiltersForQuery: parseFiltersForQuery,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
/**
 * Displays the component
 */
const useFilters = (props) => {
  return useContext(FilterContext);
};

useFilters.propTypes = propTypes;
useFilters.defaultProps = defaultProps;

export default useFilters;
export { useFilters, FiltersProvider, getSelectedArrayValues, getArrayOfKeys };
