/**
 * Defines the data requirements for the component
 *
 * @see CategoryAsHero.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { CategoryPropTypes, CategoryDefaultProps } from "../Category";

/**
 * Defines the prop types
 */
const propTypes = {
  ...CategoryPropTypes
};

/**
 * Defines the default props
 */
const defaultProps = {
  ...CategoryDefaultProps
};

export { propTypes, defaultProps };
