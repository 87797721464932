/**
 * Defines the data requirements for the component
 *
 * @see StaySearch.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.tring,
    })
  ),
  defaultData: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  defaultFilters: {
    destination: null,
    accomodationType: null,
    roomType: null,
    extras: null,
  },
  defaultStays: [],

  defaultData: {
    destinations: [],
    accomodationTypes: [],
    roomTypes: [],
    extras: [],
  },
};

export { propTypes, defaultProps };
