/**
 * Defines the data requirements for the component
 *
 * @see DestinationsList.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import Lorem from "react-lorem-component";
import shortid from "shortid";

/**
 * Imports prop types from other components
 */
import {
  DestinationAsHeroPropTypes,
  DestinationAsHeroDefaultProps
} from "../../_destination/DestinationAsHero";

/**
 * Defines the prop types
 */
const propTypes = {
  destinations: PropTypes.arrayOf(PropTypes.shape(DestinationAsHeroPropTypes))
};

/**
 * Defines the default props
 */
const defaultProps = {
  destinations: Array(10)
    .fill(DestinationAsHeroDefaultProps)
    .map((item, index) => {
      return {
        ...item,
        id: shortid.generate(),
        name: (
          <Lorem
            format="text"
            seed={index}
            count={1}
            sentenceLowerBound={1}
            sentenceUpperBound={2}
            paragraphLowerBound={1}
            paragraphUpperBound={1}
          />
        ),
        alternateName: (
          <Lorem
            format="text"
            seed={index}
            count={1}
            sentenceLowerBound={1}
            sentenceUpperBound={5}
            paragraphLowerBound={1}
            paragraphUpperBound={1}
          />
        ),
        description: <Lorem format="text" seed={index} count={1} />
      };
    })
};

export { propTypes, defaultProps };
