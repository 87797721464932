/**
 * Component short description
 *
 * @see DestinationAsThumb.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import Card2 from "../../Card2";
import { useUnsplash } from "../../../hooks";
import { TextThumbnail } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./DestinationAsThumb.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./DestinationAsThumb.lang.ro-ro";
import { hu_hu } from "./DestinationAsThumb.lang.hu-hu";
import { en_us } from "./DestinationAsThumb.lang.en-us";
import { de_de } from "./DestinationAsThumb.lang.de-de";

i18n.addResourceBundle("ro-RO", "DestinationAsThumb", ro_ro);
i18n.addResourceBundle("hu-HU", "DestinationAsThumb", hu_hu);
i18n.addResourceBundle("en-US", "DestinationAsThumb", en_us);
i18n.addResourceBundle("de-DE", "DestinationAsThumb", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  }
}));

/**
 * Displays the component
 */
const DestinationAsThumb = props => {
  const { container } = useStyles(props);
  const { name, slug, image, description, index } = props;
  const { t } = useTranslation("DestinationAsThumb");

  /**
   * Loads unsplash images
   */
  const unsplashImages = useUnsplash({
    keyword: "Small village",
    page: index + 1,
    photos: 1,
    params: { orientation: "landscape" }
  });

  /**
   * Loads the current route
   */
  const match = useRouteMatch();
  const { url } = match;

  /**
   * Displays the card
   */
  const cardContent1 = (
    <TextThumbnail
      to={`${url}/${slug}`}
      title={name}
      description={description}
    />
  );

  const cardWithMedia1 = {
    image: unsplashImages ? unsplashImages[0] : image,
    content: cardContent1,
    customStyles: useStyles(props)
  };

  return <Card2 {...cardWithMedia1} />;
};

DestinationAsThumb.propTypes = propTypes;
DestinationAsThumb.defaultProps = defaultProps;

export default DestinationAsThumb;
export {
  propTypes as DestinationAsThumbPropTypes,
  defaultProps as DestinationAsThumbDefaultProps
};
