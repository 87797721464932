/**
 * Component short description
 *
 * @see Newsletter.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Card2, {
  Card2SingleRowStyles,
  Card2SingleRowStylesInverted
} from "../Card2";
import NewsletterForm from "../NewsletterForm";
import { useUnsplash } from "../../hooks";
import { TextSection } from "../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Newsletter.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Newsletter.lang.ro-ro";
import { hu_hu } from "./Newsletter.lang.hu-hu";
import { en_us } from "./Newsletter.lang.en-us";
import { de_de } from "./Newsletter.lang.de-de";

i18n.addResourceBundle("ro-RO", "Newsletter", ro_ro);
i18n.addResourceBundle("hu-HU", "Newsletter", hu_hu);
i18n.addResourceBundle("en-US", "Newsletter", en_us);
i18n.addResourceBundle("de-DE", "Newsletter", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {}
}));

/**
 * Displays the component
 */
const Newsletter = props => {
  const { description, enabled, image, form } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("Newsletter");

  /**
   * Loads unsplash images
   */
  const unsplashImages = useUnsplash({
    keyword: "Newsletter",
    page: 1,
    photos: 1,
    params: { orientation: "landscape" }
  });

  if (!enabled) return;

  /**
   * Displays the card
   */
  const cardContent1 = (
    <TextSection subtitle={t("Newsletter")} description={description} />
  );

  const cardActions1 = <NewsletterForm {...form} />;

  const cardWithMedia1 = {
    image: null,
    //image: unsplashImages ? unsplashImages[0] : image,
    //widget: cardActions1,
    content: cardContent1,
    actions: cardActions1,
    customStyles: Card2SingleRowStylesInverted({ ...props, breakpoint: "sm" }),
    cardContainerProps: { alignItems: "center" }
  };

  return <Card2 {...cardWithMedia1} />;
};

Newsletter.propTypes = propTypes;
Newsletter.defaultProps = defaultProps;

export default Newsletter;
export {
  propTypes as NewsletterPropTypes,
  defaultProps as NewsletterDefaultProps
};
