/**
 * Defines the data requirements for the component
 *
 * @see Gps.md for details
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { IconPropTypes, IconDefaultProps } from "../Icon";
import RoomIcon from "@material-ui/icons/Room";

/**
 * Defines the prop types
 */
const propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  icon: PropTypes.shape(IconPropTypes)
};

/**
 * Defines the default props
 */
const defaultProps = {
  latitude: 44.44444,
  longitude: 44.44444,
  icon: {
    icon: <RoomIcon />,
    name: "GPS"
  }
};

export { propTypes, defaultProps };
