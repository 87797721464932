const en_us = {
  FilteredBy: "FilteredBy",
  clear_all: "Clear all",
  destination: "Destination",
  manufacturer: "Seller",
  category: "Category",
  accomodation_type: "Accomodation type",
  room_type: "Room type",
  extras: "Extras",
};

export { en_us };
