const en_us = {
  ForgotPassDialog: "ForgotPassDialog",
  title: "Forgot password",
  description:
    "Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incidid unt ut labore et ",
  return: "Or return to",
  login: "Sign in",
  reset_success: "Password recovery email sent",
  reset_fail: "Could not send email",
};

export { en_us };
