/**
 * Component short description
 *
 * @see StaySearch.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState, useEffect } from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import StayFilters from "../StayFilters/StayFilters";
import { FiltersProvider, useFilters } from "../../../hooks";
import FilteredBy from "../../FilteredBy";
/**
 * Imports data
 */
import { propTypes, defaultProps } from "./StaySearch.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./StaySearch.lang.ro-ro";
import { hu_hu } from "./StaySearch.lang.hu-hu";
import { en_us } from "./StaySearch.lang.en-us";
import { de_de } from "./StaySearch.lang.de-de";
import { useDestinationsSwr } from "../../../hooks/api-hooks/useDestination/useDestination";
import useStay, {
  useAccomodationTypesSwr,
  useRoomTypesSwr,
  useExtrasSwr
} from "../Stay/Stay.logic";

i18n.addResourceBundle("ro-RO", "StaySearch", ro_ro);
i18n.addResourceBundle("hu-HU", "StaySearch", hu_hu);
i18n.addResourceBundle("en-US", "StaySearch", en_us);
i18n.addResourceBundle("de-DE", "StaySearch", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    border: "1px solid"
  }
}));

/**
 * Displays the component
 */
const StaySearch = props => {
  const { defaultStays } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("StaySearch");

  const [stays, setStays] = useState(defaultStays);
  const { filters, setData, parseFiltersForQuery } = useFilters();

  const { data: destinations = [] } = useDestinationsSwr();
  const { data: accomodationTypes = [] } = useAccomodationTypesSwr();
  const { data: roomTypes = [] } = useRoomTypesSwr();
  const { data: extras = [] } = useExtrasSwr();
  const { getStays } = useStay();

  useEffect(() => {
    if (
      destinations.length &&
      accomodationTypes.length &&
      roomTypes.length &&
      extras.length
    ) {
      setData({
        destinations,
        accomodationTypes,
        roomTypes,
        extras
      });
    }
  }, [destinations, accomodationTypes, roomTypes, extras]);

  const fetchStays = async filters => {
    getStays(filters).then(results => {
      setStays(results.data);
    });
  };

  useEffect(() => {
    fetchStays(parseFiltersForQuery(filters));
  }, [filters]);

  const stayList = stays.map(item => (
    <Grid item xs={12} md={6} key={item.id}>
      {item.name}
    </Grid>
  ));
  return (
    <Grid container>
      <Grid item xs={12} md={3} className={clsx(container, "StaySearch")}>
        <StayFilters />
      </Grid>
      <Grid container item xs={12} md={9} spacing={2}>
        <Grid item xs={12}>
          <FilteredBy />
        </Grid>
        <Grid item xs={12}>
          {stayList}
        </Grid>
      </Grid>
    </Grid>
  );
};

StaySearch.propTypes = propTypes;
StaySearch.defaultProps = defaultProps;

export default StaySearch;
export {
  propTypes as StaySearchPropTypes,
  defaultProps as StaySearchDefaultProps
};
