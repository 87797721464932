/**
 * Component short description
 *
 * @see Valuta.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Card2, {
  Card2SingleRowStyles,
  Card2SingleRowStylesInverted
} from "../../Card2";
import { TextSection } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Valuta.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Valuta.lang.ro-ro";
import { hu_hu } from "./Valuta.lang.hu-hu";
import { en_us } from "./Valuta.lang.en-us";
import { de_de } from "./Valuta.lang.de-de";

i18n.addResourceBundle("ro-RO", "Valuta", ro_ro);
i18n.addResourceBundle("hu-HU", "Valuta", hu_hu);
i18n.addResourceBundle("en-US", "Valuta", en_us);
i18n.addResourceBundle("de-DE", "Valuta", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({}));

/**
 * Displays the component
 */
const Valuta = props => {
  const { widget } = props;
  const { t } = useTranslation("Valuta");

  /**
   * Displays the card
   */
  const cardContent1 = <TextSection subtitle={t("Valuta")} />;

  const widget2 = (
    <Grid item xs={12} dangerouslySetInnerHTML={{ __html: widget }} />
  );

  const cardWithMedia1 = {
    image: null,
    content: cardContent1,
    actions: widget2,
    customStyles: Card2SingleRowStylesInverted({ ...props, breakpoint: "sm" }),
    cardContainerProps: { alignItems: "center" }
  };

  return <Card2 {...cardWithMedia1} />;
};

Valuta.propTypes = propTypes;
Valuta.defaultProps = defaultProps;

export default Valuta;
export { propTypes as ValutaPropTypes, defaultProps as ValutaDefaultProps };
