/**
 * Component short description
 *
 * @see LocalMarketAsHero.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { forwardRef } from "react";
import clsx from "clsx";
import shortid from "shortid";
import { Link as RouterLink } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import Card2, {
  Card2SingleRowStyles,
  Card2SingleRowStylesInverted
} from "../../Card2";
import Carousel from "../../Carousel";
import ProductAsThumb from "../../_product/ProductAsThumb";
import { useUnsplash } from "../../../hooks";
import { TextSection } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./LocalMarketAsHero.data";

/**
 * Imports Material UI components
 */
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./LocalMarketAsHero.lang.ro-ro";
import { hu_hu } from "./LocalMarketAsHero.lang.hu-hu";
import { en_us } from "./LocalMarketAsHero.lang.en-us";
import { de_de } from "./LocalMarketAsHero.lang.de-de";

i18n.addResourceBundle("ro-RO", "LocalMarketAsHero", ro_ro);
i18n.addResourceBundle("hu-HU", "LocalMarketAsHero", hu_hu);
i18n.addResourceBundle("en-US", "LocalMarketAsHero", en_us);
i18n.addResourceBundle("de-DE", "LocalMarketAsHero", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  },

  imageContainer: {
    order: 2,
    [theme.breakpoints.up("xs")]: {
      order: 1
    }
  }
}));

/**
 * Displays the component
 */
const LocalMarketAsHero = props => {
  const { localMarket, products } = props;
  const { description: localMarketDescription } = localMarket;

  const { container, imageContainer } = useStyles(props);
  const { t } = useTranslation("LocalMarketAsHero");

  const LinkToLocalMarket = forwardRef((props, ref) => (
    <RouterLink ref={ref} to="/local-market/" {...props} />
  ));

  /**
   * Prepares the images for the carousel
   */
  const carouselSteps = products.map((item, index) => {
    return {
      id: shortid.generate(),
      content: <ProductAsThumb {...item} index={index} />
    };
  });

  const cardContent = (
    <TextSection
      title={t("Local market")}
      description={localMarketDescription}
    />
  );

  const cardActions = (
    <>
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={LinkToLocalMarket}
      >
        {t("View more")}
      </Button>
    </>
  );

  const cardWithMedia = {
    klassName: "LocalMarketAsHero",
    image: null,
    images: null,
    carousel: (
      <Carousel steps={carouselSteps} isMobileStepperAlignedRight={true} />
    ),
    content: cardContent,
    actions: cardActions,
    customStyles: Card2SingleRowStylesInverted({
      ...props,
      breakpoint: "md",
      imageWidth: 640
    })
  };

  return <Card2 {...cardWithMedia} />;
};

LocalMarketAsHero.propTypes = propTypes;
LocalMarketAsHero.defaultProps = defaultProps;

export default LocalMarketAsHero;
export {
  propTypes as LocalMarketAsHeroPropTypes,
  defaultProps as LocalMarketAsHeroDefaultProps
};
