/**
 * Defines the data requirements for the component
 *
 * @see AccommodationAsHero.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { StayPropTypes, StayDefaultProps } from "../Stay";
import {
  AccommodationAsThumbPropTypes,
  AccommodationAsThumbDefaultProps
} from "../../_accommodation/AccommodationAsThumb";

/**
 * Defines the prop types
 */
const propTypes = {
  stay: PropTypes.shape(StayPropTypes),
  stays: PropTypes.arrayOf(PropTypes.shape(AccommodationAsThumbPropTypes))
};

/**
 * Defines the default props
 */
const defaultProps = {
  stay: StayDefaultProps,
  stays: Array(5)
    .fill(AccommodationAsThumbDefaultProps)
    .map((item, index) => {
      return { ...item, name: `Accommodation ${index}` };
    })
};

export { propTypes, defaultProps };
