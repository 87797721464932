/**
 * Defines the data requirements for the component
 *
 * @see ProductSearch.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import {
  ProductAsThumbPropTypes,
  ProductAsThumbDefaultProps,
} from "../ProductAsThumb";

/**
 * Defines the prop types
 */
const propTypes = {
  defaultFilters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.tring,
    })
  ),
  defaultProducts: PropTypes.arrayOf(PropTypes.shape(ProductAsThumbPropTypes)),
  defaultData: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  defaultFilters: { destination: null, manufacturer: null, category: null },
  defaultProducts: [],
  defaultData: {
    destinations: [],
    sellers: [],
    categories: [],
  },
};

export { propTypes, defaultProps };
