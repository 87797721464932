/**
 * Defines the data requirements for the component
 *
 * @see Account.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import Lorem from "react-lorem-component";

/**
 * Imports prop types from other components
 */
import {
  ImageResponsivePropTypes,
  ImageResponsiveDefaultProps,
  ImageResponsivePlaceholderCom11
} from "../../ImageResponsive";

/**
 * Defines the prop types
 */
const propTypes = {
  /**
   * From the API
   *
   * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/Account/getAccountProfile
   */
  name: PropTypes.string,
  email: PropTypes.string,
  avatar: PropTypes.string,
  isManufacturer: PropTypes.bool,
  isOwner: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  isLocalAdmin: PropTypes.bool,
  /**
   * Other props
   */
  description: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  image: PropTypes.shape(ImageResponsivePropTypes),
  activeTab: PropTypes.number
};

/**
 * Defines the default props
 */
const defaultProps = {
  name: "User name",
  email: "john.doe@example.com",
  avatar: "https://www.avatar1.com",
  image: {
    ...ImageResponsivePlaceholderCom11,
    sizes: "(max-width: 767px) 100px, 200px"
  },
  isManufacturer: true,
  isOwner: false,
  isSuperAdmin: false,
  isLocalAdmin: false,
  description: <Lorem seed="2" count="1" sentenceUpperBound="5" />,
  isAuthenticated: false,
  activeTab: 0
};

export { propTypes, defaultProps };
