/**
 * Component short description
 *
 * @see OrdersForCart.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import OrderForCart from "../OrderForCart";
import { useUnsplash } from "../../../hooks";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./OrdersForCart.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./OrdersForCart.lang.ro-ro";
import { hu_hu } from "./OrdersForCart.lang.hu-hu";
import { en_us } from "./OrdersForCart.lang.en-us";
import { de_de } from "./OrdersForCart.lang.de-de";

i18n.addResourceBundle("ro-RO", "OrdersForCart", ro_ro);
i18n.addResourceBundle("hu-HU", "OrdersForCart", hu_hu);
i18n.addResourceBundle("en-US", "OrdersForCart", en_us);
i18n.addResourceBundle("de-DE", "OrdersForCart", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  }
}));

/**
 * Displays the component
 */
const OrdersForCart = props => {
  const { orders } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("OrdersForCart");

  /**
   * Prepares the orders
   */
  const ordersList =
    orders &&
    orders.map((item, index) => {
      const { id } = item;

      return (
        <Grid item key={id} xs={12}>
          <OrderForCart {...item} index={index} />
        </Grid>
      );
    });

  return (
    <Grid container spacing={1} className={clsx(container, "OrdersForCart")}>
      {ordersList}
    </Grid>
  );
};

OrdersForCart.propTypes = propTypes;
OrdersForCart.defaultProps = defaultProps;

export default OrdersForCart;
export {
  propTypes as OrdersForCartPropTypes,
  defaultProps as OrdersForCartDefaultProps
};
