/**
 * Component short description
 *
 * @see FilterCategory.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import FilterListItem from "../../FilterListItem/FilterListItem";
import { useFilters } from "../../../hooks";
import {
  getSelectedArrayValues,
  getArrayOfKeys,
} from "../../../hooks/useFilters";
import FilterChips from "../../FilterChips/FilterChips";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./FilterCategory.data";

/**
 * Imports Material UI components
 */
import { ListItemText } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./FilterCategory.lang.ro-ro";
import { hu_hu } from "./FilterCategory.lang.hu-hu";
import { en_us } from "./FilterCategory.lang.en-us";
import { de_de } from "./FilterCategory.lang.de-de";

i18n.addResourceBundle("ro-RO", "FilterCategory", ro_ro);
i18n.addResourceBundle("hu-HU", "FilterCategory", hu_hu);
i18n.addResourceBundle("en-US", "FilterCategory", en_us);
i18n.addResourceBundle("de-DE", "FilterCategory", de_de);

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const FilterCategory = (props) => {
  const { filterListItem, defaultValue } = props;
  const { t } = useTranslation("FilterCategory");

  /**
   * Handles the filters
   */
  const { filters, setFilters, data } = useFilters();
  const { categories } = data;

  const selected = getSelectedArrayValues(
    "id",
    filters.category?.filterValue || defaultValue,
    categories
  );

  const selectHandler = (data) => {
    const {
      target: { value },
    } = data;
    if (value[0] === "empty") {
      return;
    }

    setFilters((prevState) => {
      return {
        ...prevState,
        category: {
          filterValue: getArrayOfKeys("id", value),
          label: getArrayOfKeys("name", value),
        },
      };
    });
  };

  return (
    <FilterListItem {...filterListItem} primary={t("name")}>
      <Select
        multiple
        name="category"
        // open={open}
        // onOpen={handleOpenSelect}
        // onClose={handleCloseSelect}
        value={selected}
        onChange={selectHandler}
        fullWidth
        displayEmpty
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <FilterChips selected={selected} emptyText={t("placeholder")} />
        )}
      >
        {categories.map((c) => {
          return (
            <MenuItem key={c.id} value={c}>
              <ListItemText primary={c.name} />
            </MenuItem>
          );
        })}
      </Select>
    </FilterListItem>
  );
};

FilterCategory.propTypes = propTypes;
FilterCategory.defaultProps = defaultProps;

export default FilterCategory;
export {
  propTypes as FilterCategoryPropTypes,
  defaultProps as FilterCategoryDefaultProps,
};
