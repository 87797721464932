/**
 * Component short description
 *
 * @see OrderItemForCart.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState } from "react";
import clsx from "clsx";
import shortid from "shortid";

/**
 * Imports other components and hooks
 */
import Card2, { Card2SingleRowStylesWithActions } from "../../Card2";
import { TextSection, Actions, TextPrice, TextOverline } from "../../Theme";
import { useUnsplash } from "../../../hooks";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./OrderItemForCart.data";

/**
 * Imports Material UI components
 */
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./OrderItemForCart.lang.ro-ro";
import { hu_hu } from "./OrderItemForCart.lang.hu-hu";
import { en_us } from "./OrderItemForCart.lang.en-us";
import { de_de } from "./OrderItemForCart.lang.de-de";

i18n.addResourceBundle("ro-RO", "OrderItemForCart", ro_ro);
i18n.addResourceBundle("hu-HU", "OrderItemForCart", hu_hu);
i18n.addResourceBundle("en-US", "OrderItemForCart", en_us);
i18n.addResourceBundle("de-DE", "OrderItemForCart", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  },

  select: {
    width: "100%"
  }
}));

/**
 * Displays the component
 */
const OrderItemForCart = props => {
  const { id, name, destinationName, price, quantity, image, index } = props;
  const { container, select } = useStyles(props);
  const { t } = useTranslation("OrderItemForCart");

  /**
   * Loads unsplash images
   */
  const unsplashImages = useUnsplash({
    keyword: "Local market",
    page: index + 1,
    photos: 1,
    params: { orientation: "landscape" }
  });

  const image2 = unsplashImages ? unsplashImages[0] : image;

  /**
   * Handles the select box
   */
  const [quantity2, setQuantity2] = useState(quantity);

  const handleChangeQuantity = event => {
    setQuantity2(event.target.value);
  };

  /**
   * Displays the select box items
   */
  const quantityList = Array(10)
    .fill(1)
    .map((item, index) => {
      return (
        <MenuItem value={index + 1} key={shortid.generate()}>
          {index + 1} {t("Unit")}
        </MenuItem>
      );
    });

  /**
   * Displays the select box
   */
  const quantitySelect = (
    <FormControl variant="outlined" className={clsx(select)}>
      <InputLabel id="cart-order-quantity-select-label">
        {t("Select quantity")}
      </InputLabel>
      <Select
        label={t("Select quantity")}
        labelId="cart-order-quantity-select-label"
        id="cart-order-quantity-select"
        value={quantity2}
        onChange={handleChangeQuantity}
      >
        {quantityList}
      </Select>
    </FormControl>
  );

  /**
   * Displays the order item
   */
  const cardContent1 = (
    <TextSection
      description={name}
      overline={destinationName}
      price={`${price} RON`}
    />
  );

  const cardActions1 = (
    <Actions
      items={[
        { id: shortid.generate(), action: quantitySelect },
        {
          id: shortid.generate(),
          action: <Button variant="outlined">{t("Delete item")}</Button>
        }
      ]}
      orientation="horizontal"
      fullGridItems
    />
  );

  const cardWithMedia1 = {
    image: image2,
    content: cardContent1,
    actions: cardActions1,
    customStyles: Card2SingleRowStylesWithActions({
      ...props,
      alignItems: "center"
    })
  };

  return <Card2 {...cardWithMedia1} />;
};

OrderItemForCart.propTypes = propTypes;
OrderItemForCart.defaultProps = defaultProps;

export default OrderItemForCart;
export {
  propTypes as OrderItemForCartPropTypes,
  defaultProps as OrderItemForCartDefaultProps
};
