/**
 * Defines the data requirements for the component
 *
 * @see LocalMarketIntro.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { LocalMarketPropTypes, LocalMarketDefaultProps } from "../LocalMarket";

/**
 * Defines the prop types
 */
const propTypes = {
  ...LocalMarketPropTypes
};

/**
 * Defines the default props
 */
const defaultProps = {
  ...LocalMarketDefaultProps
};

export { propTypes, defaultProps };
