/**
 * Displays an Attraction as a thumb
 *
 * @see AttractionAsThumb.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import Card2 from "../../Card2";
import { useUnsplash } from "../../../hooks";
import { TextThumbnail } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./AttractionAsThumb.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./AttractionAsThumb.lang.ro-ro";
import { hu_hu } from "./AttractionAsThumb.lang.hu-hu";
import { en_us } from "./AttractionAsThumb.lang.en-us";
import { de_de } from "./AttractionAsThumb.lang.de-de";

i18n.addResourceBundle("ro-RO", "AttractionAsThumb", ro_ro);
i18n.addResourceBundle("hu-HU", "AttractionAsThumb", hu_hu);
i18n.addResourceBundle("en-US", "AttractionAsThumb", en_us);
i18n.addResourceBundle("de-DE", "AttractionAsThumb", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {}
}));

/**
 * Displays the component
 */
const AttractionAsThumb = props => {
  const { name, slug, image, description, index } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("AttractionAsThumb");

  /**
   * Loads unsplash images
   */
  const unsplashImages = useUnsplash({
    keyword: "Attractions",
    page: index + 1,
    photos: 1,
    params: { orientation: "landscape" }
  });

  /**
   * Loads the current route
   */
  const match = useRouteMatch();
  const { url } = match;

  const cardContent = (
    <TextThumbnail
      to={`${url}/${slug}`}
      title={name}
      description={description}
    />
  );

  const cardWithMedia = {
    image: unsplashImages ? unsplashImages[0] : image,
    images: null,
    content: cardContent,
    customStyles: useStyles(props)
  };

  return <Card2 {...cardWithMedia} />;
};

AttractionAsThumb.propTypes = propTypes;
AttractionAsThumb.defaultProps = defaultProps;

export default AttractionAsThumb;
export {
  propTypes as AttractionAsThumbPropTypes,
  defaultProps as AttractionAsThumbDefaultProps
};
