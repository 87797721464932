/**
 * Defines the data requirements for the component
 *
 * @see Icon.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";

/**
 * Imports other components and hooks
 */
import FacebookIcon from "@material-ui/icons/Facebook";

/**
 * Defines the prop types
 */
const propTypes = {
  /**
   * An unique ID for an icon
   * - Used when icons are displayed in a list
   */
  id: PropTypes.string,
  /**
   * The icon element, usually an SVG icon (Ex. <FacebookIcon/>)
   */
  icon: PropTypes.element,
  /**
   * The icon name (Ex. Facebook)
   */
  name: PropTypes.string,
  /**
   * The (external) link where the icon points (Ex. https://facebook.com)
   */
  url: PropTypes.string,
  /**
   * The (internal) link where the icon points (Ex. /events)
   */
  path: PropTypes.string,
  /**
   * A component associated to the icon. (Ex. <Calendar/>)
   * - Usually when `name`, `url` is set the `component` is unset, or vice versa
   */
  component: PropTypes.element,
  /**
   * If the icon is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Instead of links / component a callback will be performed on click
   */
  callback: PropTypes.func,
  /**
   * Displays a divider
   * @see https://material-ui.com/api/divider/
   */
  divider: PropTypes.shape({
    variant: PropTypes.oneOf(["fullWidth", "inset", "middle"])
  }),
  /**
   * If the icon is highlighted
   */
  highlighted: PropTypes.bool,
  /**
   * If the name is displayed
   */
  displayName: PropTypes.bool,
  /**
   * If the name is should be translated
   */
  doTranslateName: PropTypes.bool
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: shortid.generate(),
  icon: null,
  name: null,
  url: null,
  component: null,
  disabled: false,
  callback: null,
  highlighted: false,
  displayName: true,
  doTranslateName: false
};

export { propTypes, defaultProps };
