/**
 * Defines the data requirements for the component
 *
 * @see Header.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";

/**
 * Imports other components and hooks
 */
import LanguageSelector from "../LanguageSelector";
import AccountMenu from "../_account/AccountMenu";

import DateRangeIcon from "@material-ui/icons/DateRange";
import LanguageIcon from "@material-ui/icons/Language";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SearchIcon from "@material-ui/icons/Search";
import AccountBoxIcon from "@material-ui/icons/AccountBox";

/**
 * Imports prop types from other components
 */
import { LogoPropTypes, LogoDefaultProps } from "../Logo";
import { MenuPropTypes, MenuDefaultProps } from "../Menu";
import { MenuItemsPropTypes } from "../MenuItems";
import { IconsPropTypes } from "../Icons";

/**
 * Defines the prop types
 */
const propTypes = {
  logo: PropTypes.shape(LogoPropTypes),
  ...MenuPropTypes,
  menuItems: PropTypes.shape(MenuItemsPropTypes),
  menuIcons: PropTypes.shape(IconsPropTypes),
  currentPage: PropTypes.string
};

/**
 * Defines the default props
 */
const defaultProps = {
  logo: LogoDefaultProps,
  ...MenuDefaultProps,
  menuItems: {
    items: [
      { id: shortid.generate(), name: "Home" },
      { id: shortid.generate(), name: "Destinations" },
      { id: shortid.generate(), name: "Local Market" },
      { id: shortid.generate(), name: "Stay" },
      { id: shortid.generate(), name: "News" },
      { id: shortid.generate(), name: "Contact" }
    ],
    activeItem: ""
  },
  menuIcons: {
    items: [
      {
        id: shortid.generate(),
        icon: <DateRangeIcon />,
        name: "Events",
        path: "/events",
        doTranslateName: true
      },
      {
        id: shortid.generate(),
        icon: <LanguageIcon />,
        component: <LanguageSelector />
      },
      {
        id: shortid.generate(),
        icon: <SearchIcon />,
        name: "Search",
        path: "/search",
        doTranslateName: true
      },
      {
        id: shortid.generate(),
        icon: <ShoppingCartIcon />,
        name: "Cart",
        path: "/cart",
        doTranslateName: true
      },
      {
        // NOTE: This gives a props warning. Above the <LanguageSelector/> gives no warnings ate all
        id: shortid.generate(),
        component: <AccountMenu />
      }
    ]
  },
  currentPage: ""
};

export { propTypes, defaultProps };
