const hu_hu = {
  MenuItems: "MenuItems (hu_hu)",
  Home: "Kezdolap",
  Destinations: "Uticelok",
  "Local Market": "Helyi termemek",
  Stay: "Szallas",
  News: "Hirek",
  Contact: "Elerhetoseg"
};

export { hu_hu };
