/**
 * Component short description
 *
 * @see ProductAsThumb.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import Card2 from "../../Card2";
import { useUnsplash } from "../../../hooks";
import { TextThumbnail } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./ProductAsThumb.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./ProductAsThumb.lang.ro-ro";
import { hu_hu } from "./ProductAsThumb.lang.hu-hu";
import { en_us } from "./ProductAsThumb.lang.en-us";
import { de_de } from "./ProductAsThumb.lang.de-de";

i18n.addResourceBundle("ro-RO", "ProductAsThumb", ro_ro);
i18n.addResourceBundle("hu-HU", "ProductAsThumb", hu_hu);
i18n.addResourceBundle("en-US", "ProductAsThumb", en_us);
i18n.addResourceBundle("de-DE", "ProductAsThumb", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: { ...theme.custom.grid.removeNegativeMargin }
}));

/**
 * Displays the component
 */
const ProductAsThumb = props => {
  const { name, slug, image, price, destinationName, index } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("ProductAsThumb");

  /**
   * Loads unsplash images
   */
  const unsplashImages = useUnsplash({
    keyword: "Vegetables",
    page: index + 1,
    photos: 1,
    params: { orientation: "landscape" }
  });

  /**
   * Loads the current route
   */
  const match = useRouteMatch();
  const { url } = match;

  /**
   * Displays the product as card
   */
  const price2 = `${price} RON / Unit`;

  const cardContent1 = (
    <TextThumbnail
      overline={destinationName}
      to={`${url}/${slug}`}
      title={name}
      price={price2}
    />
  );

  const cardWithMedia1 = {
    image: unsplashImages ? unsplashImages[0] : image,
    images: null,
    carousel: null,
    content: cardContent1,
    actions: null,
    customStyles: useStyles(props)
  };

  return <Card2 {...cardWithMedia1} />;
};

ProductAsThumb.propTypes = propTypes;
ProductAsThumb.defaultProps = defaultProps;

export default ProductAsThumb;
export {
  propTypes as ProductAsThumbPropTypes,
  defaultProps as ProductAsThumbDefaultProps
};
