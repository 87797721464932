/**
 * Displays a category as a Hero
 *
 * @see CategoryAsHero.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Card2, { Card2SingleRowStyles } from "../../Card2";
import { useUnsplash } from "../../../hooks";
import { TextSection } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./CategoryAsHero.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./CategoryAsHero.lang.ro-ro";
import { hu_hu } from "./CategoryAsHero.lang.hu-hu";
import { en_us } from "./CategoryAsHero.lang.en-us";
import { de_de } from "./CategoryAsHero.lang.de-de";

i18n.addResourceBundle("ro-RO", "CategoryAsHero", ro_ro);
i18n.addResourceBundle("hu-HU", "CategoryAsHero", hu_hu);
i18n.addResourceBundle("en-US", "CategoryAsHero", en_us);
i18n.addResourceBundle("de-DE", "CategoryAsHero", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: { ...theme.custom.grid.removeNegativeMargin }
}));

/**
 * Displays the component
 */
const CategoryAsHero = props => {
  const { name, image, images, alternateName, description, index } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("CategoryAsHero");

  /**
   * Loads an unsplash image
   */
  const unsplashImage = useUnsplash({
    keyword: "Transilvania",
    page: index + 1,
    photos: 1,
    params: { orientation: "landscape" }
  });

  const cardContent = (
    <TextSection
      title={name}
      subtitle={alternateName}
      description={description}
    />
  );

  const cardWithMedia = {
    // TODO: Figure out what those two images in the design means
    image: unsplashImage ? unsplashImage[0] : image,
    content: cardContent,
    customStyles: Card2SingleRowStyles({ ...props, breakpoint: "lg" })
  };

  return (
    <Grid container className={clsx(container, "CategoryAsHero")}>
      <Grid item xs={12}>
        <Card2 {...cardWithMedia} />
      </Grid>
    </Grid>
  );
};

CategoryAsHero.propTypes = propTypes;
CategoryAsHero.defaultProps = defaultProps;

export default CategoryAsHero;
export {
  propTypes as CategoryAsHeroPropTypes,
  defaultProps as CategoryAsHeroDefaultProps
};
