/**
 * Defines the data requirements for the component
 *
 * @see Booking.md for details
 */
import PropTypes from "prop-types";
import shortid from "shortid";

/**
 * Imports prop types from other components
 */
import {
  ImageResponsivePropTypes,
  ImageResponsiveDefaultProps,
  ImageResponsivePlaceholderCom169
} from "../../ImageResponsive";

/**
 * Defines the prop types
 *
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/Stay/getBookings
 */
const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  destinationName: PropTypes.string,
  phone: PropTypes.string,
  price: PropTypes.number,
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
  image: PropTypes.shape(ImageResponsivePropTypes)
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: shortid.generate(),
  name: "Accommodation Name",
  destinationName: "Destination name",
  phone: "0754851246",
  price: 100,
  checkIn: "2020-07-20T00:00:00.000Z",
  checkOut: "2020-07-24T00:00:00.000Z",
  image: ImageResponsivePlaceholderCom169
};

export { propTypes, defaultProps };
