/**
 * Component short description
 *
 * @see Booking.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import shortid from "shortid";

/**
 * Imports other components and hooks
 */
import Card2, { Card2SingleRowStylesWithActions } from "../../Card2";
import { TextSection, TextPrice, TextDefault, Actions } from "../../Theme";
import { useUnsplash } from "../../../hooks";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Booking.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Booking.lang.ro-ro";
import { hu_hu } from "./Booking.lang.hu-hu";
import { en_us } from "./Booking.lang.en-us";
import { de_de } from "./Booking.lang.de-de";

i18n.addResourceBundle("ro-RO", "Booking", ro_ro);
i18n.addResourceBundle("hu-HU", "Booking", hu_hu);
i18n.addResourceBundle("en-US", "Booking", en_us);
i18n.addResourceBundle("de-DE", "Booking", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  }
}));

/**
 * Displays the component
 */
const Booking = props => {
  const {
    id,
    name,
    destinationName,
    price,
    checkIn,
    checkOut,
    image,
    index
  } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("Booking");

  /**
   * Loads unsplash images
   */
  const unsplashImages = useUnsplash({
    keyword: "Accommodation",
    page: index + 1,
    photos: 1,
    params: { orientation: "landscape" }
  });

  const image2 = unsplashImages ? unsplashImages[0] : image;

  /**
   * Displays order details
   */
  const date = `${checkIn} - ${checkOut}`;

  const price2 = <TextPrice text={`From ${price} RON / Night`} />;

  const cardContent1 = (
    <TextSection description={name} overline={destinationName} />
  );

  const cardActions1 = (
    <Actions
      items={[
        { id: shortid.generate(), action: price2 },
        { id: shortid.generate(), action: date }
      ]}
      orientation="vertical"
    />
  );

  const cardWithMedia1 = {
    image: image2,
    content: cardContent1,
    actions: cardActions1,
    customStyles: Card2SingleRowStylesWithActions({
      ...props
    })
  };

  return <Card2 {...cardWithMedia1} />;
};

Booking.propTypes = propTypes;
Booking.defaultProps = defaultProps;

export default Booking;
export { propTypes as BookingPropTypes, defaultProps as BookingDefaultProps };
