/**
 * Defines the data requirements for the component
 *
 * @see Faq.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import Lorem from "react-lorem-component";

/**
 * Imports prop types from other components
 */
import { QuestionsPropTypes, QuestionsDefaultProps } from "../Questions";

/**
 * Defines the prop types
 */
const propTypes = {
  description: PropTypes.stirng,
  questions: PropTypes.shape(QuestionsPropTypes)
};

/**
 * Defines the default props
 */
const defaultProps = {
  description: <Lorem seed="0" count="1" sentenceUpperBound="14" />,
  questions: QuestionsDefaultProps
};

export { propTypes, defaultProps };
