/**
 * Defines the data requirements for the component
 *
 * @see OrderHistory.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";

/**
 * Imports prop types from other components
 */
import {
  OrderForAccountPropTypes,
  OrderForAccountDefaultProps
} from "../OrderForAccount";
import { OrderItemDefaultProps } from "../OrderItem";

/**
 * Defines the prop types
 */
const propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape(OrderForAccountPropTypes))
};

/**
 * Defines the default props
 */
const defaultProps = {
  orders: Array(5)
    .fill(OrderForAccountDefaultProps)
    .map((item, index) => {
      return {
        ...item,
        id: shortid.generate(),
        seller: `Seller ${index + 1}`,
        totalPrice: Math.floor(Math.random() * Math.floor(100)) + 1,
        items: Array(3)
          .fill(OrderItemDefaultProps)
          .map((item, index) => {
            return {
              ...item,
              id: shortid.generate(),
              name: `Order item ${index + 1}`,
              destinationName: `Destination name ${index + 1}`,
              price: Math.floor(Math.random() * Math.floor(100)) + 1,
              quantity: Math.floor(Math.random() * Math.floor(10)) + 1
            };
          })
      };
    })
};

export { propTypes, defaultProps };
