/**
 * Defines the styles for the component
 *
 * @see Card2.md for details
 */

/**
 * Imports the styling library
 */
import { makeStyles } from "@material-ui/core";

/**
 * Defines the styles
 * - Each style has to be standalone, or, complete in other words
 * - At this moment styles cannot be merged like {...defaultStyles, ...anotherStyles}
 * - So `anotherStyles` has to include and spread `defaultStyles`
 */

/**
 * The default style
 * - The card is displayed vertically
 */
const defaultStyles = makeStyles(theme => ({
  card: {
    width: "100%",
    /**
     * Padding is set at card level
     */
    padding: theme.spacing(2)
  },

  cardContainer: {
    /**
     * Removes the negative margin injected by MUI
     */
    ...theme.custom.grid.removeNegativeMargin,
    flexDirection: "column",
    flexWrap: "nowrap"
  },

  contentContainer: {
    /**
     * This padding is injected by MUI
     */
    ["& .MuiCardContent-root:last-child"]: {
      paddingBottom: 0
    }
  },

  imageContainer: {
    /**
     * Since padding is managed at card level we'll have to add here some spacing
     */
    ["& + .Card2ContentContainer"]: {
      marginTop: theme.spacing(2)
    }
  },

  cardContent: props => ({
    /**
     * Keep 45-60 chars per row for long text
     */
    ["& .MuiTypography-subtitle1, & .MuiTypography-subtitle2, & .MuiTypography-body1, & .MuiTypography-body2"]: {
      maxWidth: props.removeCardContentMaxWidth
        ? "auto"
        : theme.custom.typography.maxTextWidth
    },

    /**
     * Padding is set at card level
     */
    padding: 0,

    /**
     * Since padding is managed at card level we'll have to add here some spacing
     */
    ["& + .CardActions"]: {
      marginTop: theme.spacing(1)
    }
  }),

  cardActions: {
    /**
     * Padding is set at card level
     */
    padding: 0,

    ["& > .MuiButton-root"]: {
      /**
       * If the first child is button then ...
       */
      marginLeft: theme.spacing(1)
    }
  }
}));

/**
 * Single row style
 * - From a breakpoint and up the card is displayed in a single row
 */
const singleRowStyles = makeStyles(theme => ({
  ...defaultStyles,

  cardContainer: props => ({
    [theme.breakpoints.up(props.breakpoint)]: {
      flexDirection: "row",
      flexWrap: "nowrap"
    }
  }),

  imageContainer: props => ({
    maxWidth: props.imageResponsiveWidth ? props.imageResponsiveWidth : "auto",

    [theme.breakpoints.up(props.breakpoint)]: {
      maxWidth: props.imageResponsiveWidth
        ? props.imageResponsiveWidth
        : props.imageWidth
        ? props.imageWidth
        : "auto",

      ["& + .Card2ContentContainer"]: {
        marginLeft: theme.spacing(2),
        marginTop: 0
      }
    }
  }),

  contentContainer: props => ({
    [theme.breakpoints.up(props.breakpoint)]: {
      maxWidth: props.contentWidth ? props.contentWidth : "auto"
    }
  })
}));

/**
 * Single row style, inverted
 * - When the card is displayed in a single row the content comes first then the image
 */
const singleRowStylesInverted = makeStyles(theme => ({
  // // NOTE: This won't work due to props ...singleRowStyles(props),
  // so copy pasting ...

  ...defaultStyles,

  cardContainer: props => ({
    [theme.breakpoints.up(props.breakpoint)]: {
      flexDirection: "row",
      flexWrap: "nowrap"
    }
  }),

  imageContainer: props => ({
    ["& + .Card2ContentContainer"]: {
      //marginBottom: theme.spacing(2)
    },

    [theme.breakpoints.up(props.breakpoint)]: {
      order: 2,
      maxWidth: props.imageWidth ? props.imageWidth : "auto",

      ["& + .Card2ContentContainer"]: {
        marginRight: theme.spacing(2),
        marginTop: 0
      }
    }
  }),

  contentContainer: props => ({
    [theme.breakpoints.up(props.breakpoint)]: {
      maxWidth: props.contentWidth ? props.contentWidth : "auto"
    }
  }),

  cardContent: props => ({
    [theme.breakpoints.up(props.breakpoint)]: {
      order: 1
    }
  })
}));

/**
 * Single row styles when both the image, content and actions are in a single row
 * - props had to be hardwired, useMediaQuery not always works with this construct
 */
const singleRowStylesWithActions = makeStyles(theme => ({
  ...defaultStyles,

  cardContainer: props => ({
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      flexWrap: "nowrap"
    }
  }),

  imageContainer: props => ({
    [theme.breakpoints.up("sm")]: {
      flexBasis: "40%",

      ["& + .Card2ContentContainer"]: {
        marginTop: 0,
        marginLeft: theme.spacing(2)
      }
    },

    [theme.breakpoints.up("lg")]: {
      flexBasis: "25%"
    }
  }),

  contentContainer: props => ({
    [theme.breakpoints.up("sm")]: {
      flexBasis: "60%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: props.alignItems ? props.alignItems : "flex-start"
    },

    [theme.breakpoints.up("lg")]: {
      flexBasis: "75%"
    }
  }),

  cardContent: props => ({
    [theme.breakpoints.up("sm")]: {
      width: "60%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "75%"
    }
  }),

  cardActions: props => ({
    [theme.breakpoints.up("sm")]: {
      width: "40%",
      marginTop: "0 !important"
    },
    [theme.breakpoints.up("lg")]: {
      width: "25%"
    }
  })
}));

/**
 * Single row style for localMarket
 * - on mobile the content is displayed first, then the carousel
 */
const localMarketAsHeroStyles = makeStyles(theme => ({
  ...defaultStyles,

  cardContainer: props => ({
    [theme.breakpoints.up(props.breakpoint)]: {
      flexDirection: "row",
      flexWrap: "nowrap"
    }
  }),

  imageContainer: props => ({
    order: 2,

    [theme.breakpoints.up(props.breakpoint)]: {
      order: 1,
      maxWidth: props.imageWidth ? props.imageWidth : "auto"
    }
  }),

  contentContainer: props => ({
    [theme.breakpoints.up(props.breakpoint)]: {
      order: 2,
      maxWidth: props.contentWidth ? props.contentWidth : "auto"
    }
  })
}));

const localMarketAsHeroStylesInverted = makeStyles(theme => ({
  ...defaultStyles,

  cardContainer: props => ({
    [theme.breakpoints.up(props.breakpoint)]: {
      flexDirection: "row",
      flexWrap: "nowrap"
    }
  }),

  imageContainer: props => ({
    order: 2,
    width: "100%",

    [theme.breakpoints.up(props.breakpoint)]: {
      maxWidth: props.imageWidth ? props.imageWidth : "auto"
    }
  }),

  contentContainer: props => ({
    [theme.breakpoints.up(props.breakpoint)]: {
      maxWidth: props.contentWidth ? props.contentWidth : "auto"
    }
  }),

  cardContent: props => ({
    order: 1
  })
}));

export {
  defaultStyles,
  singleRowStyles,
  singleRowStylesInverted,
  localMarketAsHeroStyles,
  localMarketAsHeroStylesInverted,
  singleRowStylesWithActions
};
