/**
 * Component short description
 *
 * @see RegisterForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect } from "react";
import clsx from "clsx";
import * as yup from "yup";
import { useForm } from "react-hook-form";

/**
 * Imports other components and hooks
 */
import useValidationMessages from "../../../hooks/useValidationMessages";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./RegisterForm.data";

/**
 * Imports Material UI components
 */
import {
  TextField,
  Button,
  Typography,
  makeStyles,
  Link
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./RegisterForm.lang.ro-ro";
import { hu_hu } from "./RegisterForm.lang.hu-hu";
import { en_us } from "./RegisterForm.lang.en-us";
import { de_de } from "./RegisterForm.lang.de-de";

i18n.addResourceBundle("ro-RO", "RegisterForm", ro_ro);
i18n.addResourceBundle("hu-HU", "RegisterForm", hu_hu);
i18n.addResourceBundle("en-US", "RegisterForm", en_us);
i18n.addResourceBundle("de-DE", "RegisterForm", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin
  },

  alignRight: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "flex-end"
    }
  }
}));

/**
 * Displays the component
 */
const RegisterForm = props => {
  const { defaultValues, onSubmit, openDialog } = props;
  const { container, alignRight } = useStyles();

  const { t } = useTranslation("RegisterForm");
  const { trans } = useValidationMessages();

  const schema = yup.object().shape({
    name: yup.string().required(trans("required")),
    email: yup
      .string()
      .required(trans("required"))
      .email(trans("email_invalid")),
    password: yup
      .string()
      .required(trans("required"))
      .min(6, trans("password_min_length")),
    password_confirmation: yup
      .string()
      .required(trans("required"))
      .test("passwords-match", trans("passwords_not_match"), function(value) {
        return this.parent.password === value;
      })
  });

  const { register, errors, reset, handleSubmit, formState } = useForm({
    defaultValues,
    validationSchema: schema
  });

  const { isValid, isSubmitted } = formState;

  const handleFormSubmit = (...args) => {
    reset(args[0]);
    onSubmit(...args);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <Grid container spacing={2} className={clsx(container)}>
        <Grid item xs={12}>
          Short description
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="text"
            inputRef={register}
            fullWidth
            variant="outlined"
            name="name"
            label={t("name")}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="email"
            inputRef={register}
            fullWidth
            variant="outlined"
            name="email"
            label={t("email")}
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="password"
            inputRef={register}
            fullWidth
            variant="outlined"
            name="password"
            label={t("password")}
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="password"
            inputRef={register}
            fullWidth
            variant="outlined"
            name="password_confirmation"
            label={t("confirm_password")}
            error={Boolean(errors.password_confirmation)}
            helperText={errors.password_confirmation?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" display="inline">
            {t("conditions_part_1")}
          </Typography>
          <Typography
            variant="body2"
            fontWeight="fontWeightBold"
            display="inline"
          >
            {t("conditions_part_2")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={!isValid && isSubmitted}
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
          >
            {t("submit")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography display="inline" variant="body2">
            {t("Already have an account?")}
          </Typography>
          &nbsp;
          <Link
            component="button"
            variant="body2"
            onClick={() => openDialog("login")}
          >
            {t("Sign in!")}
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};

RegisterForm.propTypes = propTypes;
RegisterForm.defaultProps = defaultProps;

export default RegisterForm;
export {
  propTypes as RegisterFormPropTypes,
  defaultProps as RegisterFormDefaultProps
};
