const en_us = {
  RegisterForm: "RegisterForm",
  name: "Your name",
  email: "Email address",
  password: "Password",
  confirm_password: "Re-enter password",
  submit: "Join us",
  conditions_part_1: "By creating an account you agree to Pineyard's ",
  conditions_part_2: "Conditions of Use & Sale.",
};

export { en_us };
