/**
 * Component short description
 *
 * @see FilterChips.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./FilterChips.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { ro_ro } from "./FilterChips.lang.ro-ro";
import { hu_hu } from "./FilterChips.lang.hu-hu";
import { en_us } from "./FilterChips.lang.en-us";
import { de_de } from "./FilterChips.lang.de-de";

i18n.addResourceBundle("ro-RO", "FilterChips", ro_ro);
i18n.addResourceBundle("hu-HU", "FilterChips", hu_hu);
i18n.addResourceBundle("en-US", "FilterChips", en_us);
i18n.addResourceBundle("de-DE", "FilterChips", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

/**
 * Displays the component
 */
const FilterChips = (props) => {
  const { selected, emptyText } = props;
  const { chip, chips } = useStyles();

  return (
    <div className={chips}>
      {selected.length
        ? selected.map((value) => (
            <Chip
              size="small"
              key={value.id}
              label={value.name}
              className={chip}
            />
          ))
        : emptyText}
    </div>
  );
};

FilterChips.propTypes = propTypes;
FilterChips.defaultProps = defaultProps;

export default FilterChips;
export {
  propTypes as FilterChipsPropTypes,
  defaultProps as FilterChipsDefaultProps,
};
