/**
 * Component short description
 *
 * @see useRequests.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState } from "react";
import queryString from "query-string";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./useRequests.data";
import { useSnackbar } from "notistack";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./useRequests.lang.ro-ro";
import { hu_hu } from "./useRequests.lang.hu-hu";
import { en_us } from "./useRequests.lang.en-us";
import { de_de } from "./useRequests.lang.de-de";

i18n.addResourceBundle("ro-RO", "useRequests", ro_ro);
i18n.addResourceBundle("hu-HU", "useRequests", hu_hu);
i18n.addResourceBundle("en-US", "useRequests", en_us);
i18n.addResourceBundle("de-DE", "useRequests", de_de);

const apiUrl = "https://api.borsecturismregional.web-staging.eu/api/";

const checkResponse = res => {
  if (res && res.status >= 200 && res.status < 300) {
    return res.json();
  } else {
    throw res;
  }
};

const parseLanguage = locale => {
  const [lng] = locale.split("-");
  return lng;
};

const useRequests = props => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("useRequests");

  const handleNetworkError = () => {
    enqueueSnackbar(t("network_error"), { variant: "error" });
  };

  const get = async (path, params = {}, customHeaders = {}) => {
    const queryParams = queryString.stringify(params, { arrayFormat: "comma" });
    const res = await fetch(`${apiUrl}${path}?${queryParams}`, {
      headers: {
        "Accept-Language": parseLanguage(i18n.language),
        ...customHeaders
      }
    }).catch(error => {
      console.error(error);
      handleNetworkError();
    });
    return checkResponse(res);
  };

  const post = async (path, body, customHeaders = {}) => {
    const res = await fetch(`${apiUrl}${path}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": parseLanguage(i18n.language),
        ...customHeaders
      },
      body: JSON.stringify(body)
    }).catch(error => {
      console.error(error);
      handleNetworkError();
    });
    return checkResponse(res);
  };

  const put = async (path, body = {}, customHeaders = {}) => {
    const res = await fetch(`${apiUrl}${path}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": parseLanguage(i18n.language),
        ...customHeaders
      },
      body: JSON.stringify(body)
    }).catch(error => {
      console.error(error);
      handleNetworkError();
    });
    return checkResponse(res);
  };

  const deleteReq = async (path, customHeaders = {}) => {
    const res = await fetch(`${apiUrl}${path}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": parseLanguage(i18n.language),
        ...customHeaders
      }
    }).catch(error => {
      console.error(error);
      handleNetworkError();
    });
    return checkResponse(res);
  };

  return { get, post, put, deleteReq };
};

useRequests.propTypes = propTypes;
useRequests.defaultProps = defaultProps;

export default useRequests;
export { useRequests };
