/**
 * Displays a responsive image
 * - Supports the resolution switching mechanism with `srcset` and `sizes`
 * - For art direction the `<Picture/>` component should be used
 *
 * @see https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images
 */

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import shortid from "shortid";

/**
 * Imports other components and hooks
 */
import Image, { ImagePropTypes, ImageDefaultProps } from "../Image";

/**
 * Defines the prop types
 */
const propTypes = {
  /**
   * The basic image props
   */
  ...ImagePropTypes,
  /**
   * A set of image sources
   */
  srcSet: PropTypes.string,
  /**
   * A set of source sizes
   */
  sizes: PropTypes.string
};

/**
 * Defines the default props
 */
const defaultProps = {
  ...ImageDefaultProps,
  srcSet: null,
  sizes: null
};

/**
 * Pre-defines various placeholders
 */
const imagePlaceholderCom169 = {
  id: shortid.generate(),
  srcSet:
    "https://via.placeholder.com/160x90/fff?text=... 160w, https://via.placeholder.com/320x180/fff?text=... 320w, https://via.placeholder.com/640x360/fff?text=... 640w, https://via.placeholder.com/1280x720/fff?text=... 1280w",
  alt: "Placeholder image (16:9)",
  aspectRatio: 9 / 16
};

const imagePlaceholderCom11 = {
  id: shortid.generate(),
  srcSet:
    "https://via.placeholder.com/100x100/fff?text=... 100w, https://via.placeholder.com/200x200/fff?text=... 200w, https://via.placeholder.com/400x400/fff?text=... 400w",
  alt: "Placeholder image (1:1)",
  aspectRatio: 1 / 1
};

const imagePlaceholderCom169Gray = {
  id: shortid.generate(),
  srcSet:
    "https://via.placeholder.com/160x90?text=160x90+16:9 160w, https://via.placeholder.com/320x180?text=320x180+16:9 320w, https://via.placeholder.com/640x360?text=640x360+16:9 640w, https://via.placeholder.com/1280x720?text=1280x720+16:9 1280w",
  alt: "Placeholder image (16:9)",
  aspectRatio: 9 / 16
};

const imagePlaceholderCom11Gray = {
  id: shortid.generate(),
  srcSet:
    "https://via.placeholder.com/100x100?text=100x100+1:1 100w, https://via.placeholder.com/200x200?text=200x200+1:1 200w, https://via.placeholder.com/400x400?text=400x400+1:1 400w",
  alt: "Placeholder image (1:1)",
  aspectRatio: 1 / 1
};

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  image: {
    /**
     * When the image is responsive this is a must
     */
    width: "100%"
  }
}));

/**
 * Displays the component
 *
 * - This is a lighweight component.
 * - A responsive image is nothing more than an `<Image />` with `srcSet` and `sizes` set
 */
const ImageResponsive = props => {
  const { image } = useStyles(props);
  return <Image {...props} />;
};

ImageResponsive.propTypes = propTypes;
ImageResponsive.defaultProps = defaultProps;

export default ImageResponsive;
export {
  propTypes as ImageResponsivePropTypes,
  defaultProps as ImageResponsiveDefaultProps,
  imagePlaceholderCom169 as ImageResponsivePlaceholderCom169,
  imagePlaceholderCom11 as ImageResponsivePlaceholderCom11
};
