/**
 * Displays a category as a thumbnail
 *
 * @see CategoryAsThumb.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import Card2 from "../../Card2";
import { useUnsplash } from "../../../hooks";
import { TextThumbnail } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./CategoryAsThumb.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./CategoryAsThumb.lang.ro-ro";
import { hu_hu } from "./CategoryAsThumb.lang.hu-hu";
import { en_us } from "./CategoryAsThumb.lang.en-us";
import { de_de } from "./CategoryAsThumb.lang.de-de";

i18n.addResourceBundle("ro-RO", "CategoryAsThumb", ro_ro);
i18n.addResourceBundle("hu-HU", "CategoryAsThumb", hu_hu);
i18n.addResourceBundle("en-US", "CategoryAsThumb", en_us);
i18n.addResourceBundle("de-DE", "CategoryAsThumb", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {}
}));

/**
 * Displays the component
 */
const CategoryAsThumb = props => {
  const { name, slug, image, count, index } = props;
  const { t } = useTranslation("CategoryAsThumb");

  /**
   * Loads unsplash images
   */
  const unsplashImages = useUnsplash({
    keyword: "Small village",
    page: index + 1,
    photos: 1,
    params: { orientation: "landscape" },
    originalImageProps: image
  });

  /**
   * Loads the current route
   */
  const match = useRouteMatch();
  const { url } = match;

  /**
   * Prepares a card
   */
  const cardContent = (
    <TextThumbnail to={`${url}/${slug}`} title={`${name} (${count})`} />
  );

  const cardWithMedia = {
    image: unsplashImages ? unsplashImages[0] : image,
    images: null,
    content: cardContent,
    customStyles: useStyles(props)
  };

  return <Card2 {...cardWithMedia} breakpoint="sm" />;
};

CategoryAsThumb.propTypes = propTypes;
CategoryAsThumb.defaultProps = defaultProps;

export default CategoryAsThumb;
export {
  propTypes as CategoryAsThumbPropTypes,
  defaultProps as CategoryAsThumbDefaultProps
};
